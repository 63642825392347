export function ViewCardProductController ($scope, http, routeParams, location, anchorScroll, resourceFactory, $rootScope, $filter) {
  $scope.regions = $rootScope.regions;

  function getCardProductDetail () {
    resourceFactory.cardProductsResource.get({ cardProductId: routeParams.id }, (response) => {
      $scope.cardproduct = response;
      const applicableForLookup = {
        1: 'Person',
        2: 'Entity'
      };

      $scope.details = {
        cardType: $scope.cardproduct.cardType,
        virtual: $scope.cardproduct.virtual,
        status: $scope.cardproduct.active ? 'Active' : 'Inactive',
        network: $scope.cardproduct.network,
        bin: $scope.cardproduct.bin,
        manualPin: $scope.cardproduct.manualPin.toString(),
        yearExpire: `${$scope.cardproduct.yearExpire} ${$scope.cardproduct.yearExpire > 1 ? 'years' : 'year'}`,
        businessCardIDEnabled: $scope.cardproduct.businessCardIDEnabled,
        digitalFirst: $scope.cardproduct.digitalFirst,
        fulfillCardOnOrder: $scope.cardproduct.fulfillCardOnOrder,
        upgradableToProductId: $scope.cardproduct.upgradableToProductId,
        maxActiveCardAllowed: $scope.cardproduct.maxActiveCardAllowed,
        creditProductName: $scope.cardproduct.creditProductName,
        applicableFor: applicableForLookup[$scope.cardproduct.legalForm] || '',
        replacementLimit: $scope.cardproduct.replacementLimit,
        activationUrl: $scope.cardproduct.activationUrl
      };

      $scope.securities = {
        onlinePaymentsEnabled: $scope.cardproduct.onlinePaymentsEnabled,
        contactlessPaymentsEnabled: $scope.cardproduct.contactlessPaymentsEnabled,
        atmWithdrawalsEnabled: $scope.cardproduct.atmWithdrawalsEnabled,
        internationalPaymentsEnabled: $scope.cardproduct.internationalPaymentsEnabled,
        posPaymentsEnabled: $scope.cardproduct.posPaymentsEnabled
      };

      if ($scope.cardproduct?.prepaidCard) {
        Object.assign($scope.details, {
          prepaidCard: $scope.cardproduct.prepaidCard,
          prepaidCardLimitAmount: $scope.cardproduct.prepaidCardLimitAmount,
          prepaidCardTerminationPeriod: $scope.cardproduct.prepaidCardTerminationPeriodInDay + ' ' + $filter('translate')('label.heading.card.days')
        });
      }
      $scope.cardProcessor = {
        id: $scope.cardproduct.cardProcessorId,
        displayName: $scope.cardproduct.cardProcessorDisplayName,
        externalProductId: $scope.cardproduct.externalProductId,
        cardProcessorConfigId: $scope.cardproduct.cardProcessorConfigId
      };
      $scope.posLimits = $scope.cardproduct.velocityRules.filter((item) => item.controls[0] === 'PURCHASE');
      $scope.atmLimits = $scope.cardproduct.velocityRules.filter((item) => item.controls[0] === 'WITHDRAWAL');
    });
  }

  getCardProductDetail();

  /**
   * @param {'activate' | 'deactivate'} statusType
   */
  $scope.updateCardProductStatus = (statusType) => {
    resourceFactory.cardProductsResource.updateStatus({ cardProductId: routeParams.id, command: statusType }, () => {
      getCardProductDetail();
    });
  };

  $scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };
}

ViewCardProductController.$inject = ['$scope', '$http', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory', '$rootScope', '$filter'];
