export class ClientStatus {
  constructor () {
    this.statusTypes = {
      Pending: [
        {
          name: 'label.button.activate',
          href: '#/clients',
          subhref: 'activate-action',
          icon: 'fas fa-check',
          taskPermissionName: 'ACTIVATE_CLIENT'
        },
        {
          name: 'label.button.close',
          href: '#/clients',
          subhref: 'close-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'CLOSE_CLIENT'
        },
        {
          name: 'label.button.reject',
          href: '#/clients',
          subhref: 'reject-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'REJECT_CLIENT'
        },
        {
          name: 'label.button.withdraw',
          href: '#/clients',
          subhref: 'withdraw-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'WITHDRAW_CLIENT'
        }
      ],
      subAccountPending: [
        {
          name: 'label.button.activate',
          href: '#/clients',
          subhref: 'activate-action',
          icon: 'fas fa-check',
          taskPermissionName: 'ACTIVATE_CLIENT'
        },
        {
          name: 'label.button.reject',
          href: '#/clients',
          subhref: 'reject-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'REJECT_CLIENT'
        },
        {
          name: 'label.button.close',
          href: '#/clients',
          subhref: 'close-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'CLOSE_CLIENT'
        }
      ],
      Closed: [
        {
          name: 'label.button.reactivate',
          href: '#/clients',
          subhref: 'reactivate-action',
          icon: 'fas fa-check',
          taskPermissionName: 'REACTIVATE_CLIENT'
        }
      ],
      subAccountClosed: [
        {
          name: 'label.button.reactivate',
          href: '#/clients',
          subhref: 'reactivate-action',
          icon: 'fas fa-check',
          taskPermissionName: 'REACTIVATE_CLIENT'
        }
      ],
      Rejected: [
        {
          name: 'label.button.undoReject',
          href: '#/clients',
          subhref: 'undoreject-action',
          icon: 'fas fa-undo',
          taskPermissionName: 'UNDOREJECT_CLIENT'
        }

      ],
      subAccountRejected: [
        {
          name: 'label.button.undoReject',
          href: '#/clients',
          subhref: 'undoreject-action',
          icon: 'fas fa-undo',
          taskPermissionName: 'UNDOREJECT_CLIENT'
        }

      ],
      Withdrawn: [
        {
          name: 'label.button.undoWithdrawn',
          href: '#/clients',
          subhref: 'undowithdrawn-action',
          icon: 'icon-ok-sign',
          taskPermissionName: 'UNDOWITHDRAWAL_CLIENT'
        }

      ],
      Active: [
        {
          name: 'label.button.newloan',
          href: '#/newclientloanaccount',
          icon: 'fas fa-plus',
          taskPermissionName: 'CREATE_LOAN'
        },
        {
          name: 'label.button.newdepositaccount',
          href: '#/new_client_saving_application',
          icon: 'fas fa-plus',
          taskPermissionName: 'CREATE_SAVINGSACCOUNT'
        },
        {
          name: 'label.button.close',
          href: '#/clients',
          subhref: 'close-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'CLOSE_CLIENT'
        }
      ],
      subAccountActive: [
        {
          name: 'label.button.newSubAccount',
          href: '#/clients',
          subhref: 'create-sub-account',
          icon: 'fas fa-plus',
          taskPermissionName: 'CREATE_SAVINGSACCOUNT'
        },
        {
          name: 'label.button.close',
          href: '#/clients',
          subhref: 'close-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'CLOSE_CLIENT'
        }
      ],
      'Transfer in progress': [
        {
          name: 'label.button.accepttransfer',
          href: '#/client',
          subhref: 'acceptclienttransfer',
          icon: 'fas fa-check',
          taskPermissionName: 'ACCEPTTRANSFER_CLIENT'
        },
        {
          name: 'label.button.rejecttransfer',
          href: '#/client',
          subhref: 'rejecttransfer',
          icon: 'fas fa-times',
          taskPermissionName: 'REJECTTRANSFER_CLIENT'
        },
        {
          name: 'label.button.undotransfer',
          href: '#/client',
          subhref: 'undotransfer',
          icon: 'fas fa-undo',
          taskPermissionName: 'WITHDRAWTRANSFER_CLIENT'
        }
      ],
      'Transfer on hold': [
        {
          name: 'label.button.undotransfer',
          href: '#/client',
          subhref: 'undotransfer',
          icon: 'fas fa-undo',
          taskPermissionName: 'WITHDRAWTRANSFER_CLIENT'
        }
      ],
      'Assign Staff': {
      },
      'DECEASED client': [
        {
          name: 'label.button.newloan',
          href: '#/newclientloanaccount',
          icon: 'fas fa-plus',
          taskPermissionName: 'CREATE_LOAN'
        },
        {
          name: 'label.button.newdepositaccount',
          href: '#/new_client_saving_application',
          icon: 'fas fa-plus',
          taskPermissionName: 'CREATE_SAVINGSACCOUNT'
        },
        {
          name: 'label.button.close',
          href: '#/clients',
          subhref: 'close-action',
          icon: 'fas fa-times-circle',
          taskPermissionName: 'CLOSE_CLIENT'
        }
      ]
    };
  }

  getStatus (status) {
    return this.statusTypes[status];
  }

  allStatusTypes () {
    return Object.keys(this.statusTypes);
  }

  statusKnown (status) {
    return this.allStatusTypes().includes(status);
  }
}
