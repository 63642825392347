<template>
  <div class="table-responsive">
    <table
      v-if="phoneNumbers?.length"
      class="table"
    >
      <thead>
        <tr>
          <th>{{ $t('label.head.phoneNumberType') }}</th>
          <th>{{ $t('label.head.countryCode') }}</th>
          <th colspan="2">
            {{ $t('label.head.phoneNumber') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(phoneNumber) in phoneNumbers"
          :key="phoneNumber.mobileNo"
          class="align-middle"
        >
          <td>
            {{ phoneNumber.type.name }}
          </td>
          <td>
            {{ phoneNumber.mobileCountryCode }}
          </td>
          <td>
            {{ phoneNumber.mobileNo }}
          </td>
          <td>
            <div class="d-flex justify-content-end gap-2">
              <button
                class="btn btn-primary"
                @click="routeToEdit(clientId, phoneNumber.id)"
              >
                {{ $t('label.button.edit') }}
              </button>
              <button
                class="btn btn-danger"
                :disabled="loading"
                @click="deletePhoneNumber(clientId, phoneNumber.id)"
              >
                {{ $t('label.button.delete') }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-else
      class="text-center text-secondary mb-3"
    >
      {{ $t('label.heading.noPhoneNumbersAdded') }}
    </div>
  </div>
</template>

<script setup>
import i18n from '@/plugins/i18n';
import prompt from '@/plugins/prompt';
import { $notify } from '@/plugins/prototypes';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { useSharedStore } from '@/stores/shared';
import { computed } from 'vue';

defineProps({
  phoneNumbers: {
    type: Array,
    default: () => []
  },
  clientId: {
    type: String,
    required: true
  }
});

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);
const emits = defineEmits(['update']);

function routeToEdit (clientId, phoneNumberId) {
  router.push({ name: 'EditPhoneNumber', params: { clientId, phoneNumberId } });
}

async function deletePhoneNumber (clientId, phoneNumberId) {
  try {
    const confirmation = await prompt.confirm(i18n.t('messages.areYouSureYouWantToDelete'));
    if (confirmation) {
      await apiService.clientPhoneNumber.delete(clientId, phoneNumberId);
      emits('update');
    }
  } catch (error) {
    $notify.error(error);
  }
}
</script>
