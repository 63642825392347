<template>
  <section name="client-saving-account-tabs">
    <b-tabs lazy>
      <b-tab
        v-for="(tab, index) in tabs"
        :key="`tab-${tab.title}`"
        :title="tab.title"
        :active="index === 0"
      >
        <component
          :is="tab.component"
        />
      </b-tab>
    </b-tabs>
  </section>
</template>

<script setup>
// TO-DO: Title need to do locale
const tabs = [
  { title: 'Transactions', component: () => import('./Transactions.vue') },
  { title: 'Pending Transactions', component: () => import('./PendingTransactions.vue') },
  { title: 'Average Monthly Account Balance', component: () => import('./AverageMonthlyBalance.vue') }
];
</script>
