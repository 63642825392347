<template>
  <div>
    <button
      v-if="havePermissions(buttonsPermissions)"
      id="statusDropDown"
      class="btn btn-primary dropdown-toggle rounded-0"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      {{ $t('label.button.changeStatus') }}
    </button>
    <ul
      class="dropdown-menu"
      aria-labelledby="statusDropDown"
    >
      <li
        v-for="(cardStatus, index) in cardButtonStatuses[cardItem.status?.toLowerCase()]"
        :key="index"
      >
        <button
          v-has-permission="cardStatus.taskPermissionName"
          class="dropdown-item"
          @click="triggerStatusModal(cardStatus.action)"
        >
          {{ $t(`label.cardStatus.${cardStatus.action}`) }}
        </button>
      </li>
      <li v-if="isReplaceCard">
        <button
          v-has-permission="'REPLACE_CARD'"
          class="dropdown-item"
          @click="triggerStatusModal('replace')"
        >
          {{ $t('label.anchor.replaceCard') }}
        </button>
      </li>
    </ul>
    <base-modal
      id="changeStatus"
      ref="changeStatusModal"
      :modal-size="status === 'return' ? 'xl' : 'default'"
      hide-footer
    >
      <template #header>
        <card-header
          v-if="status === 'return'"
          :item="cardItem"
        />
        <h4 v-else>
          {{ $t(`label.heading.${modalTitle}`) }}
        </h4>
      </template>
      <template #body>
        <form-wrapper @submit="changeStatus">
          <div v-if="statusWithNote.includes(status)">
            <input-text
              :id="$t(`label.input.${inputLabel}`)"
              v-model="note"
              vertical
              :label="$t(`label.input.${inputLabel}`)"
              :class="{'mt-3': status === 'return'}"
              :rows="2"
              :required="status === 'reactivate'"
              type="textarea"
            />
          </div>
          <div v-if="status === 'replace'">
            <input-select
              v-model="note"
              class="col-sm-8 mb-1"
              :options="replaceCardOptions"
              :label="$t('label.anchor.reason')"
              value-field="value"
              required
            />
            <p v-if="note === 'DAMAGED'">
              {{ $t('label.replaceCardDamagedText') }}
            </p>
          </div>
          <div class="pull-right">
            <button
              type="button"
              class="btn btn-default"
              @click.prevent="cancelModal"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              :disabled="loading"
            >
              {{ $t('label.button.save') }}
            </button>
          </div>
        </form-wrapper>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import { havePermissions } from '@/directives/permission.js';
import BaseModal from '@/components/shared/BaseModal.vue';
import apiService from '@/services/apiService';
import { CardHeader } from '@/components/clients/card/index.js';
import { cardButtonStatuses } from '@/utils/dictionaries/cardButtonStatuses.dictionary';
import { InputText, InputSelect, FormWrapper } from '@/components/shared/form-input/index';
import { $notify } from '@/plugins/prototypes';
import { get, set } from '@vueuse/core';
import { ref, computed } from 'vue';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const changeStatusModal = ref('');
const status = ref('');
const note = ref('');
const replaceCardOptions = ref([]);
const statusWithNote = ['reactivate', 'return'];
const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  }
});
const emit = defineEmits(['onStatusChanged']);
const modalTitle = computed(() => get(status) === 'replace' ? 'confirmReplaceCard' : 'confirmChangeCardStatus');
const inputLabel = computed(() => get(status) === 'return' ? 'note' : 'reason');
const loading = computed(() => sharedStore.loading);
const buttonsPermissions = computed(() => {
  const status = props.cardItem.status?.toLowerCase();
  if (!status) return [];
  const taskPermissions = cardButtonStatuses[status].map((button) => button.taskPermissionName);
  return [...taskPermissions, 'REPLACE_CARD'];
});

const triggerStatusModal = async (statusAction) => {
  set(status, statusAction);
  if (statusAction === 'replace') getReplaceOptions();
  get(changeStatusModal).show();
};

const getReplaceOptions = () => {
  if (props.cardItem.physicalCardActivated) set(replaceCardOptions, [{ value: 'DAMAGED', name: 'DAMAGED' }]);
  if (props.cardItem.status === 'SUSPENDED') {
    set(replaceCardOptions, [
      { value: 'LOST', name: 'LOST' },
      { value: 'STOLEN', name: 'STOLEN' },
      { value: 'CARD_NOT_RECEIVED', name: 'CARD NOT RECEIVED' },
      { value: 'CAPTURED_BY_ATM', name: 'CAPTURED BY ATM' }
    ]);
  }
};

const isSuspended = computed(() => props.cardItem.status === 'SUSPENDED');
const isReplaceCard = computed(() => (props.cardItem.physicalCardActivated && props.cardItem.status !== 'TERMINATED') || get(isSuspended));

const hideModal = () => {
  cancelModal();
  emit('onStatusChanged');
};

const cancelModal = () => {
  set(note, '');
  get(changeStatusModal).hide();
};

const changeStatus = async () => {
  const isReturnWithNote = get(status) === 'return' && get(note);
  const payloadData = () => {
    if (get(status) === 'replace') return { replaceReason: get(note) };
    if (get(status) === 'reactivate') return { reason: get(note) };
    return null;
  };
  try {
    await apiService.cardsResource.command(props.cardItem.id, get(status), payloadData());
    if (isReturnWithNote) await apiService.clientNote.post(props.cardItem.clientId, { note: get(note) });
    hideModal();
  } catch (e) {
    $notify.error(e);
  }
};
</script>
