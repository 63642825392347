export function EditProvisioningCriteriaController ($scope, resourceFactory, routeParams, location, dateFilter, translate) {
  $scope.available = [];
  $scope.selected = [];
  $scope.template = [];
  $scope.formData = {};
  $scope.translate = translate;

  resourceFactory.provisioningcriteria.get({ criteriaId: routeParams.criteriaId, template: 'true' }, function (data) {
    $scope.selectedloanproducts = data.selectedLoanProducts;
    $scope.allloanproducts = data.loanProducts;
    $scope.definitions = data.definitions;
    $scope.criteriaName = data.criteriaName;
    $scope.criteriaId = data.criteriaId;
    $scope.liabilityaccounts = data.glAccounts;
    $scope.expenseaccounts = data.glAccounts;
  });

  $scope.addLoanProduct = function () {
    if (!this.available?.length) return;

    const { allloanproducts, available } = $scope;
    const selectedProducts = available.map(id => allloanproducts.find(product => product.id === Number(id)));

    $scope.selectedloanproducts.push(...selectedProducts);
    $scope.allloanproducts = allloanproducts.filter(product => !available.includes(`${product.id}`));
  };

  $scope.removeLoanProduct = function () {
    if (!this.selected?.length) return;

    const { selectedloanproducts, selected } = $scope;
    const selectedProducts = selected.map(id => selectedloanproducts.find(product => product.id === Number(id)));

    $scope.allloanproducts.push(...selectedProducts);
    $scope.selectedloanproducts = selectedloanproducts.filter(product => !selected.includes(`${product.id}`));
  };

  $scope.submit = function () {
    this.formData.locale = $scope.optlang.code;
    this.formData.criteriaId = $scope.criteriaId;
    this.formData.criteriaName = $scope.criteriaName;
    this.formData.loanProducts = $scope.selectedloanproducts.map(product => ({ id: product.id, name: product.name, includeInBorrowerCycle: product.includeInBorrowerCycle }));
    this.formData.definitions = $scope.definitions;
    resourceFactory.provisioningcriteria.put({ criteriaId: routeParams.criteriaId }, this.formData, function (data) {
      location.path(`/viewprovisioningcriteria/${data.resourceId}`);
    });
  };
}

EditProvisioningCriteriaController.$inject = ['$scope', 'ResourceFactory', '$routeParams', '$location', 'dateFilter', '$translate'];
