export function LegalFormName () {
  return (type) => {
    switch (type) {
      case 1:
        return 'PERSON';
      case 2:
        return 'ENTITY';
      default:
        return '';
    }
  };
}
