export default {
  inject: {
    register: {
      default: false
    },
    unregister: {
      default: false
    }
  },
  data () {
    return {
      errorBucket: [],
      localRule: []
    };
  },
  computed: {
    validators () {
      const isValueEmpty = (value) => value === '' || value === null || value === undefined;
      const requiredRule = (value) => (this.required && isValueEmpty(value)) ? 'This field is required' : true;
      return [...this.rules, ...this.localRule, requiredRule];
    },
    errorMessage () {
      return this.errorBucket.length ? this.errorBucket[0] : '';
    },
    isValid () {
      const { isValid } = this.fieldValidation();
      return isValid;
    }
  },
  beforeMount () {
    if (this.register) this.register(this);
  },
  beforeUnmount () {
    if (this.unregister) this.unregister(this);
  },
  methods: {
    async validatorRunner () {
      this.errorBucket = [];
      await this.$nextTick();
      const inputElement = this.$refs.input;
      inputElement?.setCustomValidity('');

      const { errors, isValid } = this.fieldValidation();

      if (!isValid) {
        inputElement?.setCustomValidity(' ');
        this.errorBucket.push(...errors);
      }

      return this.errorBucket;
    },
    fieldValidation () {
      const errors = [];

      this.validators.forEach(validate => {
        const validationResult = validate(this.value);

        if (typeof validationResult === 'undefined' || validationResult === null) console.warn('function must return error message as string');

        if (typeof validationResult === 'string') errors.push(validationResult);
      });

      return { errors, isValid: !errors.length };
    }
  }
};
