import angular from 'angular';
import { AddUpTotalFor } from './AddUpTotalFor';
import { DateFormat } from './DateFormat';
import { FormatPAN } from './FormatPAN';
import { StatusLookup } from './StatusLookup';
import { YesOrNo } from './YesOrNo';
import { DayMonthFormat } from './DayMonthFormat';
import { DotRemove } from './DotRemove';
import { FormatExchangeRate, FormatNumber } from './FormatNumber';
import { SearchFilter } from './SearchFilter';
import { sort } from './sort';
import { prettifyDataTableColumn } from './TranslateDataTableColumn';
import { UrlToString } from './UrlToString';
import { AccountIdentifierFormat, IdentifierFormat, RoutingNumberFormat } from './AccountIdentifierFormat';
import { CountryIsoToName } from './CountryFilter';
import { CurrencyIsoToName } from './CurrencyFilter';
import { MCCCodeWithName } from './MCCFilter';
import { FundAllocateTypeFormat } from './FundAllocateTypeFormat';
import { LegalFormIcon } from './LegalFormIcon';
import { LegalFormName } from './LegalFormName';

export default angular
  .module('mbanq.filters', [])
  .filter('AddUpTotalFor', AddUpTotalFor)
  .filter('StatusLookup', StatusLookup)
  .filter('DateFormat', DateFormat)
  .filter('FormatPAN', FormatPAN)
  .filter('YesOrNo', YesOrNo)
  .filter('UrlToString', UrlToString)
  .filter('DayMonthFormat', DayMonthFormat)
  .filter('DotRemove', DotRemove)
  .filter('FormatNumber', FormatNumber)
  .filter('SearchFilter', SearchFilter)
  .filter('sort', sort)
  .filter('prettifyDataTableColumn', prettifyDataTableColumn)
  .filter('AccountIdentifierFormat', AccountIdentifierFormat)
  .filter('RoutingNumberFormat', RoutingNumberFormat)
  .filter('IdentifierFormat', IdentifierFormat)
  .filter('countryIsoToName', CountryIsoToName)
  .filter('currencyIsoToName', CurrencyIsoToName)
  .filter('mccCodeWithName', MCCCodeWithName)
  .filter('formatExchangeRate', FormatExchangeRate)
  .filter('FundAllocateTypeFormat', FundAllocateTypeFormat)
  .filter('LegalFormIcon', LegalFormIcon)
  .filter('LegalFormName', LegalFormName)
  .name;
