<template>
  <data-grid
    ref="clientTable"
    :items="items"
    :limit="limit"
    :total-records="totalRecords"
    :fields="fields"
    :clickable="true"
    use-arrow-paginator
    @pagination-update="paginationUpdate"
    @sort-column="sortColumn"
    @entries-per-page="updateEntriesPerPage"
    @click-row="rowClicked"
  >
    <template #displayName="{ item }">
      <span :class="getLegalFormType(item)" />
      {{ item.displayName }}
    </template>
    <template #status="{ item }">
      <span
        class="d-inline-block"
        tabindex="0"
        data-toggle="tooltip"
        :title="item.status.value"
      >
        <i :class="`fas fa-stop ${generalStatusLookUp(item.status)}`" />
      </span>
      <small v-if="item.subStatus">{{ item.subStatus.description }}</small>
    </template>
    <template #submittedDate="{ item }">
      {{ dateStringToFormat(item.timeline.submittedOnDate) }}
    </template>
  </data-grid>
</template>

<script>
import DataGrid from '@/components/shared/data-grid/index.vue';
import { dateStringToFormat } from '@/helper/dateHelper';
import { generalStatusLookUp } from '@/helper/statusLookUp';

export default {
  components: {
    DataGrid
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    totalRecords: {
      type: Number,
      default: 0
    },
    limit: {
      type: [Number, String],
      default: 15
    }
  },
  data () {
    return {
      fields: [
        { key: 'displayName', label: this.$t('label.heading.name'), sortable: true },
        { key: 'accountNo', label: this.$t('label.heading.clientNo'), sortable: true },
        { key: 'externalId', label: this.$t('label.heading.externalId'), sortable: true },
        { key: 'onBoardingStatus', label: this.$t('label.heading.onBoardingStatus'), sortable: true },
        { key: 'status', label: this.$t('label.heading.status'), sortable: true },
        { key: 'officeName', label: this.$t('label.heading.office'), sortable: true },
        { key: 'submittedDate', label: this.$t('label.heading.submittedOn'), sortable: true }
      ]
    };
  },
  methods: {
    dateStringToFormat,
    generalStatusLookUp,
    getLegalFormType (item) {
      switch (item.legalForm?.code) {
        case 'legalFormType.person':
          return 'fa fa-user fa-sm';
        case 'legalFormType.entity':
          return 'fa fa-suitcase fa-sm';
        default:
          return '';
      }
    },
    paginationUpdate (offset) {
      this.$emit('pagination-update', offset);
    },
    sortColumn (sort) {
      this.$emit('sort-column', sort);
    },
    updateEntriesPerPage (entry) {
      this.$emit('entries-per-page', entry);
    },
    rowClicked (item) {
      this.$emit('click-row', item);
    },
    resetPage () {
      this.$refs.clientTable.currentPage = 1;
    }
  }
};
</script>
