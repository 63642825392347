import _ from 'underscore';
import { getRandomString, getClonnedName } from '../../common-methods';
import { dateStringToFormat } from '@/helper/dateHelper';
import router from '@/router/index';

export function CreateLoanProductController (scope, $rootScope, resourceFactory, location, dateFilter, WizardHandler) {
  const searchParams = location.search();
  scope.formData = {};
  scope.formData.currencyCode = '';
  scope.restrictDate = new Date();
  scope.loanproduct = {};
  scope.charges = [];
  scope.accountingOptions = ['None', 'Cash', 'Accrual(Periodic)', 'Accrual(Upfront)'];
  scope.floatingrateoptions = [];
  scope.loanProductConfigurableAttributes = [];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeAccountMapping = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};
  scope.date = {};
  scope.pvFlag = false;
  scope.rvFlag = false;
  scope.irFlag = false;
  scope.chargeFlag = false;
  scope.penalityFlag = false;
  scope.frFlag = false;
  scope.fiFlag = false;
  scope.piFlag = false;
  scope.amortizationType = true;
  scope.arrearsTolerance = true;
  scope.graceOnArrearsAging = true;
  scope.interestCalcPeriod = true;
  scope.interestMethod = true;
  scope.graceOnPrincipalAndInterest = true;
  scope.repaymentFrequency = true;
  scope.transactionProcessingStrategy = true;
  scope.allowAttributeConfiguration = true;
  scope.onDayTypeOptions = Array.from({ length: 28 }, (_, index) => index + 1);

  resourceFactory.loanProductResource.get({ resourceType: 'template' }, function (data) {
    scope.product = data;
    scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
    scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
    scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];
    scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
    scope.incomeAndLiabilityAccountOptions = scope.incomeAccountOptions.concat(scope.liabilityAccountOptions);
    scope.penaltyOptions = scope.product.penaltyOptions || [];
    scope.chargeOptions = scope.product.chargeOptions || [];
    scope.overduecharges = [];
    for (const i in scope.penaltyOptions) {
      if (scope.penaltyOptions[i].chargeTimeType.code === 'chargeTimeType.overdueInstallment') {
        scope.overduecharges.push(scope.penaltyOptions[i]);
      }
    }
    scope.formData.currencyCode = scope.formData.currencyCode === '' ? scope.product.currencyOptions[0].code : scope.formData.currencyCode;
    scope.formData.includeInBorrowerCycle = 'false';
    scope.formData.useBorrowerCycle = false;
    scope.formData.digitsAfterDecimal = '2';
    scope.formData.inMultiplesOf = '0';
    scope.formData.repaymentFrequencyType = scope.product.repaymentFrequencyType.id;
    scope.formData.interestRateFrequencyType = scope.product.interestRateFrequencyType.id;
    scope.formData.amortizationType = scope.product.amortizationType.id;
    scope.formData.interestType = scope.product.interestType.id;
    scope.formData.interestCalculationPeriodType = scope.product.interestCalculationPeriodType.id;
    scope.formData.transactionProcessingStrategyId = scope.product.transactionProcessingStrategyOptions[0].id;
    scope.formData.principalVariationsForBorrowerCycle = scope.product.principalVariationsForBorrowerCycle;
    scope.formData.interestRateVariationsForBorrowerCycle = scope.product.interestRateVariationsForBorrowerCycle;
    scope.formData.numberOfRepaymentVariationsForBorrowerCycle = scope.product.numberOfRepaymentVariationsForBorrowerCycle;
    scope.formData.multiDisburseLoan = false;
    scope.formData.accountingRule = '1';
    scope.formData.daysInYearType = scope.product.daysInYearType.id;
    scope.formData.daysInMonthType = scope.product.daysInMonthType.id;
    scope.formData.isInterestRecalculationEnabled = scope.product.isInterestRecalculationEnabled;
    scope.formData.interestRecalculationCompoundingMethod = scope.product.interestRecalculationData.interestRecalculationCompoundingType.id;
    scope.formData.rescheduleStrategyMethod = scope.product.interestRecalculationData.rescheduleStrategyType.id;
    scope.formData.preClosureInterestCalculationStrategy = scope.product.interestRecalculationData.preClosureInterestCalculationStrategy.id;
    if (scope.product.interestRecalculationData.recalculationRestFrequencyType) {
      scope.formData.recalculationRestFrequencyType = scope.product.interestRecalculationData.recalculationRestFrequencyType.id;
    }
    scope.floatingRateOptions = data.floatingRateOptions;
    scope.formData.isFloatingInterestRateCalculationAllowed = false;
    scope.formData.isLinkedToFloatingInterestRates = false;
    scope.formData.allowVariableInstallments = false;
    scope.product.interestRecalculationNthDayTypeOptions.push({ code: 'onDay', id: -2, value: 'on day' });
    scope.formData.isEqualAmortization = false;
    scope.formData.allowPartialPeriodInterestCalculation = false;
    scope.loanproduct = {
      amortizationOption: { value: $rootScope.formValue(scope.product.amortizationTypeOptions, scope.formData.amortizationType) },
      interestOption: { value: $rootScope.formValue(scope.product.interestTypeOptions, scope.formData.interestType) },
      interestCalculationPeriodOption: { value: $rootScope.formValue(scope.product.interestCalculationPeriodTypeOptions, scope.formData.interestCalculationPeriodType) },
      transactionProcessingStrategy: { name: $rootScope.formValue(scope.product.transactionProcessingStrategyOptions, scope.formData.transactionProcessingStrategyId, 'id', 'name') },
      daysInYearOption: { value: $rootScope.formValue(scope.product.daysInYearTypeOptions, scope.formData.daysInYearType) },
      daysInMonthOption: { value: $rootScope.formValue(scope.product.daysInMonthTypeOptions, scope.formData.daysInMonthType) }
    };

    scope.loanproduct = _.extend(scope.loanproduct, scope.formData);
    scope.isClicked = false;
  });

  scope.$watch('formData', function (newVal) {
    scope.loanproduct = angular.extend(scope.loanproduct, newVal);
  }, true);

  $rootScope.formValue = function (array, model, findattr, retAttr) {
    findattr = findattr || 'id';
    retAttr = retAttr || 'value';
    return _.find(array, function (obj) {
      return obj[findattr] === model;
    })[retAttr];
  };

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
    scope.isClicked = true;
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get({ chargeId, template: 'true' }, this.formData, function (data) {
        data.chargeId = data.id;
        scope.charges.push(data);
        // to charge select box empty

        if (data.penalty) {
          scope.penalityFlag = true;
          scope.penalityId = '';
        } else {
          scope.chargeFlag = true;
          scope.chargeId = '';
        }
      });
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  // advanced accounting rule
  scope.showOrHide = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.addConfigureFundSource = function () {
    scope.frFlag = true;
    scope.configureFundOptions.push({
      paymentTypeId: scope.product.paymentTypeOptions.length > 0 ? scope.product.paymentTypeOptions[0].id : '',
      fundSourceAccountId: scope.assetAccountOptions.length > 0 ? scope.assetAccountOptions[0].id : '',
      paymentTypeOptions: scope.product.paymentTypeOptions.length > 0 ? scope.product.paymentTypeOptions : [],
      assetAccountOptions: scope.assetAccountOptions.length > 0 ? scope.assetAccountOptions : []
    });
  };

  scope.mapFees = function () {
    scope.fiFlag = true;
    scope.specificIncomeAccountMapping.push({
      chargeId: scope.chargeOptions.length > 0 ? scope.chargeOptions[0].id : '',
      incomeAccountId: scope.incomeAndLiabilityAccountOptions.length > 0 ? scope.incomeAndLiabilityAccountOptions[0].id : ''
    });
  };

  scope.addPrincipalVariation = function () {
    scope.pvFlag = true;
    scope.formData.principalVariationsForBorrowerCycle.push({
      valueConditionType: scope.product.valueConditionTypeOptions[0].id
    });
  };
  scope.addInterestRateVariation = function () {
    scope.irFlag = true;
    scope.formData.interestRateVariationsForBorrowerCycle.push({
      valueConditionType: scope.product.valueConditionTypeOptions[0].id
    });
  };
  scope.addNumberOfRepaymentVariation = function () {
    scope.rvFlag = true;
    scope.formData.numberOfRepaymentVariationsForBorrowerCycle.push({
      valueConditionType: scope.product.valueConditionTypeOptions[0].id
    });
  };

  scope.mapPenalty = function () {
    scope.piFlag = true;
    scope.penaltySpecificIncomeaccounts.push({
      chargeId: scope.penaltyOptions.length > 0 ? scope.penaltyOptions[0].id : '',
      incomeAccountId: scope.incomeAccountOptions.length > 0 ? scope.incomeAccountOptions[0].id : ''
    });
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeAccountMapping.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.deletePrincipalVariation = function (index) {
    scope.formData.principalVariationsForBorrowerCycle.splice(index, 1);
  };

  scope.deleteInterestRateVariation = function (index) {
    scope.formData.interestRateVariationsForBorrowerCycle.splice(index, 1);
  };

  scope.deleterepaymentVariation = function (index) {
    scope.formData.numberOfRepaymentVariationsForBorrowerCycle.splice(index, 1);
  };

  scope.routeToLoanProducts = function () {
    router.push({ name: 'LoanProducts' });
  };

  scope.cancel = scope.routeToLoanProducts;

  scope.isAccountingEnabled = function () {
    if (scope.formData.accountingRule === '2' || scope.formData.accountingRule === '3' || scope.formData.accountingRule === '4') {
      return true;
    }
    return false;
  };

  scope.isAccrualAccountingEnabled = function () {
    if (scope.formData.accountingRule === '3' || scope.formData.accountingRule === '4') {
      return true;
    }
    return false;
  };
  scope.setAttributeValues = function () {
    if (scope.allowAttributeConfiguration === false) {
      scope.amortizationType = false;
      scope.arrearsTolerance = false;
      scope.graceOnArrearsAging = false;
      scope.interestCalcPeriod = false;
      scope.interestMethod = false;
      scope.graceOnPrincipalAndInterest = false;
      scope.repaymentFrequency = false;
      scope.transactionProcessingStrategy = false;
    }
  };

  scope.filterCharges = function (currencyCode, multiDisburseLoan) {
    return function (item) {
      if ((multiDisburseLoan !== true) && item.chargeTimeType.id === 12) {
        return false;
      }
      if (item.currency.code !== currencyCode) {
        return false;
      }
      return true;
    };
  };

  scope.submit = function () {
    const reqFirstDate = dateFilter(scope.date.first, scope.df);
    const reqSecondDate = dateFilter(scope.date.second, scope.df);
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];
    scope.selectedConfigurableAttributes = [];

    let temp = '';

    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeAccountMapping) {
      temp = {
        chargeId: scope.specificIncomeAccountMapping[i].chargeId,
        incomeAccountId: scope.specificIncomeAccountMapping[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id
      };
      scope.chargesSelected.push(temp);
    }

    if (scope.allowAttributeConfiguration === false) {
      scope.amortizationType = false;
      scope.arrearsTolerance = false;
      scope.graceOnArrearsAging = false;
      scope.interestCalcPeriod = false;
      scope.interestMethod = false;
      scope.graceOnPrincipalAndInterest = false;
      scope.repaymentFrequency = false;
      scope.transactionProcessingStrategy = false;
    }

    scope.selectedConfigurableAttributes = {
      amortizationType: scope.amortizationType,
      interestType: scope.interestMethod,
      transactionProcessingStrategyId: scope.transactionProcessingStrategy,
      interestCalculationPeriodType: scope.interestCalcPeriod,
      inArrearsTolerance: scope.arrearsTolerance,
      repaymentEvery: scope.repaymentFrequency,
      graceOnPrincipalAndInterestPayment: scope.graceOnPrincipalAndInterest,
      graceOnArrearsAgeing: scope.graceOnArrearsAging
    };

    this.formData.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.allowAttributeOverrides = scope.selectedConfigurableAttributes;
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.startDate = reqFirstDate;
    this.formData.closeDate = reqSecondDate;

    // Interest recalculation data
    if (this.formData.isInterestRecalculationEnabled) {
      const restFrequencyDate = dateFilter(scope.date.recalculationRestFrequencyDate, scope.df);
      scope.formData.recalculationRestFrequencyDate = restFrequencyDate;
      const compoundingFrequencyDate = dateFilter(scope.date.recalculationCompoundingFrequencyDate, scope.df);
      scope.formData.recalculationCompoundingFrequencyDate = compoundingFrequencyDate;
    } else {
      delete scope.formData.interestRecalculationCompoundingMethod;
      delete scope.formData.rescheduleStrategyMethod;
      delete scope.formData.recalculationRestFrequencyType;
      delete scope.formData.recalculationRestFrequencyInterval;
    }

    if (this.formData.isLinkedToFloatingInterestRates) {
      delete scope.formData.interestRatePerPeriod;
      delete scope.formData.minInterestRatePerPeriod;
      delete scope.formData.maxInterestRatePerPeriod;
      delete scope.formData.interestRateFrequencyType;
    } else {
      delete scope.formData.floatingRatesId;
      delete scope.formData.interestRateDifferential;
      delete scope.formData.isFloatingInterestRateCalculationAllowed;
      delete scope.formData.minDifferentialLendingRate;
      delete scope.formData.defaultDifferentialLendingRate;
      delete scope.formData.maxDifferentialLendingRate;
    }
    // If Variable Installments is not allowed for this product, remove the corresponding formData
    if (!this.formData.allowVariableInstallments) {
      delete scope.formData.minimumGap;
      delete scope.formData.maximumGap;
    }

    if (this.formData.interestCalculationPeriodType === 0) {
      this.formData.allowPartialPeriodInterestCalculation = false;
    }

    if (this.formData.recalculationCompoundingFrequencyType === 4) {
      if (this.formData.recalculationCompoundingFrequencyNthDayType === -2) {
        delete this.formData.recalculationCompoundingFrequencyNthDayType;
        delete this.formData.recalculationCompoundingFrequencyDayOfWeekType;
      } else {
        delete this.formData.recalculationCompoundingFrequencyOnDayType;
      }
    } else if (this.formData.recalculationCompoundingFrequencyType === 3) {
      delete this.formData.recalculationCompoundingFrequencyOnDayType;
      delete this.formData.recalculationCompoundingFrequencyNthDayType;
    }

    if (this.formData.recalculationRestFrequencyType === 4) {
      if (this.formData.recalculationRestFrequencyNthDayType === -2) {
        delete this.formData.recalculationRestFrequencyNthDayType;
        delete this.formData.recalculationRestFrequencyDayOfWeekType;
      } else {
        delete this.formData.recalculationRestFrequencyOnDayType;
      }
    } else if (this.formData.recalculationRestFrequencyType === 3) {
      delete this.formData.recalculationRestFrequencyOnDayType;
      delete this.formData.recalculationRestFrequencyNthDayType;
    }
    resourceFactory.loanProductResource.save(this.formData, function (data) {
      location.path(`/viewloanproduct/${data.resourceId}`);
    });
  };

  if (searchParams.clone) {
    getLoanProduct();
  }

  function getLoanProduct () {
    resourceFactory.loanProductResource.get({ loanProductId: searchParams.clone }, function (data) {
      if (data.startDate) {
        scope.date.first = dateStringToFormat(data.startDate);
      }
      if (data.closeDate) {
        scope.date.second = dateStringToFormat(data.closeDate);
      }

      scope.formData = {
        name: getClonnedName(data.name),
        shortName: getRandomString(4),
        description: data.description,
        fundId: data.fundId,
        includeInBorrowerCycle: data.includeInBorrowerCycle,
        currencyCode: data.currency.code,
        digitsAfterDecimal: data.currency.decimalPlaces,
        inMultiplesOf: data.currency.inMultiplesOf,
        installmentAmountInMultiplesOf: data.installmentAmountInMultiplesOf,
        useBorrowerCycle: data.useBorrowerCycle,
        principal: data.principal,
        minPrincipal: data.minPrincipal,
        maxPrincipal: data.maxPrincipal,
        numberOfRepayments: data.numberOfRepayments,
        minNumberOfRepayments: data.minNumberOfRepayments,
        maxNumberOfRepayments: data.maxNumberOfRepayments,
        repaymentEvery: data.repaymentEvery,
        repaymentFrequencyType: data.repaymentFrequencyType.id,
        interestRatePerPeriod: data.interestRatePerPeriod,
        minInterestRatePerPeriod: data.minInterestRatePerPeriod,
        maxInterestRatePerPeriod: data.maxInterestRatePerPeriod,
        interestRateFrequencyType: data.interestRateFrequencyType.id,
        amortizationType: data.amortizationType.id,
        interestType: data.interestType.id,
        interestCalculationPeriodType: data.interestCalculationPeriodType.id,
        allowPartialPeriodInterestCalculation: data.allowPartialPeriodInterestCalculation,
        inArrearsTolerance: data.inArrearsTolerance,
        transactionProcessingStrategyId: data.transactionProcessingStrategyId,
        graceOnPrincipalPayment: data.graceOnPrincipalPayment,
        graceOnInterestPayment: data.graceOnInterestPayment,
        graceOnInterestCharged: data.graceOnInterestCharged,
        graceOnArrearsAgeing: data.graceOnArrearsAgeing,
        overdueDaysForNPA: data.overdueDaysForNPA,
        accountMovesOutOfNPAOnlyOnArrearsCompletion: data.accountMovesOutOfNPAOnlyOnArrearsCompletion,
        accountingRule: data.accountingRule.id,
        principalVariationsForBorrowerCycle: [],
        interestRateVariationsForBorrowerCycle: [],
        numberOfRepaymentVariationsForBorrowerCycle: [],
        multiDisburseLoan: data.multiDisburseLoan,
        maxTrancheCount: data.maxTrancheCount,
        outstandingLoanBalance: data.outstandingLoanBalance,
        daysInYearType: data.daysInYearType.id,
        daysInMonthType: data.daysInMonthType.id,
        isInterestRecalculationEnabled: data.isInterestRecalculationEnabled,
        holdGuaranteeFunds: data.holdGuaranteeFunds,
        minimumDaysBetweenDisbursalAndFirstRepayment: data.minimumDaysBetweenDisbursalAndFirstRepayment,
        principalThresholdForLastInstallment: data.principalThresholdForLastInstallment,
        canDefineInstallmentAmount: data.canDefineInstallmentAmount,
        isEqualAmortization: data.isEqualAmortization,
        isLinkedWithFundSourceAccount: data.isLinkedWithFundSourceAccount,
        isIgnoreUndisbursedTranches: data.isIgnoreUndisbursedTranches,
        interestRateVariesMonthly: data.interestRateVariesMonthly
      };
      if (data.isInterestRecalculationEnabled) {
        scope.formData.interestRecalculationCompoundingMethod = data.interestRecalculationData.interestRecalculationCompoundingType.id;
        scope.formData.rescheduleStrategyMethod = data.interestRecalculationData.rescheduleStrategyType.id;
        scope.formData.recalculationRestFrequencyType = data.interestRecalculationData.recalculationRestFrequencyType.id;
        scope.formData.recalculationRestFrequencyInterval = data.interestRecalculationData.recalculationRestFrequencyInterval;
        scope.formData.isArrearsBasedOnOriginalSchedule = data.interestRecalculationData.isArrearsBasedOnOriginalSchedule;
        scope.formData.preClosureInterestCalculationStrategy = data.interestRecalculationData.preClosureInterestCalculationStrategy.id;
        if (data.interestRecalculationData.recalculationRestFrequencyOnDay !== null) {
          scope.formData.recalculationRestFrequencyNthDayType = -2;
          scope.formData.recalculationRestFrequencyOnDayType = data.interestRecalculationData.recalculationRestFrequencyOnDay;
        } else {
          if (data.interestRecalculationData.recalculationRestFrequencyNthDay !== null) { scope.formData.recalculationRestFrequencyNthDayType = data.interestRecalculationData.recalculationRestFrequencyNthDay.id; }
          if (data.interestRecalculationData.recalculationRestFrequencyWeekday !== null) { scope.formData.recalculationRestFrequencyDayOfWeekType = data.interestRecalculationData.recalculationRestFrequencyWeekday.id; }
        }
        if (scope.formData.interestRecalculationCompoundingMethod !== 0) {
          scope.formData.recalculationCompoundingFrequencyType = data.interestRecalculationData.recalculationCompoundingFrequencyType.id;
          scope.formData.recalculationCompoundingFrequencyInterval = data.interestRecalculationData.recalculationCompoundingFrequencyInterval;

          if (data.interestRecalculationData.recalculationCompoundingFrequencyOnDay !== null) {
            scope.formData.recalculationCompoundingFrequencyNthDayType = -2;
            scope.formData.recalculationCompoundingFrequencyOnDayType = data.interestRecalculationData.recalculationCompoundingFrequencyOnDay;
          } else {
            if (data.interestRecalculationData.recalculationCompoundingFrequencyNthDay !== null) { scope.formData.recalculationCompoundingFrequencyNthDayType = data.interestRecalculationData.recalculationCompoundingFrequencyNthDay.id; }
            if (data.interestRecalculationData.recalculationCompoundingFrequencyWeekday !== null) { scope.formData.recalculationCompoundingFrequencyDayOfWeekType = data.interestRecalculationData.recalculationCompoundingFrequencyWeekday.id; }
          }
        }
      }
      if (data.allowAttributeOverrides !== null) {
        scope.amortizationType = data.allowAttributeOverrides.amortizationType;
        scope.arrearsTolerance = data.allowAttributeOverrides.inArrearsTolerance;
        scope.graceOnArrearsAging = data.allowAttributeOverrides.graceOnArrearsAgeing;
        scope.interestCalcPeriod = data.allowAttributeOverrides.interestCalculationPeriodType;
        scope.interestMethod = data.allowAttributeOverrides.interestType;
        scope.graceOnPrincipalAndInterest = data.allowAttributeOverrides.graceOnPrincipalAndInterestPayment;
        scope.repaymentFrequency = data.allowAttributeOverrides.repaymentEvery;
        scope.transactionProcessingStrategy = data.allowAttributeOverrides.transactionProcessingStrategyId;
      }
      if (scope.amortizationType || scope.arrearsTolerance || scope.graceOnArrearsAgeing ||
                  scope.interestCalcPeriod || scope.interestMethod || scope.graceOnPrincipalAndInterest ||
                  scope.repaymentFrequency || scope.transactionProcessingStrategy === true) {
        scope.allowAttributeConfiguration = true;
      } else {
        scope.allowAttributeConfiguration = false;
      }

      if (data.holdGuaranteeFunds) {
        scope.formData.mandatoryGuarantee = data.productGuaranteeData.mandatoryGuarantee;
        scope.formData.minimumGuaranteeFromOwnFunds = data.productGuaranteeData.minimumGuaranteeFromOwnFunds;
        scope.formData.minimumGuaranteeFromGuarantor = data.productGuaranteeData.minimumGuaranteeFromGuarantor;
      }

      _.each(data.principalVariationsForBorrowerCycle, function (letiation) {
        scope.formData.principalVariationsForBorrowerCycle.push({
          id: letiation.id,
          borrowerCycleNumber: letiation.borrowerCycleNumber,
          valueConditionType: letiation.valueConditionType.id,
          minValue: letiation.minValue,
          maxValue: letiation.maxValue,
          defaultValue: letiation.defaultValue
        });
      });

      _.each(data.interestRateVariationsForBorrowerCycle, function (letiation) {
        scope.formData.interestRateVariationsForBorrowerCycle.push({
          id: letiation.id,
          borrowerCycleNumber: letiation.borrowerCycleNumber,
          valueConditionType: letiation.valueConditionType.id,
          minValue: letiation.minValue,
          maxValue: letiation.maxValue,
          defaultValue: letiation.defaultValue
        });
      });

      _.each(data.numberOfRepaymentVariationsForBorrowerCycle, function (letiation) {
        scope.formData.numberOfRepaymentVariationsForBorrowerCycle.push({
          id: letiation.id,
          borrowerCycleNumber: letiation.borrowerCycleNumber,
          valueConditionType: letiation.valueConditionType.id,
          minValue: letiation.minValue,
          maxValue: letiation.maxValue,
          defaultValue: letiation.defaultValue
        });
      });

      if (scope.formData.accountingRule === 2 || scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
        scope.formData.fundSourceAccountId = data.accountingMappings.fundSourceAccount.id;
        scope.formData.loanPortfolioAccountId = data.accountingMappings.loanPortfolioAccount.id;
        if (scope.formData.accountingRule === 3 || scope.formData.accountingRule === 4) {
          scope.formData.receivableInterestAccountId = data.accountingMappings.receivableInterestAccount.id;
          scope.formData.receivableFeeAccountId = data.accountingMappings.receivableFeeAccount.id;
          scope.formData.receivablePenaltyAccountId = data.accountingMappings.receivablePenaltyAccount.id;
        }

        scope.formData.transfersInSuspenseAccountId = data.accountingMappings.transfersInSuspenseAccount.id;
        scope.formData.interestOnLoanAccountId = data.accountingMappings.interestOnLoanAccount.id;
        scope.formData.incomeFromFeeAccountId = data.accountingMappings.incomeFromFeeAccount.id;
        scope.formData.incomeFromPenaltyAccountId = data.accountingMappings.incomeFromPenaltyAccount.id;
        scope.formData.incomeFromRecoveryAccountId = data.accountingMappings.incomeFromRecoveryAccount.id;
        scope.formData.writeOffAccountId = data.accountingMappings.writeOffAccount.id;
        scope.formData.overpaymentLiabilityAccountId = data.accountingMappings.overpaymentLiabilityAccount.id;

        _.each(data.paymentChannelToFundSourceMappings, function (fundSource) {
          scope.configureFundOptions.push({
            paymentTypeId: fundSource.paymentType.id,
            fundSourceAccountId: fundSource.fundSourceAccount.id,
            paymentTypeOptions: data.paymentTypeOptions,
            assetAccountOptions: scope.assetAccountOptions
          });
        });

        _.each(data.feeToIncomeAccountMappings, function (fees) {
          scope.specificIncomeAccountMapping.push({
            chargeId: fees.charge.id,
            incomeAccountId: fees.incomeAccount.id
          });
        });

        _.each(data.penaltyToIncomeAccountMappings, function (penalty) {
          scope.penaltySpecificIncomeaccounts.push({
            chargeId: penalty.charge.id,
            incomeAccountId: penalty.incomeAccount.id
          });
        });
      }

      scope.formData.isLinkedToFloatingInterestRates = data.isLinkedToFloatingInterestRates;
      scope.formData.floatingRatesId = data.floatingRateId;
      scope.formData.interestRateDifferential = data.interestRateDifferential;
      scope.formData.isFloatingInterestRateCalculationAllowed = data.isFloatingInterestRateCalculationAllowed;
      scope.formData.minDifferentialLendingRate = data.minDifferentialLendingRate;
      scope.formData.defaultDifferentialLendingRate = data.defaultDifferentialLendingRate;
      scope.formData.maxDifferentialLendingRate = data.maxDifferentialLendingRate;
      scope.formData.allowVariableInstallments = data.allowVariableInstallments;
      scope.formData.minimumGap = data.minimumGap;
      scope.formData.maximumGap = data.maximumGap;
      scope.formData.canUseForTopup = data.canUseForTopup;
    });
  }
}

CreateLoanProductController.$inject = ['$scope', '$rootScope', 'ResourceFactory', '$location', 'dateFilter', 'WizardHandler'];
