import dayjs from 'dayjs';
const TODAY_DATE = new Date();

/**
 * @param {number} _numberOfYear
 */
const yearDiff = (_numberOfYear) => {
  const dayToMinus = 1000 * 60 * 60 * 24 * 365.4 * _numberOfYear;

  return new Date(TODAY_DATE.valueOf() - dayToMinus);
};

const hasPassedDate = (date) => {
  const getDate = dayjs(date);
  const today = dayjs(TODAY_DATE);
  const diff = getDate.diff(today, 'd');

  const hasPassed = today.isAfter(getDate);

  return { hasPassed, diff };
};

const dateStringToFormat = (value, format) => {
  if (!value) return '';
  const currentFormat = Array.isArray(value) ? 'DD MMMM YYYY' : 'DD MMMM YYYY HH:mm:ss';
  // For safari, because in safari, new Date('2018-08-23 11:12') will return Invalid Date.
  // Ref: https://github.com/iamkun/dayjs/issues/254#issuecomment-415278024
  if (Array.isArray(value)) value = value.join('/');
  return dayjs(value).format(format || currentFormat);
};

export {
  yearDiff,
  dateStringToFormat,
  hasPassedDate
};
