export function CreateProvisioningCriteriaController ($scope, resourceFactory, location, translate) {
  $scope.available = [];
  $scope.selected = [];
  $scope.selectedloanproducts = [];
  $scope.template = [];
  $scope.formData = {};
  $scope.translate = translate;
  $scope.isRequired = false;

  resourceFactory.provisioningcriteria.template({ criteriaId: 'template' }, function (data) {
    $scope.template = data;
    $scope.allloanproducts = data.loanProducts;
    $scope.definitions = data.definitions;
    $scope.liabilityaccounts = data.glAccounts;
    $scope.expenseaccounts = data.glAccounts;
  });

  $scope.addLoanProduct = function () {
    if (!this.available?.length) return;

    const { allloanproducts, available } = $scope;
    const selectedProducts = available.map(id => allloanproducts.find(product => product.id === Number(id)));

    $scope.selectedloanproducts.push(...selectedProducts);
    $scope.allloanproducts = allloanproducts.filter(product => !available.includes(`${product.id}`));
  };

  $scope.removeLoanProduct = function () {
    if (!this.selected?.length) return;

    const { selectedloanproducts, selected } = $scope;
    const selectedProducts = selected.map(id => selectedloanproducts.find(product => product.id === Number(id)));

    $scope.allloanproducts.push(...selectedProducts);
    $scope.selectedloanproducts = selectedloanproducts.filter(product => !selected.includes(`${product.id}`));
  };

  $scope.submit = function () {
    this.isRequired = true;
    this.formData.locale = $scope.optlang.code;
    this.formData.loanProducts = $scope.selectedloanproducts.map(product => ({ id: product.id, name: product.name, includeInBorrowerCycle: product.includeInBorrowerCycle }));
    this.formData.definitions = $scope.definitions;
    resourceFactory.provisioningcriteria.post(this.formData, function (data) {
      location.path(`/viewprovisioningcriteria/${data.resourceId}`);
    });
  };

  $scope.doFocus = function (index) {
    if (index > 0 && !$scope.definitions[index].minAge) {
      $scope.definitions[index].minAge = parseInt($scope.definitions[index - 1].maxAge, 10) + 1;
    }
  };

  $scope.doBlur = function () {
    // console.log("Blur") ;
  };
}

CreateProvisioningCriteriaController.$inject = ['$scope', 'ResourceFactory', '$location', '$translate'];
