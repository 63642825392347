<template>
  <div>
    <button
      class="btn btn-primary m-1"
      @click="showModal"
    >
      {{ $t('label.button.logs') }}
    </button>
    <base-modal
      ref="logsModal"
      modal-size="lg"
      show-button-close
      hide-footer
      scrollable
    >
      <template #header>
        {{ $t('label.heading.logs') }}
      </template>
      <template #body>
        <div v-if="!details">
          <div v-if="!displayData?.length">
            {{ $t('label.heading.noLogs') }}
          </div>
          <base-table
            v-else
            :fields="fields"
            :items="displayData"
          >
            <template #actions="{ item }">
              <button
                class="btn btn-link"
                @click="viewLog(item)"
              >
                {{ $t('label.button.viewDetails') }}
              </button>
            </template>
          </base-table>
        </div>
        <div v-else>
          <button
            class="btn btn-link px-0 mb-3"
            @click="details=null"
          >
            <span class="fa fa-arrow-left me-2" />
            {{ $t('label.button.back') }}
          </button>
          <pre>{{ details }}</pre>
        </div>
      </template>
    </base-modal>
  </div>
</template>

<script setup>
import BaseModal from '@/components/shared/BaseModal.vue';
import i18n from '@/plugins/i18n';
import { $notify } from '@/plugins/prototypes';
import apiService from '@/services/apiService';
import { ref } from 'vue';
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
});

const fields = ref([
  { key: 'timestamp', label: i18n.t('label.heading.timestamp') },
  { key: 'message', label: i18n.t('label.heading.message') },
  { key: 'requestId', label: i18n.t('label.heading.requestId') },
  { key: 'actions', label: i18n.t('label.heading.actions') }
]);
const displayData = ref([]);
const logsModal = ref(null);
const details = ref(null);

const showModal = () => {
  logsModal.value.show();
  getLogs();
};

const getLogs = async () => {
  try {
    const { data } = await apiService.clients.externalKycRequestLogs(props.data.id);
    displayData.value = data.logs;
  } catch (e) {
    $notify.error(e);
  }
};

const viewLog = (item) => {
  details.value = item;
};

</script>
