<template>
  <main-content-layout>
    <template #title>
      {{ $t(`label.heading.${pageTitle}`) }}
    </template>
    <form-wrapper
      ref="form"
      :disabled="disabled"
      @submit="submit"
    >
      <div class="row">
        <div class="col-12 col-lg-6">
          <input-select
            id="document-id"
            v-model="formData.documentTypeId"
            required
            value-field="id"
            :options="documentTypeItem"
            :label="$t('label.anchor.documentId')"
          />
          <input-select
            id="status"
            v-model="formData.status"
            required
            text-field="label"
            value-field="value"
            :options="statusTypeItem"
            :label="$t('label.anchor.status')"
          />
          <input-text
            id="document-key"
            v-model="formData.documentKey"
            required
            :label="$t('label.anchor.uniqueId')"
          />
          <input-text
            id="description"
            v-model="formData.description"
            :label="$t('label.anchor.description')"
            type="textarea"
          />
          <input-date-picker
            id="expiry-date"
            v-model="formData.expiryDate"
            :min-date="minDate"
            :label="$t('label.anchor.expiryDate')"
            :required="isI20TypeSelected"
          />
          <input-select
            v-if="isI20TypeSelected"
            id="nationality"
            v-model="formData.nationality"
            required
            value-field="id"
            :options="nationalityItem"
            :label="$t('label.anchor.nationality')"
          />
          <input-text
            id="issued-by"
            v-model="formData.issuedBy"
            :label="$t('label.input.issuedBy')"
          />
        </div>
        <div class="w-100 mt-3">
          <div class="col-md-5 text-end">
            <button
              class="btn btn-default me-3"
              @click.prevent="cancel"
            >
              {{ $t('label.button.cancel') }}
            </button>
            <button
              id="save"
              class="btn btn-primary"
              type="submit"
            >
              {{ $t('label.button.save') }}
            </button>
          </div>
        </div>
      </div>
    </form-wrapper>
  </main-content-layout>
</template>

<script setup>
import { InputText, FormWrapper, InputSelect, InputDatePicker } from '@/components/shared/form-input/index';
import { onMounted, ref, computed, reactive, watch } from 'vue';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import { dateStringToFormat } from '@/helper/dateHelper';
import { $notify } from '@/plugins/prototypes';
import { get, set } from '@vueuse/core';
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import apiService from '@/services/apiService';
import router from '@/router';
import i18n from '@/plugins/i18n';

const breadCrumbsStore = useBreadcrumbsStore();
const { clientId, identifierId } = router.currentRoute.params;

const isI20TypeSelected = ref(false);
const documentTypeItem = ref([]);
const today = new Date();
const form = ref(null);
const minDate = new Date(today.setDate(today.getDate() - 1));
const nationalityItem = ref([]);
const statusTypeItem = ref([
  { value: 'ACTIVE', label: 'Active', code: 'clientIdentifierStatusType.active' },
  { value: 'INACTIVE', label: 'Inactive', code: 'clientIdentifierStatusType.inactive' }
]);
const formData = reactive({
  locale: 'en',
  dateFormat: 'dd MMMM yyyy',
  documentTypeId: '',
  status: '',
  documentKey: '',
  description: '',
  expiryDate: '',
  nationality: '',
  issuedBy: ''
});
const disabled = ref(false);

const pageTitle = computed(() => identifierId ? 'editClientIdentifier' : 'addClientIdentifier');
onMounted(async () => {
  try {
    const { data: { displayName } } = await apiService.clients.get(clientId);
    breadCrumbsStore.setBreadcrumbs([
      {
        text: i18n.t('label.anchor.clients'),
        href: '#/clients'
      },
      {
        text: displayName,
        href: `#/viewclient/${clientId}`
      },
      {
        text: i18n.t(`label.heading.${get(pageTitle)}`)
      }
    ]);

    const { data: { allowedDocumentTypes } } = await apiService.identifiers.template(clientId);
    set(documentTypeItem, allowedDocumentTypes);

    if (identifierId) {
      const { data: { expiryDate, documentKey, description, status, nationality, issuedBy, documentType: { id } } } = await apiService.identifiers.get(clientId, identifierId, { unmaskValue: 'true' });
      formData.documentTypeId = id;
      formData.status = get(statusTypeItem).find(({ code }) => code === status).value;
      formData.documentKey = documentKey;
      formData.expiryDate = dateStringToFormat(expiryDate);
      formData.description = description;
      formData.nationality = nationality;
      formData.issuedBy = issuedBy;
    }
  } catch (e) {
    $notify.error(e);
  }
});

watch(() => formData.documentTypeId, async (newVal) => {
  const isI20DocumentType = get(documentTypeItem).find(({ id }) => newVal === id).name === 'I-20';
  if (isI20DocumentType && !get(nationalityItem).length) {
    const { data: { countryIdOptions } } = await apiService.clients.addressTemplate();
    set(nationalityItem, countryIdOptions);
  }
  get(form).validate();
  set(isI20TypeSelected, isI20DocumentType);
});

const cancel = () => location.assign(`#/viewclient/${clientId}`);

const submit = async () => {
  try {
    if (!get(isI20TypeSelected)) delete formData.nationality;
    identifierId ? await apiService.identifiers.update(clientId, identifierId, formData) : await apiService.identifiers.add(clientId, formData);
    cancel();
  } catch (e) {
    $notify.error(e);
  }
};
</script>
