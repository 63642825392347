import { dateStringToFormat } from '@/helper/dateHelper';
export function ViewLoanDetailsController (scope, routeParams, resourceFactory, paginatorService, location, route, http, $uibModal, dateFilter, API_VERSION, $sce, $rootScope) {
  scope.loandocuments = [];
  scope.report = false;
  scope.hidePentahoReport = true;
  scope.formData = {};
  scope.date = {};
  scope.date.payDate = new Date();
  scope.hideAccrualTransactions = false;
  scope.isHideAccrualsCheckboxChecked = true;
  scope.loandetails = [];
  scope.defaultDecimalPlace = 2;
  scope.activeTab = 0;
  scope.isRevolving = false;

  scope.showSchedule = function () {
    scope.showSchedulePayment = true;
  };

  scope.routeTo = function (loanId, transactionId, transactionTypeId) {
    if (transactionTypeId === 2 || transactionTypeId === 4 || transactionTypeId === 1) {
      location.path(`/viewloantrxn/${loanId}/trxnId/${transactionId}`);
    }
  };

  /** *
             * we are using orderBy(https://docs.angularjs.org/api/ng/filter/orderBy) filter to sort fields in ui
             * api returns dates in array format[yyyy, mm, dd], converting the array of dates to date object
             * @param dateFieldName
             */
  scope.convertDateArrayToObject = function (dateFieldName) {
    for (const i in scope.loandetails.transactions) {
      scope.loandetails.transactions[i][dateFieldName] = dateStringToFormat(scope.loandetails.transactions[i].date);
    }
  };

  scope.clickEvent = function (eventName, accountId) {
    eventName = eventName || '';
    switch (eventName) {
      case 'addloancharge':
        location.path(`/addloancharge/${accountId}`);
        break;
      case 'addcollateral':
        location.path(`/addcollateral/${accountId}`);
        break;
      case 'assignloanofficer':
      case 'changeloanofficer':
        location.path(`/assignloanofficer/${accountId}`);
        break;
      case 'modifyapplication': {
        let destination = `/editloanaccount/${accountId}`;
        if (scope.isRevolving) { destination = `/editrevolvingloanaccount/${accountId}`; }
        location.path(destination);
        break;
      }
      case 'approve':
        location.path(`/loanaccount/${accountId}/approve`);
        break;
      case 'reject':
        location.path(`/loanaccount/${accountId}/reject`);
        break;
      case 'withdrawnbyclient':
        location.path(`/loanaccount/${accountId}/withdrawnByApplicant`);
        break;
      case 'delete':
        resourceFactory.LoanAccountResource.delete({ loanId: accountId }, {}, function (data) {
          let destination = `/viewgroup/${data.groupId}`;
          if (data.clientId) destination = `/viewclient/${data.clientId}`;
          location.path(destination);
        });
        break;
      case 'undoapproval':
        location.path(`/loanaccount/${accountId}/undoapproval`);
        break;
      case 'disburse':
        location.path(`/loanaccount/${accountId}/disburse`);
        break;
      case 'disbursetosavings':
        location.path(`/loanaccount/${accountId}/disbursetosavings`);
        break;
      case 'undodisbursal':
        location.path(`/loanaccount/${accountId}/undodisbursal`);
        break;
      case 'makerepayment':
        location.path(`/loanaccount/${accountId}/repayment`);
        break;
      case 'prepayment':
        location.path(`/loanaccount/${accountId}/prepayloan`);
        break;
      case 'waiveinterest':
        location.path(`/loanaccount/${accountId}/waiveinterest`);
        break;
      case 'writeoff':
        location.path(`/loanaccount/${accountId}/writeoff`);
        break;
      case 'recoverypayment':
        location.path(`/loanaccount/${accountId}/recoverypayment`);
        break;
      case 'close-rescheduled':
        location.path(`/loanaccount/${accountId}/close-rescheduled`);
        break;
      case 'transferFunds':
        if (scope.loandetails.clientId) {
          location.path(`/accounttransfers/fromloans/${accountId}`);
        }
        break;
      case 'close':
        location.path(`/loanaccount/${accountId}/close`);
        break;
      case 'createguarantor':
        location.path(`/guarantor/${accountId}`);
        break;
      case 'listguarantor':
        location.path(`/listguarantors/${accountId}`);
        break;
      case 'recoverguarantee':
        location.path(`/loanaccount/${accountId}/recoverguarantee`);
        break;
      case 'unassignloanofficer':
        location.path(`/loanaccount/${accountId}/unassignloanofficer`);
        break;
      case 'loanscreenreport':
        location.path(`/loanscreenreport/${accountId}`);
        break;
      case 'reschedule':
        location.path(`/loans/${accountId}/reschedule`);
        break;
      case 'adjustrepaymentschedule':
        location.path(`/adjustrepaymentschedule/${accountId}`);
        break;
      case 'foreclosure':
        location.path(`loanforeclosure/${accountId}`);
        break;
    }
  };

  scope.delCharge = function (id) {
    $uibModal.open({
      templateUrl: 'delcharge.html',
      controller: DelChargeCtrl,
      resolve: {
        ids () {
          return id;
        }
      }
    });
  };

  const DelChargeCtrl = function ($scope, $uibModalInstance, ids) {
    $scope.delete = function () {
      resourceFactory.LoanAccountResource.delete({ loanId: routeParams.id, resourceType: 'charges', chargeId: ids }, {}, function () {
        $uibModalInstance.close('delete');
        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  resourceFactory.LoanAccountResource.getLoanAccountDetails({ loanId: routeParams.id, associations: 'all', exclude: 'guarantors,futureSchedule' }, function (data) {
    scope.loandetails = data;
    scope.formatDecimalPlace = data.currency.decimalPlaces;
    scope.isRevolving = data.isRevolving;
    scope.isGenerateRepaymentSchedule = data.isGenerateRepaymentSchedule;
    scope.convertDateArrayToObject('date');
    scope.recalculateInterest = data.recalculateInterest || true;
    scope.isWaived = scope.loandetails.repaymentSchedule.totalWaived > 0;
    scope.date.fromDate = new Date(data.timeline.actualDisbursementDate);
    scope.date.toDate = new Date();
    scope.status = data.status.value;
    scope.chargeAction = data.status.value === 'Submitted and pending approval';
    scope.decimals = data.currency.decimalPlaces;
    if (scope.loandetails.charges) {
      scope.charges = scope.loandetails.charges;
      for (const charge of scope.charges) {
        const isChargePaidOrWaived = charge.paid || charge.waived;
        const isChargeDisbursement = charge.chargeTimeType.value === 'Disbursement';
        const isLoanActive = scope.loandetails.status.value === 'Active';
        const actionFlag = isChargePaidOrWaived || isChargeDisbursement || !isLoanActive;
        charge.actionFlag = actionFlag;
      }

      scope.chargeTableShow = true;
    } else {
      scope.chargeTableShow = false;
    }
    if (scope.status === 'Submitted and pending approval' || scope.status === 'Active' || scope.status === 'Approved') {
      scope.choice = true;
    }
    if (data.status.value === 'Submitted and pending approval') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.addloancharge',
            icon: 'fas fa-plus',
            taskPermissionName: 'CREATE_LOANCHARGE'
          },
          {
            name: 'button.approve',
            icon: 'fas fa-check',
            taskPermissionName: 'APPROVE_LOAN'
          },
          {
            name: 'button.modifyapplication',
            icon: 'fa fa-pincel-square-o',
            taskPermissionName: 'UPDATE_LOAN'
          },
          {
            name: 'button.reject',
            icon: 'fas fa-times',
            taskPermissionName: 'REJECT_LOAN'
          }
        ],
        options: [
          {
            name: (scope.loandetails.loanOfficerName ? 'button.changeloanofficer' : 'button.assignloanofficer'),
            taskPermissionName: 'UPDATELOANOFFICER_LOAN'
          },
          {
            name: 'button.withdrawnbyclient',
            taskPermissionName: 'WITHDRAW_LOAN'
          },
          {
            name: 'button.delete',
            taskPermissionName: 'DELETE_LOAN'
          },
          {
            name: 'button.addcollateral',
            taskPermissionName: 'CREATE_COLLATERAL'
          },
          {
            name: 'button.listguarantor',
            taskPermissionName: 'READ_GUARANTOR'
          },
          {
            name: 'button.createguarantor',
            taskPermissionName: 'CREATE_GUARANTOR'
          },
          {
            name: 'button.loanscreenreport',
            taskPermissionName: 'READ_LOAN'
          }
        ]

      };
      if (data.isVariableInstallmentsAllowed) {
        scope.buttons.options.push({
          name: 'button.adjustrepaymentschedule',
          taskPermissionName: 'ADJUST_REPAYMENT_SCHEDULE'
        });
      }
    }

    if (data.status.value === 'Approved') {
      scope.buttons = {
        singlebuttons: [
          {
            name: (scope.loandetails.loanOfficerName ? 'button.changeloanofficer' : 'button.assignloanofficer'),
            icon: 'fas fa-user',
            taskPermissionName: 'UPDATELOANOFFICER_LOAN'
          },
          {
            name: 'button.disburse',
            icon: 'fas fa-flag',
            taskPermissionName: 'DISBURSE_LOAN'
          },
          {
            name: 'button.disbursetosavings',
            icon: 'fas fa-flag',
            taskPermissionName: 'DISBURSETOSAVINGS_LOAN'
          },
          {
            name: 'button.undoapproval',
            icon: 'fas fa-undo',
            taskPermissionName: 'APPROVALUNDO_LOAN'
          }
        ],
        options: [
          {
            name: 'button.addloancharge',
            taskPermissionName: 'CREATE_LOANCHARGE'
          },
          {
            name: 'button.listguarantor',
            taskPermissionName: 'READ_GUARANTOR'
          },
          {
            name: 'button.createguarantor',
            taskPermissionName: 'CREATE_GUARANTOR'
          },
          {
            name: 'button.loanscreenreport',
            taskPermissionName: 'READ_LOAN'
          }
        ]

      };
    }

    if (data.status.value === 'Active') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.addloancharge',
            icon: 'fas fa-plus',
            taskPermissionName: 'CREATE_LOANCHARGE'
          },
          {
            name: 'button.foreclosure',
            icon: 'icon-dollar',
            taskPermissionName: 'FORECLOSURE_LOAN'
          },
          {
            name: 'button.makerepayment',
            icon: 'fas fa-dollar-sign',
            taskPermissionName: 'REPAYMENT_LOAN'
          },
          {
            name: 'button.undodisbursal',
            icon: 'fas fa-undo',
            taskPermissionName: 'DISBURSALUNDO_LOAN'
          }
        ],
        options: [
          {
            name: 'button.waiveinterest',
            taskPermissionName: 'WAIVEINTERESTPORTION_LOAN'
          },
          {
            name: 'button.reschedule',
            taskPermissionName: 'CREATE_RESCHEDULELOAN'
          },
          {
            name: 'button.writeoff',
            taskPermissionName: 'WRITEOFF_LOAN'
          },
          {
            name: 'button.close-rescheduled',
            taskPermissionName: 'CLOSEASRESCHEDULED_LOAN'
          },
          {
            name: 'button.close',
            taskPermissionName: 'CLOSE_LOAN'
          },
          {
            name: 'button.loanscreenreport',
            taskPermissionName: 'READ_LOAN'
          },
          {
            name: 'button.listguarantor',
            taskPermissionName: 'READ_GUARANTOR'
          },
          {
            name: 'button.createguarantor',
            taskPermissionName: 'CREATE_GUARANTOR'
          },
          {
            name: 'button.recoverguarantee',
            taskPermissionName: 'RECOVERGUARANTEES_LOAN'
          }
        ]

      };

      if (data.canDisburse || data.isRevolving) {
        scope.buttons.singlebuttons.splice(1, 0, {
          name: 'button.disburse',
          icon: 'fas fa-flag',
          taskPermissionName: 'DISBURSE_LOAN'
        });
        scope.buttons.singlebuttons.splice(1, 0, {
          name: 'button.disbursetosavings',
          icon: 'fas fa-flag',
          taskPermissionName: 'DISBURSETOSAVINGS_LOAN'
        });
      }
      // loan officer not assigned to loan, below logic
      // helps to display otherwise not
      if (!data.loanOfficerName) {
        scope.buttons.singlebuttons.splice(1, 0, {
          name: 'button.assignloanofficer',
          icon: 'fas fa-user',
          taskPermissionName: 'UPDATELOANOFFICER_LOAN'
        });
      }

      if (scope.recalculateInterest) {
        scope.buttons.singlebuttons.splice(1, 0, {
          name: 'button.prepayment',
          icon: 'fas fa-money-bill-alt',
          taskPermissionName: 'REPAYMENT_LOAN'
        });
      }
    }
    if (data.status.value === 'Overpaid') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.transferFunds',
            icon: 'fas fa-exchange-alt',
            taskPermissionName: 'CREATE_ACCOUNTTRANSFER'
          }
        ]
      };
    }
    if (data.status.value === 'Closed (written off)') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.recoverypayment',
            icon: 'fas fa-briefcase',
            taskPermissionName: 'RECOVERYPAYMENT_LOAN'
          }
        ]
      };
    }

    resourceFactory.standingInstructionTemplateResource.get({ fromClientId: scope.loandetails.clientId, fromAccountType: 1, fromAccountId: routeParams.id }, function (response) {
      scope.standinginstruction = response;
      scope.searchTransaction();
    });
  });

  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = scope.optlang.code;
    params.fromAccountId = routeParams.id;
    params.fromAccountType = 1;
    params.clientId = scope.loandetails.clientId;
    params.clientName = scope.loandetails.clientName;
    params.dateFormat = scope.df;

    resourceFactory.standingInstructionResource.search(params, callback);
  };

  scope.searchTransaction = function () {
    scope.displayResults = true;
    scope.instructions = paginatorService.paginate(fetchFunction, 14);
    scope.isCollapsed = false;
  };

  scope.deletestandinginstruction = function (id) {
    $uibModal.open({
      templateUrl: 'delInstruction.html',
      controller: DelInstructionCtrl,
      resolve: {
        ids () {
          return id;
        }
      }
    });
  };

  const DelInstructionCtrl = function ($scope, $uibModalInstance, ids) {
    $scope.delete = function () {
      resourceFactory.standingInstructionResource.cancel({ standingInstructionId: ids }, function () {
        scope.searchTransaction();
        $uibModalInstance.close('delete');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  resourceFactory.loanResource.getAllNotes({ loanId: routeParams.id, resourceType: 'notes' }, function (data) {
    scope.loanNotes = data;
  });

  scope.saveNote = function () {
    if (!scope.formData.note) return;
    resourceFactory.loanResource.save({ loanId: routeParams.id, resourceType: 'notes' }, this.formData, function (data) {
      const today = new Date();
      const temp = { id: data.resourceId, note: scope.formData.note, createdByUsername: 'test', createdOn: today };
      scope.loanNotes.push(temp);
      scope.formData.note = '';
      scope.predicate = '-id';
    });
  };

  scope.getLoanDocuments = function () {
    resourceFactory.LoanDocumentResource.getLoanDocuments({ loanId: routeParams.id }, function (data) {
      scope.loandocuments = data.map(item => {
        item.docUrl = `${API_VERSION}/loans/${item.parentEntityId}/documents/${item.id}/attachment?tenantIdentifier=${$rootScope.tenantIdentifier}`;

        if (item?.fileName) { item.fileIsImage = ['.jpg', '.jpeg', '.png'].includes(item.fileName.toLowerCase()); }

        if (item?.type) { item.fileIsImage = item.type.toLowerCase().includes('image'); }

        return item;
      });
    });
  };

  resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_loan' }, function (data) {
    scope.loandatatables = data;
  });

  scope.downloadDocumentXhr = function (xurl, fileType, fileName) {
    const configObj = {
      method: 'GET',
      url: xurl,
      responseType: 'arraybuffer'
    };

    http(configObj)
      .then(function onFulfilled (response) {
        const a = document.createElement('a');
        const windowUrl = window.URL || window.webkitURL;
        const blob = new Blob([response.data], { type: fileType, responseType: 'arraybuffer' });

        const url = windowUrl.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        windowUrl.revokeObjectURL(url);
      }).catch(function onRejection (errorResponse) {
        console.error({ errorResponse });
      });
  };

  scope.dataTableChange = function (datatable) {
    resourceFactory.DataTablesResource.getTableDetails({
      datatablename: datatable.registeredTableName,
      entityId: routeParams.id,
      genericResultSet: 'true'
    }, function (data) {
      scope.datatabledetails = data;
      scope.datatabledetails.isData = data.data.length > 0;
      scope.datatabledetails.isMultirow = data.columnHeaders[0].columnName === 'id';
      scope.showDataTableAddButton = !scope.datatabledetails.isData || scope.datatabledetails.isMultirow;
      scope.showDataTableEditButton = scope.datatabledetails.isData && !scope.datatabledetails.isMultirow;
      scope.singleRow = [];
      for (const i in data.columnHeaders) {
        if (scope.datatabledetails.columnHeaders[i].columnCode) {
          for (const j in scope.datatabledetails.columnHeaders[i].columnValues) {
            for (const k in data.data) {
              if (data.data[k].row[i] === scope.datatabledetails.columnHeaders[i].columnValues[j].id) {
                data.data[k].row[i] = scope.datatabledetails.columnHeaders[i].columnValues[j].value;
              }
            }
          }
        }
      }
      if (scope.datatabledetails.isData) {
        for (const i in data.columnHeaders) {
          if (!scope.datatabledetails.isMultirow) {
            const row = {};
            row.key = data.columnHeaders[i].columnName;
            row.value = data.data[0].row[i];
            scope.singleRow.push(row);
          }
        }
      }
    });
  };

  scope.export = function () {
    scope.report = true;
    scope.printbtn = false;
    scope.viewReport = false;
    scope.viewLoanReport = true;
    scope.viewTransactionReport = false;
  };

  scope.viewJournalEntries = function () {
    location.path('/accounting/search-journal-entries').search({ loanId: scope.loandetails.id });
  };

  scope.viewLoanDetails = function () {
    scope.report = false;
    scope.hidePentahoReport = true;
    scope.viewReport = false;
  };

  scope.viewLoanCollateral = function (collateralId) {
    location.path(`/loan/${scope.loandetails.id}/viewcollateral/${collateralId}`).search({ status: scope.loandetails.status.value });
  };

  scope.viewDataTable = function (registeredTableName, data) {
    if (scope.datatabledetails.isMultirow) {
      location.path(`/viewdatatableentry/${registeredTableName}/${scope.loandetails.id}/${data.row[0]}`);
    } else {
      location.path(`/viewsingledatatableentry/${registeredTableName}/${scope.loandetails.id}`);
    }
  };

  scope.viewLoanChargeDetails = function (chargeId) {
    location.path(`/loan/${scope.loandetails.id}/viewcharge/${chargeId}`).search({ loanstatus: scope.loandetails.status.value });
  };

  scope.viewprintdetails = function () {
    // scope.printbtn = true;
    scope.report = true;
    scope.viewTransactionReport = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;
    scope.formData.outputType = 'PDF';
    scope.baseURL = `${$rootScope.hostUrl + API_VERSION}/runreports/${encodeURIComponent('Client Loan Account Schedule')}`;
    scope.baseURL += `?output-type=${encodeURIComponent(scope.formData.outputType)}&tenantIdentifier=${$rootScope.tenantIdentifier}&locale=${scope.optlang.code}`;

    let reportParams = '';
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');
    let paramName = 'R_startDate';
    reportParams += `${encodeURIComponent(paramName)}=${encodeURIComponent(scope.startDate)}&`;
    paramName = 'R_endDate';
    reportParams += `${encodeURIComponent(paramName)}=${encodeURIComponent(scope.endDate)}&`;
    paramName = 'R_selectLoan';
    reportParams += `${encodeURIComponent(paramName)}=${encodeURIComponent(scope.loandetails.accountNo)}`;
    if (reportParams > '') {
      scope.baseURL += `&${reportParams}`;
    }
    // allow untrusted urls for iframe http://docs.angularjs.org/error/$sce/insecurl
    scope.viewReportDetails = $sce.trustAsResourceUrl(scope.baseURL);
  };

  scope.viewloantransactionreceipts = function (transactionId) {
    // scope.printbtn = true;
    scope.report = true;
    scope.viewTransactionReport = true;
    scope.viewLoanReport = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;

    resourceFactory.loanTransactionReciept.get({ R_transactionId: transactionId }, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      scope.viewReportDetails = $sce.trustAsResourceUrl(fileURL);
    });
  };
  scope.viewloantransactionjournalentries = function (txId) {
    const transactionId = `L${txId}`;
    if (scope.loandetails.clientId !== null && scope.loandetails.clientId !== '') {
      location.path(`/viewtransactions/${transactionId}`).search({
        productName: scope.loandetails.loanProductName,
        loanId: scope.loandetails.id,
        clientId: scope.loandetails.clientId,
        accountNo: scope.loandetails.accountNo,
        clientName: scope.loandetails.clientName
      });
    } else {
      location.path(`/viewtransactions/${transactionId}`).search({
        productName: scope.loandetails.loanProductName,
        loanId: scope.loandetails.id,
        accountNo: scope.loandetails.accountNo,
        groupId: scope.loandetails.group.id,
        groupName: scope.loandetails.group.name
      });
    }
  };

  scope.printReport = function () {
    window.print();
    window.close();
  };

  scope.deleteAll = function (apptableName, entityId, text) {
    confirm(text) && resourceFactory.DataTablesResource.delete({ datatablename: apptableName, entityId, genericResultSet: 'true' }, {}, function () {
      route.reload();
    });
  };

  scope.deleteDocument = function (documentId, index) {
    resourceFactory.LoanDocumentResource.delete({ loanId: scope.loandetails.id, documentId }, '', function () {
      scope.loandocuments.splice(index, 1);
    });
  };

  scope.previewDocument = function (xurl, fileName) {
    scope.preview = true;

    http({
      method: 'GET',
      url: xurl,
      responseType: 'arraybuffer'
    }).then(function (imageData) {
      let binary = '';
      const bytes = new Uint8Array(imageData.data);
      const len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      scope.fileBase64 = btoa(binary);
    });

    if (fileName.toLowerCase().indexOf('.png') !== -1) { scope.fileType = 'image/png'; } else if (fileName.toLowerCase().indexOf('.jpg') !== -1) { scope.fileType = 'image/jpg'; } else if (fileName.toLowerCase().indexOf('.jpeg') !== -1) { scope.fileType = 'image/jpeg'; }
  };

  scope.transactionSort = {
    column: 'date',
    descending: true
  };
  scope.changeTransactionSort = function (column) {
    const sort = scope.transactionSort;
    if (sort.column === column) {
      sort.descending = !sort.descending;
    } else {
      sort.column = column;
      sort.descending = true;
    }
  };

  scope.showEdit = function (disbursementDetail) {
    if ((!disbursementDetail.actualDisbursementDate || disbursementDetail.actualDisbursementDate === null) &&
      scope.status === 'Approved') {
      return true;
    }
    return false;
  };

  scope.showApprovedAmountBasedOnStatus = function () {
    if (scope.status === 'Submitted and pending approval' || scope.status === 'Withdrawn by applicant' || scope.status === 'Rejected') {
      return false;
    }
    return true;
  };
  scope.showDisbursedAmountBasedOnStatus = function () {
    if (scope.status === 'Submitted and pending approval' || scope.status === 'Withdrawn by applicant' || scope.status === 'Rejected' ||
      scope.status === 'Approved') {
      return false;
    }
    return true;
  };

  scope.checkStatus = function () {
    if (scope.status === 'Active' || scope.status === 'Closed (obligations met)' || scope.status === 'Overpaid' ||
      scope.status === 'Closed (rescheduled)' || scope.status === 'Closed (written off)') {
      return true;
    }
    return false;
  };

  scope.showAddDeleteTrancheButtons = function (action) {
    scope.return = true;
    if (scope.status === 'Closed (obligations met)' || scope.status === 'Overpaid' ||
      scope.status === 'Closed (rescheduled)' || scope.status === 'Closed (written off)' ||
      scope.status === 'Submitted and pending approval') {
      scope.return = false;
    }
    scope.totalDisbursedAmount = 0;
    scope.count = 0;
    for (const i in scope.loandetails.disbursementDetails) {
      if (scope.loandetails.disbursementDetails[i].actualDisbursementDate !== null) {
        scope.totalDisbursedAmount += scope.loandetails.disbursementDetails[i].principal;
      } else {
        scope.count += 1;
      }
    }
    if (scope.totalDisbursedAmount === scope.loandetails.approvedPrincipal || scope.return === false) {
      return false;
    }
    if (scope.count === 0 && action === 'deletedisbursedetails') {
      return false;
    }

    return true;
  };
}

ViewLoanDetailsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'paginatorService', '$location', '$route', '$http', '$uibModal', 'dateFilter', 'API_VERSION', '$sce', '$rootScope'];
