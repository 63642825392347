export function EditCardProductController (scope, routeParams, location, anchorScroll, resourceFactory) {
  scope.product = {
    id: routeParams.id,
    blockedCountries: []
  };
  scope.getCardProducts = [];
  scope.regions = [];
  scope.velocityRules = {
    WITHDRAWAL: {
      SECONDS: {},
      DAYS: {},
      WEEKS: {},
      MONTHS: {}
    },
    PURCHASE: {
      SECONDS: {},
      DAYS: {},
      WEEKS: {},
      MONTHS: {}
    }
  };

  scope.creditProducts = [];
  scope.error = '';
  resourceFactory.cardProductsResource.get(data => {
    scope.getCardProducts = data.pageItems;
  });
  resourceFactory.cardProcessorsResource.get((data) => {
    if (!data.length) return;
    scope.cardProcessors = data;
  });

  resourceFactory.clientaddressFields.get(function (clientaddress) {
    scope.regions = clientaddress.countryIdOptions;
  });

  resourceFactory.cardProductsResource.get({ cardProductId: routeParams.id }, (response) => {
    scope.product = response;
    if (scope.product.cardType === 'CREDIT' && scope.creditProducts.length === 0) {
      resourceFactory.creditProductResource.getAllCreditProducts(function (data) {
        scope.creditProducts = data;
      });
    }

    // set velocityRules
    response.velocityRules.forEach(({ controls, id, value, timeUnit }) => {
      ['WITHDRAWAL', 'PURCHASE'].forEach((control) => {
        if (controls[0] === control) {
          ['SECONDS', 'DAYS', 'WEEKS', 'MONTHS'].forEach((unit) => {
            if (timeUnit === unit) {
              scope.velocityRules[control][timeUnit] = { id, value };
            }
          });
        }
      });
    });
  });

  scope.scrollto = function (link) {
    location.hash(link);
    anchorScroll();
  };

  scope.handleCountriesChanged = (value) => {
    scope.product.blockedCountries = value;
  };

  scope.update = function () {
    const submitData = cardProductDataNormalize();
    scope.error = '';
    // invalid fields
    if (scope.product.bin.length < 6 || scope.product.bin.length > 8) {
      scope.error = 'label.error';
      return;
    }
    if (!scope?.product?.name) {
      scope.error = 'label.error';
      return;
    }
    resourceFactory.cardProductsResource.update({ cardProductId: routeParams.id }, submitData, () => { location.path(`/viewcardproduct/${scope.product.id}`); }, ({ data: { errors } }) => {
      scope.error = `${errors[0].defaultUserMessage}`;
    });
  };

  function cardProductDataNormalize () {
    const {
      replacementLimit, activationUrl, onlinePaymentsEnabled, contactlessPaymentsEnabled, atmWithdrawalsEnabled, internationalPaymentsEnabled, posPaymentsEnabled,
      name, bin, manualPin, blockedCountries, maxActiveCardAllowed, upgradableToProductId, creditProductId
    } = scope.product;
    const controls = Object.keys(scope.velocityRules);

    let normalizeData = {
      replacementLimit,
      activationUrl,
      onlinePaymentsEnabled,
      contactlessPaymentsEnabled,
      atmWithdrawalsEnabled,
      internationalPaymentsEnabled,
      posPaymentsEnabled,
      name,
      bin,
      manualPin,
      blockedCountries,
      maxActiveCardAllowed,
      upgradableToProductId,
      creditProductId
    };
    const velocityRules = controls.map((control) => {
      const timeUnits = Object.keys(scope.velocityRules[control]);

      return timeUnits.map((timeUnit) => {
        const { value } = scope.velocityRules[control][timeUnit];

        return {
          controls: [control.toUpperCase()],
          type: 'AMOUNT',
          timePeriod: 1,
          timeUnit: timeUnit.toUpperCase(),
          value
        };
      });
    }).flat().filter(velocityRule => !!velocityRule?.value && velocityRule?.value > 0);

    if (velocityRules.length > 0) {
      normalizeData = { ...normalizeData, velocityRules };
    }

    return normalizeData;
  }

  scope.closeAlert = function () {
    scope.error = '';
  };
}

EditCardProductController.$inject = ['$scope', '$routeParams', '$location', '$anchorScroll', 'ResourceFactory'];
