export function ApiValidationDirective ($compile, $rootScope) {
  return {
    restrict: 'E',
    require: '?ngmodel',
    link (scope, elm) {
      $rootScope.setApiValidationError = function (errorDetails) {
        $rootScope.errorDetails = [errorDetails];
        scope.$apply();
      };

      const template = `<div class="alert alert-danger alert-dismissible" ng-show="errorStatus || errorDetails.length > 0">
        <button type="button" data-id="api-validation" class="close btn-close btn position-absolute end-0 me-2 top-0" aria-label="Close"></button>
        <div>
          <span><i class="fas fa-exclamation-circle me-2"></i></span>
          <span ng-if="errorStatus">{{errorStatus}}<br/></span>
          <div ng-repeat="errorArray in errorDetails" ng-show="errorStatus !== 'Error GraphQL'">
            <label ng-if="errorArray.args.params[0].value">
              {{errorArray.args.params[$index].value | translate}} field is required
            </label>
            <label ng-if="errorArray.message">{{errorArray.message}}</label>
            <span ng-repeat="error in errorArray" ng-if="error.body">
                <label ng-hide="errorStatus">{{ error.body.errors[$index].defaultUserMessage }}</label>
            </span>
            <label ng-if="errorArray.defaultUserMessage">{{errorArray.defaultUserMessage}}</label>
          </div>

          <div ng-repeat="errorArray in errorDetails" ng-show="errorStatus === 'Error GraphQL'">
            <i class="fas fa-exclamation-circle me-2"></i>
            <label>{{ errorArray.message }}</label>
          </div>
        </div>
      </div>`;

      elm.html('').append($compile(template)(scope));

      $('button[data-id=api-validation]').on('click', function () {
        // form validation error
        delete scope.errorDetails;
        // api validation error
        delete $rootScope.errorStatus;
        delete $rootScope.errorDetails;
        $rootScope.$digest();
      });
    }
  };
}

ApiValidationDirective.$inject = ['$compile', '$rootScope'];
