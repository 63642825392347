<template>
  <main-content-layout>
    <template #title>
      {{ $t('label.anchor.createSubAccount') }}
    </template>
    <form-wrapper
      class="col-12 col-lg-6"
      @submit="onSubmit"
    >
      <input-select
        v-model="formData.primaryAccountId"
        :label="$t('label.input.selectPrimaryAccount')"
        :options="primaryAccounts"
        text-field="accountNo"
        value-field="id"
        required
      >
        <template #option="{ item }">
          <div class="fs-6 fw-bold mb-1">
            {{ item.savingsAccountNickname || item.savingsProductName }}
          </div>
          <div>
            {{ item.accountNo }}
          </div>
        </template>
        <template #selected-option="{ item }">
          <div>
            <span
              v-if="item.savingsAccountNickname || item.savingsProductName"
              class="fw-medium"
            >{{
              item.savingsAccountNickname || item.savingsProductName }} -</span>
            {{ item.accountNo }}
          </div>
        </template>
      </input-select>

      <input-text
        v-model="formData.nickName"
        :label="$t('label.input.nickname')"
      />

      <div class="d-flex justify-content-center">
        <button
          class="btn btn-default me-3"
          @click="routeBack"
        >
          {{ $t('label.button.cancel') }}
        </button>

        <button
          id="save"
          type="submit"
          class="btn btn-primary"
        >
          {{ $t('label.button.save') }}
        </button>
      </div>
    </form-wrapper>
  </main-content-layout>
</template>

<script setup>
import MainContentLayout from '@/components/shared/main-content-layout/index.vue';
import { FormWrapper, InputSelect, InputText } from '@/components/shared/form-input/index';
import { ref, onMounted } from 'vue';
import apiService from '@/services/apiService';
import router from '@/router/index';
import { $notify } from '@/plugins/prototypes';
import { useBreadcrumbsStore } from '@/stores/breadcrumbs';
import i18n from '@/plugins/i18n';

const breadCrumbsStore = useBreadcrumbsStore();
const { clientId } = router.currentRoute.params;
const formData = ref({});
const primaryAccounts = ref([]);
const entityId = ref(null);

onMounted(async () => {
  try {
    const { data: { parentId, displayName } } = await apiService.clients.get(clientId, { fields: 'parentId,displayName' });
    const { data: primaryAccountsData } = await apiService.clients.getPrimaryAccounts(parentId);

    entityId.value = parentId;
    primaryAccounts.value = primaryAccountsData.filter(account => account.status.code === 'savingsAccountStatusType.active');

    breadCrumbsStore.setBreadcrumbs([
      { text: i18n.t('label.anchor.associatedClients'), href: `#/clients/${parentId}/associated-clients` },
      { text: displayName, href: `#/viewclient/${parentId}/associated-client/${clientId}` },
      { text: i18n.t('label.anchor.createSubAccount') }
    ]);
  } catch (error) {
    $notify.error(error);
  }
});

const onSubmit = async () => {
  try {
    await apiService.manageEmployeeAccounts.createSubAccount(entityId.value, clientId, formData.value);
    routeBack();
  } catch (error) {
    $notify.error(error);
  }
};

const routeBack = () => location.assign(`#/viewclient/${entityId.value}/associated-client/${clientId}`);
</script>
