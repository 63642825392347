export function EditPeriodLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: periodLimitRuleId } = routeParams;
  scope.periodLimitRule = { id: periodLimitRuleId, ruleGroup: { id: ruleId } };
  scope.ruleGroup = {};
  scope.formData = {};

  resourceFactory.paymentTypeResource.getAll(function (data) {
    scope.paymentTypes = [{ id: 0, name: 'ALL' }, ...data.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })];
  }, handleResponseError);

  function loadData () {
    const params = {
      amlRule: { ruleId, fields: 'id,name' },
      periodLimitRule: { ruleId, action: 'periodlimitrule', actionId: periodLimitRuleId }
    };

    resourceFactory.amlRulesResource.get(
      params.amlRule,
      function (resAmlRule) {
        resourceFactory.amlRulesResource.get(
          params.periodLimitRule,
          function (resPeriodLimitRule) {
            delete resPeriodLimitRule.id;
            delete resPeriodLimitRule.ruleGroupId;
            scope.ruleGroup = resAmlRule;
            scope.formData = resPeriodLimitRule;
            scope.formData.legalForm = resPeriodLimitRule.legalForm.toString();

            if (!scope.formData.paymentTypeId) scope.formData.paymentTypeId = 0;
            scope.paymentType = scope.paymentTypes.find(paymentType => paymentType.id === scope.formData.paymentTypeId);
            if (!scope.formData.clientClassificationId) scope.formData.clientClassificationId = 0;
            resourceFactory.codeResources.getAllCodes(function (data) {
              const codes = data;
              const classificaiton = resPeriodLimitRule.legalForm === 1 ? 'ClientClassification-PERSON' : 'ClientClassification-ENTITY';
              const clientClassificationCodeId = codes.find(code => code.name === classificaiton)?.id;
              resourceFactory.codeValueResource.getAllCodeValues({ codeId: clientClassificationCodeId }, function (data) {
                scope.codeValues = [{ id: 0, name: 'ALL' }, ...data.sort((a, b) => {
                  if (a.name < b.name) return -1;
                  if (a.name > b.name) return 1;
                  return 0;
                })];
                scope.clientClassification = scope.codeValues.find(codeValue => codeValue.id === scope.formData.clientClassificationId);
              });
            });
          },
          handleResponseError
        );
      },
      handleResponseError
    );
  }

  loadData();

  scope.onTransactionalRelationshipChange = function () {
    if (scope.formData.filterByTransactionalRelationship === 'SEEN') {
      scope.formData.filterByCounterpartyStatus = 'KNOWN';
    }
  };

  scope.submit = function () {
    if (scope.formData.filterByClient !== 'OTHER') scope.formData.filterByCounterparty = undefined;

    const params = { ruleId, action: 'periodlimitrule', actionId: periodLimitRuleId };
    scope.formData.paymentTypeId = scope.paymentType.id;
    scope.formData.clientClassificationId = scope.clientClassification.id;
    scope.formData.legalForm = Number(scope.formData.legalForm);

    resourceFactory.amlRulesResource.update(
      params, scope.formData,
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/rulegroups/${ruleId}/periodlimitrules/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    scope.formData.legalForm = scope.formData.legalForm.toString();
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditPeriodLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
