export function ClientAuditHistoryController (scope, resourceFactory, location, routeParams) {
  scope.clientId = routeParams.clientId;
  scope.options = ['KYC', 'AUDIT', 'SWITCH_CLASSIFICATION', 'MLA_STATUS', 'NOTIFICATION'];
  scope.auditData = [];
  scope.clientData = [];
  scope.clientNotificationData = [];
  scope.formData = {};
  scope.formData.userType = 1;
  scope.flag = '';
  scope.selfServiceUsersIds = [];

  scope.getClientHistoryTemplate = function () {
    resourceFactory.clientAuditHistoryTemplate.get({ clientId: scope.clientId }, function (data) {
      scope.selfServiceUsersIds = data.selfServiceUsers;
    });
  };

  scope.getAuditHistory = function () {
    if (scope.auditData.length === 0) {
      resourceFactory.auditResource.clientAudit({ clientId: scope.clientId, includeJson: true }, function (data) {
        scope.auditData = data;
      });
    }
  };

  scope.getClientHistory = function (typeValue) {
    resourceFactory.clientAuditHistory.get({ clientId: scope.clientId, type: typeValue }, function (data) {
      scope.clientData = data;
    });
  };

  scope.getClientNotification = function () {
    resourceFactory.clientNotificationHistory.query({ clientId: scope.clientId }, function (data) {
      scope.clientNotificationData = data;
    });
  };

  scope.routeTo = function (id) {
    location.path(`viewaudit/${id}`).search('clientId', 1);
  };

  scope.showValue = function (id) {
    if (scope.formData.userType === 0) {
      return true;
    } else if (scope.formData.userType === 1) {
      return scope.selfServiceUsersIds.indexOf(id) > 0;
    } else {
      return scope.selfServiceUsersIds.indexOf(id) === -1;
    }
  };

  scope.getMlaHistory = function () {
    resourceFactory.clientResource.getMlaStatusHistory({ clientId: scope.clientId, anotherresource: 'mlaStatusHistory' }, function (data) {
      scope.mlaStatusHistory = data;
    });
  };

  scope.search = function () {
    if (scope.formData.id === 'AUDIT') {
      scope.flag = 'audit';
      scope.getAuditHistory();
    } else if (scope.formData.id === 'KYC') {
      scope.flag = 'kyc';
      scope.getClientHistory('kyc');
    } else if (scope.formData.id === 'SWITCH_CLASSIFICATION') {
      scope.flag = 'SWITCH_CLASSIFICATION';
      scope.getClientHistory('SWITCH_CLASSIFICATION');
    } else if (scope.formData.id === 'MLA_STATUS') {
      scope.flag = 'MLA_STATUS';
      scope.getMlaHistory();
    } else if (scope.formData.id === 'NOTIFICATION') {
      scope.flag = 'NOTIFICATION';
      scope.getClientNotification();
    } else {
      scope.flag = '';
    }
  };

  scope.getClientHistoryTemplate();
}

ClientAuditHistoryController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams'];
