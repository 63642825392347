<template>
  <div>
    <div class="d-flex justify-content-end">
      <button
        :disabled="loading"
        class="btn btn-primary mb-2"
        @click="setCardAuthorizations"
      >
        {{ $t(`label.button.view${viewCardAuthorizationBtnText}CardAuthorization`) }}
      </button>
    </div>
    <data-grid
      ref="cardAuthorizationTable"
      :fields="fields"
      :items="cardAuthorizations.pageItems"
      :limit="limit"
      :total-records="cardAuthorizations.totalFilteredRecords"
      clickable
      bordered
      @entries-per-page="updateEntriesPerPage"
      @click-row="routeToCardAuthorization"
      @pagination-update="updatePagination"
    >
      <template #expiryDate="{ item }">
        <span>
          {{ dateStringToFormat(item.expiryDate) }}
        </span>
      </template>
      <template #amount="{ item }">
        <span>
          {{ item.amount }} {{ currencyIsoCodeToLetterCode(item.currency) }}
        </span>
      </template>
      <template #clearedAmount="{ item }">
        <span>
          {{ item.clearedAmount }} {{ currencyIsoCodeToLetterCode(item.currency) }}
        </span>
      </template>
      <template #settledAmount="{ item }">
        <span v-if="item.settledAmount">
          {{ item.settledAmount }} {{ currencyIsoCodeToLetterCode(item.currency) }}
        </span>
      </template>
      <template #merchant="{ item }">
        <span>
          {{ item.merchant.description }}
        </span>
      </template>
      <template #executionMessage="{ item }">
        <span v-if="item.executionCode">
          {{ $t('label.anchor.code') }} {{ item.executionCode }}:
        </span>
        {{ item.executionMessage }}
      </template>
      <template #avsDetails="{ item }">
        <span v-if="item.avsData">
          {{ `${item.avsData?.address || ''}, ${item.avsData?.zip || ''}` }}
        </span>
      </template>
    </data-grid>
  </div>
</template>

<script setup>
import DataGrid from '@/components/shared/data-grid/index.vue';
import apiService from '@/services/apiService';
import i18n from '@/plugins/i18n';
import router from '@/router/index';
import { get, set } from '@vueuse/core';
import { dateStringToFormat } from '@/helper/dateHelper';
import { currencyIsoCodeToLetterCode } from '@/helper/currencyHelper';
import { onMounted, ref, computed, watch } from 'vue';
import { useSharedStore } from '@/stores/shared';

const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const offset = ref(0);
const limit = ref(15);
const cardAuthorizations = ref([]);
const isActiveCardAuthorizations = ref(true);
const viewCardAuthorizationBtnText = computed(() => get(isActiveCardAuthorizations) ? 'Closed' : 'Active');
const routeQuery = router.currentRoute.query;
const cardAuthorizationTable = ref(null);

const props = defineProps({
  cardItem: {
    type: Object,
    default: () => ({})
  }
});
const routeToCardAuthorization = (item) => location.assign(`#/viewcardauthorization/${props.cardItem.token}/authId/${item.id}`);

const fields = [
  { key: 'externalAuthId', label: i18n.t('label.head.authorizationId') },
  { key: 'externalOriginalAuthId', label: i18n.t('label.head.originalAuthId') },
  { key: 'createdAt', label: i18n.t('label.head.createdAt') },
  { key: 'expiryDate', label: i18n.t('label.head.expiryDate') },
  { key: 'authType', label: i18n.t('label.head.authType') },
  { key: 'transactionType', label: i18n.t('label.head.transactionType') },
  { key: 'amount', label: i18n.t('label.head.amount') },
  { key: 'clearedAmount', label: i18n.t('label.head.clearedAmount') },
  { key: 'settledAmount', label: i18n.t('label.head.settledAmount') },
  { key: 'merchant', label: i18n.t('label.head.merchant') },
  { key: 'status', label: i18n.t('label.head.status') },
  { key: 'executionMessage', label: i18n.t('label.head.statusNotes') },
  { key: 'transaction.entryType', label: i18n.t('label.head.transactionStatus') },
  { key: 'avsDetails', label: i18n.t('label.head.avsDetails') }
];

watch([limit, offset, isActiveCardAuthorizations], (newVal) => {
  router.push({ query: { ...routeQuery, limit: newVal[0], offset: newVal[1], isActiveCardAuthorizations: newVal[2] } }).catch(() => {});
}, { deep: true });

onMounted(async () => {
  if (Object.keys(routeQuery).length) {
    if ('isActiveCardAuthorizations' in routeQuery) set(isActiveCardAuthorizations, routeQuery.isActiveCardAuthorizations !== 'false');
    set(offset, Number(routeQuery?.offset) || 0);
    set(limit, Number(routeQuery?.limit) || 15);
  }

  await getCardAuthorizationsByCardResource();
});

const getCardAuthorizationsByCardResource = async () => {
  const cardToken = props.cardItem.token;
  const { data: cardAuthorizationsByCardResource } = await apiService.cardAuthorizationsByCardResource.get(cardToken,
    {
      isActiveCardAuthorizations: get(isActiveCardAuthorizations),
      limit: get(limit),
      offset: get(offset)
    });
  set(cardAuthorizations, cardAuthorizationsByCardResource);
  get(cardAuthorizationTable).selectedEntry = get(limit);
  get(cardAuthorizationTable).currentPage = get(offset) / get(limit) + 1;
};

const updatePagination = (offsetChange) => {
  set(offset, offsetChange);
  getCardAuthorizationsByCardResource();
};

const setCardAuthorizations = () => {
  set(isActiveCardAuthorizations, !get(isActiveCardAuthorizations));
  set(limit, 15);
  set(offset, 0);
  getCardAuthorizationsByCardResource();
};

const updateEntriesPerPage = (limitChange) => {
  set(limit, limitChange);
  set(offset, 0);
  getCardAuthorizationsByCardResource();
};

</script>
