import ClientActions from '@/pages/clients/actions.vue';
import CreateCard from '@/pages/clients/card/create.vue';
import ViewCard from '@/pages/clients/card/view.vue';
import CreateExternalCard from '@/pages/clients/card/external/create.vue';
import EditClientCreditAccount from '@/pages/clients/credit-accounts/edit.vue';
import AddClientAddress from '@/pages/clients/communication-details/address/add.vue';
import EditClientAddress from '@/pages/clients/communication-details/address/edit.vue';
import Clients from '@/pages/clients/index.vue';
import NotificationOfChange from '@/pages/clients/notification-of-change/index';
import ViewManageTier from '@/pages/clients/manage-tiers/view.vue';
import ManagePrepaidCard from '@/pages/clients/prepaid-card';
import CreatePrepaidCard from '@/pages/clients/prepaid-card/create.vue';
import ViewPrepaidCard from '@/pages/clients/prepaid-card/view.vue';
import AssociatedClients from '@/pages/clients/associated-clients/index';
import ModifyTier from '@/pages/clients/manage-tiers/modify.vue';
import AddOrEditTierLimits from '@/pages/clients/manage-tiers/add-or-edit-tier-limits.vue';
import GenerateUploadToken from '@/pages/clients/generate-upload-token.vue';
import ClientsDisablingPaymentRails from '@/pages/clients/disabling-payment-rails.vue';
import CloseCreditAccountPage from '@/pages/clients/credit-accounts/close.vue';
import EditClient from '@/pages/clients/edit.vue';
import CreateDispute from '@/pages/clients/credit-accounts/create-dispute.vue';
import ReversalChargeCreditAccount from '@/pages/clients/credit-accounts/reversal-charge.vue';
import ClientMonitoring from '@/pages/clients/client-monitoring.vue';
import AddOrEditIdentifier from '@/pages/clients/identifiers/add-or-edit.vue';
import AddOrEditPhoneNumber from '@/pages/clients/communication-details/phone-number/add-and-edit.vue';
import UpdateClientKycVerificationStatus from '@/pages/clients/update-client-kyc-verification-status.vue';

export const clientsRoutes = [
  {
    path: '/clients',
    name: 'ClientsPage',
    component: Clients
  },
  {
    path: '/clients/:id/:action(\\w+-action)',
    name: 'ClientActions',
    component: ClientActions
  },
  {
    path: '/clients/:id/edit',
    name: 'EditClient',
    component: EditClient
  },
  {
    path: '/clients/:clientId/card/:id(\\d+)',
    name: 'ClientViewCard',
    component: ViewCard
  },
  {
    path: '/clients/:clientId/card/create',
    name: 'ClientCreateCard',
    component: CreateCard
  },
  {
    path: '/clients/:clientId/external-card/create',
    name: 'ClientCreateExternalCard',
    component: CreateExternalCard
  },
  {
    path: '/clients/credit-account/:accountId/edit',
    name: 'EditClientCreditAccount',
    component: EditClientCreditAccount
  },
  {
    path: '/clients/:clientId/address/add',
    name: 'AddClientAddress',
    component: AddClientAddress
  },
  {
    path: '/clients/:clientId/address/:addrType/:addrId/edit',
    name: 'EditClientAddress',
    component: EditClientAddress
  },
  {
    path: '/clients/:clientId/notification-of-change',
    name: 'NotificationOfChange',
    component: NotificationOfChange
  },
  {
    path: '/clients/:clientId/prepaid-card',
    name: 'ManagePrepaidCard',
    component: ManagePrepaidCard
  },
  {
    path: '/clients/:clientId/prepaid-card/:primaryAccountId/create',
    name: 'CreatePrepaidCard',
    component: CreatePrepaidCard
  },
  {
    path: '/clients/:clientId/prepaid-card/:id',
    name: 'ViewPrepaidCard',
    component: ViewPrepaidCard
  },
  {
    path: '/clients/:clientId/associated-clients',
    name: 'AssociatedClients',
    component: AssociatedClients
  },
  {
    path: '/clients/:clientId/manage-tiers/modify',
    name: 'ModifyTier',
    component: ModifyTier
  },
  {
    path: '/clients/:clientId/manage-tiers/:tierId',
    name: 'ViewManageTier',
    component: ViewManageTier
  },
  {
    path: '/clients/:clientId/manage-tiers/:tierId/edit',
    name: 'EditTierLimits',
    component: AddOrEditTierLimits
  },
  {
    path: '/clients/:clientId/manage-tiers/:tierId/add',
    name: 'AddTierLimits',
    component: AddOrEditTierLimits
  },
  {
    path: '/clients/:clientId/generate-upload-token',
    name: 'GenerateUploadToken',
    component: GenerateUploadToken
  },
  {
    path: '/clients/:clientId/disabling-payment-rails',
    name: 'ClientDisablingPaymentRails',
    component: ClientsDisablingPaymentRails
  },
  {
    path: '/clients/:clientId/credit-accounts/:creditAccountId/close',
    name: 'CloseCreditAccountPage',
    component: CloseCreditAccountPage
  },
  {
    path: '/credit-accounts/:creditAccountId/transactions/:transactionId/reversal-charge',
    name: 'ReversalChargeCreditAccount',
    component: ReversalChargeCreditAccount
  },
  {
    path: '/clients/:clientId/credit-accounts/:creditAccountId/create-dispute',
    name: 'CreateDispute',
    component: CreateDispute
  },
  {
    path: '/client-monitoring',
    name: 'ClientMonitoring',
    component: ClientMonitoring
  },
  {
    path: '/clients/:clientId/identifier/add',
    name: 'AddIdentities',
    component: AddOrEditIdentifier
  },
  {
    path: '/clients/:clientId/identifier/:identifierId/edit',
    name: 'EditIdentities',
    component: AddOrEditIdentifier
  },
  {
    path: '/clients/:clientId/phone-number/add',
    name: 'AddPhoneNumber',
    component: AddOrEditPhoneNumber
  },
  {
    path: '/clients/:clientId/phone-number/:phoneNumberId/edit',
    name: 'EditPhoneNumber',
    component: AddOrEditPhoneNumber
  },
  {
    path: '/clients/:clientId/update-client-kyc-verification-status',
    name: 'UpdateClientKycVerificationStatus',
    component: UpdateClientKycVerificationStatus
  }
];
