import { getRandomString, getClonnedName } from '../../common-methods';

export function CreateCardProductController (scope, http, $rootScope, resourceFactory, location, dateFilter, WizardHandler) {
  const searchParams = location.search();
  if (searchParams.clone) {
    getCardProductById(searchParams.clone);
    resourceFactory.creditProductResource.getAllCreditProducts(function (data) {
      scope.creditProducts = data;
    });
  }

  scope.formData = {
    virtual: false,
    manualPin: false,
    digitalFirst: false,
    active: false,
    currencyCode: 'USD',
    currencyDigitsAfterDecimal: 2,
    currencyInMultiplesOf: 1,
    blockedCountries: [],
    maxActiveCardAllowed: 1,
    businessCardIDEnabled: false,
    legalForm: null
  };
  scope.velocityRulesIds = [];
  scope.limitData = {};
  scope.date = {};
  scope.networks = ['VISA', 'MASTERCARD'];
  scope.cardTypes = ['CREDIT', 'DEBIT'];
  scope.getCardProducts = [];
  scope.countries = $rootScope.regions;
  scope.error = '';
  scope.loading = false;
  scope.creditProducts = [];
  scope.clientLegalFormOptions = [];

  resourceFactory.transferLimitsTemplate.get(function (data) {
    scope.clientLegalFormOptions = data.clientLegalFormOptions;
    scope.formData.legalForm = data.clientLegalFormOptions[0]?.id;
  });

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
  };

  resourceFactory.cardProductsResource.get(data => {
    scope.getCardProducts = data.pageItems;
  });

  resourceFactory.cardProcessorsResource.get((data) => {
    if (!data.length) return;
    scope.cardProcessors = data;
  });

  scope.changeCardType = function () {
    if (scope.formData.cardType === 'CREDIT' && scope.creditProducts.length === 0) {
      scope.formData.businessCardIDEnabled = false;
      resourceFactory.creditProductResource.getAllCreditProducts(function (data) {
        scope.creditProducts = data;
      });
    }
  };

  scope.findCardProcessor = function (id) {
    const processor = scope.cardProcessors && scope.cardProcessors.find(function (cardProcessor) {
      return cardProcessor.id === id;
    });
    return processor && processor.displayName;
  };

  scope.cancel = function () {
    location.path('/cardproducts');
  };

  scope.$watch('formData.cardType', function () {
    if (scope.formData.cardType === 'CREDIT' && scope.creditProducts.length === 0) {
      scope.formData.businessCardIDEnabled = false;
      resourceFactory.creditProductResource.getAllCreditProducts(function (data) {
        scope.creditProducts = data;
      });
    }
  });

  scope.submit = function () {
    const submitData = cardProductDataNormalize();
    scope.error = '';
    // invalid fields
    if (scope.formData.bin.length < 6 || scope.formData.bin.length > 8) {
      scope.error = 'label.error.bin.length';
      return;
    }

    if (scope.formData.cardType === 'CREDIT') {
      delete scope.formData.prepaidCard;
      delete scope.formData.prepaidCardLimitAmount;
      delete scope.formData.prepaidCardTerminationPeriodInDay;
    }

    scope.loading = true;
    resourceFactory.cardProductsResource.save(submitData, () => { location.path('/cardproducts/'); }, ({ data: { errors } }) => {
      scope.loading = false;
      scope.error = `${errors[0].parameterName} ${errors[0].defaultUserMessage}`;
    });
  };

  scope.closeAlert = function () {
    scope.error = '';
  };

  scope.businessCardIdAvailability = () => {
    return scope.formData.cardType !== 'DEBIT' || scope.formData.virtual;
  };

  scope.selectedLegalForm = () => {
    const legalForm = scope.clientLegalFormOptions.find((legalForm) => legalForm.id === scope.formData.legalForm);
    return legalForm?.value;
  };

  function cardProductDataNormalize () {
    const {
      replacementLimit,
      activationUrl, onlinePaymentsEnabled, contactlessPaymentsEnabled, atmWithdrawalsEnabled, internationalPaymentsEnabled, posPaymentsEnabled,
      supportPartialKyc, cardProcessorConfigId, legalForm, prepaidCard, prepaidCardLimitAmount, prepaidCardTerminationPeriodInDay, maxActiveCardAllowed, name, bin, cardType, network, cardProcessorId, externalProductId, virtual, manualPin, blockedCountries, yearExpire, currencyInMultiplesOf, digitalFirst, active, currencyDigitsAfterDecimal, currencyCode, upgradableToProductId, creditProductId, businessCardIDEnabled
    } = scope.formData;
    const controls = Object.keys(scope.limitData);
    let normalizeData = {
      replacementLimit,
      activationUrl,
      onlinePaymentsEnabled,
      contactlessPaymentsEnabled,
      atmWithdrawalsEnabled,
      internationalPaymentsEnabled,
      posPaymentsEnabled,
      supportPartialKyc,
      cardProcessorConfigId,
      legalForm,
      prepaidCard,
      prepaidCardLimitAmount,
      prepaidCardTerminationPeriodInDay,
      maxActiveCardAllowed,
      name,
      bin,
      cardType,
      network,
      cardProcessorId,
      externalProductId,
      virtual,
      manualPin,
      yearExpire,
      currencyInMultiplesOf,
      digitalFirst,
      active,
      currencyDigitsAfterDecimal,
      currencyCode,
      upgradableToProductId: upgradableToProductId?.id,
      creditProductId,
      businessCardIDEnabled
    };

    const velocityRules = controls.map((control) => {
      const timeUnits = Object.keys(scope.limitData[control]);

      return timeUnits.map((timeUnit) => {
        const value = scope.limitData[control][timeUnit];

        return {
          controls: [control.toUpperCase()],
          type: 'AMOUNT',
          timePeriod: 1,
          timeUnit: timeUnit.toUpperCase(),
          value
        };
      });
    }).flat().filter(velocityRule => !!velocityRule?.value && velocityRule?.value > 0);

    if (blockedCountries?.length) {
      normalizeData = { ...normalizeData, blockedCountries };
    }

    if (velocityRules?.length) {
      normalizeData = { ...normalizeData, velocityRules };
    }

    return normalizeData;
  }

  function getCardProductById (cardProductId) {
    resourceFactory.cardProductsResource.get({ cardProductId }, (data) => {
      const vrule = data.velocityRules;
      const velocityData = {
        pos_seconds: '',
        pos_days: '',
        pos_weeks: '',
        pos_months: '',
        atm_seconds: '',
        atm_days: '',
        atm_weeks: '',
        atm_months: ''
      };

      vrule.forEach((rules) => {
        if (rules.controls[0] === 'PURCHASE') {
          const key = rules.timeUnit;
          velocityData['pos_' + key.toLowerCase()] = rules.value;
        } else if (rules.controls[0] === 'WITHDRAWAL') {
          const key = rules.timeUnit;
          velocityData['atm_' + key.toLowerCase()] = rules.value;
        }
      }, this);

      const randomString = getRandomString(8);
      const cardName = getClonnedName(data.name);

      scope.formData = {
        // step 1
        cardProcessorId: data.cardProcessorId,
        cardProcessorConfigId: data.cardProcessorConfigId,

        // step 2
        name: cardName,
        legalForm: data.legalForm,
        externalProductId: randomString,
        bin: data.bin,
        yearExpire: data.yearExpire,
        upgradableToProductId: data.upgradableToProductId,
        network: data.network,
        cardType: data.cardType,
        creditProductId: data.creditProductId,
        blockedCountries: data.blockedCountries,
        virtual: data.virtual,
        digitalFirst: data.digitalFirst,
        fulfillCardOnOrder: data.fulfillCardOnOrder,
        businessCardIDEnabled: data.businessCardIDEnabled,
        manualPin: data.manualPin,
        active: data.active,
        maxActiveCardAllowed: data.maxActiveCardAllowed,
        replacementLimit: data.replacementLimit,
        activationUrl: data.activationUrl,
        prepaidCard: data.prepaidCard,
        prepaidCardLimitAmount: data.prepaidCardLimitAmount,
        prepaidCardTerminationPeriodInDay: data.prepaidCardTerminationPeriodInDay,
        supportPartialKyc: data.supportPartialKyc,
        onlinePaymentsEnabled: data.onlinePaymentsEnabled,
        contactlessPaymentsEnabled: data.contactlessPaymentsEnabled,
        atmWithdrawalsEnabled: data.atmWithdrawalsEnabled,
        internationalPaymentsEnabled: data.internationalPaymentsEnabled,
        posPaymentsEnabled: data.posPaymentsEnabled,

        // step 3
        currencyCode: data.currencyCode,
        currencyDigitsAfterDecimal: data.currencyDigitsAfterDecimal,
        currencyInMultiplesOf: data.currencyInMultiplesOf
      };

      scope.limitData.purchase = {
        // step 4
        seconds: velocityData.pos_seconds,
        days: velocityData.pos_days,
        weeks: velocityData.pos_weeks,
        months: velocityData.pos_months
      };

      scope.limitData.withdrawal = {
        // step 4
        seconds: velocityData.atm_seconds,
        days: velocityData.atm_days,
        weeks: velocityData.atm_weeks,
        months: velocityData.atm_months
      };

      // after assigning select field data, Need to trigger the choosen to show
      setTimeout(() => { $('#blockcountry').trigger('chosen:updated'); }, 1);
      angular.module('backoffice', ['angular.chosen']);
    });
  }
}

CreateCardProductController.$inject = ['$scope', '$http', '$rootScope', 'ResourceFactory', '$location', 'dateFilter', 'WizardHandler'];
