import { hasPassedDate } from '@/helper/dateHelper';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { useApplicationStore } from '@/stores/application';

export async function ViewSavingDetailsController (scope, routeParams, resourceFactory, paginatorService, location, $uibModal, route, dateFilter, $sce, $rootScope) {
  const applicationStore = useApplicationStore();

  scope.report = false;
  scope.hidePentahoReport = true;
  scope.showActiveCharges = true;
  scope.formData = {};
  scope.date = {};
  scope.staffData = {};
  scope.fieldOfficers = [];
  scope.savingaccountdetails = [];
  scope.transactionsPerPage = 15;
  scope.holdFunds = [];
  scope.holdFunsPerPage = 15;
  scope.totalHoldFunds = 0;
  scope.totalTransactions = 0;
  scope.savingsAccountTransactions = [];
  scope.savingsAccountPendingTransactions = [];
  scope.totalPendingTransactions = '';
  scope.accountBlockButtonText = '';
  scope.blockPermission = '';
  scope.savingsAccountNickname = '';
  scope.stopPayments = [];
  scope.defaultDecimalPlace = 2;
  scope.showInterestAccruals = false;
  scope.floatingRates = [];
  scope.floatingRatesPerPage = 15;
  scope.totalFloatingRates = 0;
  scope.errorDetails = [];
  scope.buttons = [];
  scope.fundAllocateTrasnasctions = [];
  scope.hasClosedAsSchedule = false;
  scope.daysBeforeClosing = 0;
  scope.transactionTransferTypes = [
    { label: 'Credit', value: 'CREDIT' }, { label: 'Debit', value: 'DEBIT' }
  ];
  scope.isVueReady = false;

  // scope.totalFixedTabs = [
  //   { name: 'transactions' },
  //   { name: 'pendingTransactions' },
  //   { name: 'fundAllocations' },
  //   { name: 'standingInstruction' },
  //   { name: 'charges' },
  //   { name: 'notes' },
  //   { name: 'stopPayments' },
  //   { name: 'floatingInterestRate' },
  //   { name: 'holdFunds' },
  //   { name: 'averageMonthlyAccountBalance' }
  // ];

  // scope.$watch(() => routeParams.tab, function (newVal) {
  //   scope.activeTab = newVal || 'transactions';
  // });

  resourceFactory.savingsAccounts.get({ accountId: routeParams.id }, function (data) {
    scope.savingsAccountNickname = data.savingsAccountNickname;
    if (data.accountSubType && data.accountSubType === 'SUBSIDIARY_ACCOUNT') {
      if (data.parentAccount?.id) {
        resourceFactory.savingsAccounts.get({ accountId: data.parentAccount.id }, function (parentData) {
          location.path(`clients/${parentData.clientId}/associated-client/${data.clientId}/saving-accounts/${data.id}`).replace();
        });
      }
    }
  });

  scope.isDebit = function (savingsTransactionType) {
    return savingsTransactionType.isDebit === true;
  };

  scope.routeTo = function (savingsAccountId, transactionId, accountTransfer, transferId) {
    if (accountTransfer) {
      location.path(`/viewaccounttransfers/${transferId}`);
    } else {
      location.path(`/viewsavingtrxn/${savingsAccountId}/trxnId/${transactionId}`);
    }
  };

  scope.notClickableTypes = ['FUNDS_ALLOCATION', 'FUNDS_DEALLOCATION'];
  scope.routeToTransactions = function (transaction) {
    const transactionTypes = ['TRANSFER', 'CARD_AUTHORIZATION', 'SAVINGS_AUTHORIZATION'];

    if (!scope.notClickableTypes.includes(transaction.type) && transactionTypes.includes(transaction.type)) {
      let tempLocation = `/transfers/${transaction.transferId}`;

      if (transaction.cardAuthorization) {
        tempLocation = `/viewcardauthorization/${transaction.cardAuthorization.account.cardToken}/authId/${transaction.cardAuthorization.id}`;
      } else if (transaction.typeOf === 'HOLD_AMOUNT' && transaction.type === 'SAVINGS_AUTHORIZATION') {
        tempLocation = `/savingaccounts/${routeParams.id}/holdamount/${transaction.savingsAccountAuthorization.externalAuthId}`;
      }

      location.path(tempLocation);
    }
  };
  /** *
             * we are using orderBy(https://docs.angularjs.org/api/ng/filter/orderBy) filter to sort fields in ui
             * api returns dates in array format[yyyy, mm, dd], converting the array of dates to date object
             * @param dateFieldName
             */
  scope.convertDateArrayToObject = function (dateFieldName) {
    for (const i in scope.savingaccountdetails.transactions) {
      scope.savingaccountdetails.transactions[i][dateFieldName] = scope.savingaccountdetails.transactions[i].date;
    }
  };
  scope.isRecurringCharge = function (charge) {
    return charge.chargeTimeType.value === 'Monthly Fee' || charge.chargeTimeType.value === 'Annual Fee' || charge.chargeTimeType.value === 'Weekly Fee';
  };

  scope.viewCharge = function (id) {
    location.path(`/savings/${scope.savingaccountdetails.id}/viewcharge/${id}`).search({ status: scope.savingaccountdetails.status.value });
  };

  scope.undoCharge = function (accountId, transactionId) {
    location.path(`/savingaccount/${accountId}/undoCharge/transactions/${transactionId}`);
  };

  scope.chargeBack = function (accountId, transactionId) {
    location.path(`/savingaccount/${accountId}/chargeBack/transactions/${transactionId}`);
  };

  scope.clickEvent = function (eventName = '', accountId) {
    const changes = {
      withHoldTax: true
    };
    switch (eventName) {
      case 'modifyapplication':
        location.path(`/editsavingaccount/${accountId}`);
        break;
      case 'approve':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/approve`);
        break;
      case 'reject':
        location.path(`/savingaccount/${accountId}/reject`);
        break;
      case 'withdrawnbyclient':
        location.path(`/savingaccount/${accountId}/withdrawnByApplicant`);
        break;
      case 'delete':
        resourceFactory.savingsResource.delete({ accountId }, {}, function (data) {
          let destination = `/viewgroup/${data.groupId}`;
          if (data.clientId) destination = `/viewclient/${data.clientId}`;
          location.path(destination);
        });
        break;
      case 'undoapproval':
        location.path(`/savingaccount/${accountId}/undoapproval`);
        break;
      case 'activate':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/activate`);
        break;
      case 'deposit':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/deposit`);
        break;
      case 'withdraw':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/withdrawal`);
        break;
      case 'addcharge':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/add-charge`);
        break;
      case 'blockAmount':
        location.path(`/savingaccounts/${accountId}/holdamount`);
        break;
      case 'calculateInterest':
        resourceFactory.savingsResource.save({ accountId, command: 'calculateInterest' }, {}, function () {
          route.reload();
        });
        break;
      case 'postInterest':
        resourceFactory.savingsResource.save({ accountId, command: 'postInterest' }, {}, function () {
          route.reload();
        });
        break;
      case 'blockAccount':
        $uibModal.open({
          templateUrl: 'block.html',
          controller ($scope, $uibModalInstance) {
            $scope.reason = '';
            $scope.confirm = function () {
              if (!$scope.reason) return;
              resourceFactory.savingsResource.save({ accountId, command: 'block' }, { reason: $scope.reason }, function () {
                $scope.cancel();
                route.reload();
              }, (error) => scope.errorDetails.push(error.data));
            };
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }
        });
        break;
      case 'unBlockAccount':
        $uibModal.open({
          templateUrl: 'unblock.html',
          controller ($scope, $uibModalInstance) {
            $scope.reason = '';
            $scope.confirm = function () {
              if (!$scope.reason) return;
              resourceFactory.savingsResource.save({ accountId, command: 'unblock' }, { reason: $scope.reason }, function () {
                $scope.cancel();
                route.reload();
              }, (error) => scope.errorDetails.push(error.data));
            };
            $scope.cancel = function () {
              $uibModalInstance.dismiss('cancel');
            };
          }
        });
        break;
      case 'applyAnnualFees':
        location.path(`/savingaccountcharge/${accountId}/applyAnnualFees/${scope.annualChargeId}`);
        break;
      case 'statementsAndDocuments':
        location.path(`/viewsavingaccount/statementsAndDocuments/${accountId}`);
        break;
      case 'internaltransfer':
        if (scope.savingaccountdetails.clientId) {
          location.path(`/accounttransfers/fromsavings/${accountId}/internal`);
        }
        break;
      case 'outgoingExternalTransfer':
      case 'outgoingExternalTransferAch':
        if (scope.savingaccountdetails.clientId) {
          location.path(`/accounttransfers/fromsavings/${accountId}/external/outgoing`);
        }
        break;
      case 'incomingExternalTransfer':
      case 'incomingExternalTransferAch':
        if (scope.savingaccountdetails.clientId) {
          location.path(`/accounttransfers/fromsavings/${accountId}/external/incoming`);
        }
        break;
      case 'incomingWireTransfer':
        if (scope.savingaccountdetails.clientId) {
          location.path(`/accounttransfers/fromsavings/${accountId}/client/${scope.savingaccountdetails.clientId}/incomingWireTransfer`);
        }
        break;
      case 'createExternalWire':
        if (scope.savingaccountdetails.clientId) {
          location.path(`/accounttransfers/fromsavings/${accountId}/client/${scope.savingaccountdetails.clientId}/createExternalWireTransfer`);
        }
        break;
      case 'transferOptions':
        location.path(`/viewclient/${accountId}/transfer-options`);
        break;
      case 'close':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/close`);
        break;
      case 'closeAsScheduled':
        router.push({ name: 'SavingAccountsActions', params: { clientId: scope.savingaccountdetails.clientId, id: accountId, action: 'schedule-close' } });
        break;
      case 'assignSavingsOfficer':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/assign-staff`);
        break;
      case 'unAssignSavingsOfficer':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/unassign-staff`);
        break;
      case 'enableWithHoldTax':
        changes.withHoldTax = true;
        resourceFactory.savingsResource.update({ accountId, command: 'updateWithHoldTax' }, changes, function () {
          route.reload();
        });
        break;
      case 'disableWithHoldTax':
        changes.withHoldTax = false;
        resourceFactory.savingsResource.update({ accountId, command: 'updateWithHoldTax' }, changes, function () {
          route.reload();
        });
        break;
      case 'postInterestAsOn':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/post-interest-on`);
        break;
      case 'editAccountNumber':
        location.path(`clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/edit-account-number`);
        break;
      case 'editAccountNickname':
        location.path(`clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/edit-nickname`);
        break;
      case 'enableCredit':
        resourceFactory.savingsResource.save({ accountId, command: 'unblockCredit' }, {}, function () {
          route.reload();
        });
        break;
      case 'disableDebit':
        resourceFactory.savingsResource.save({ accountId, command: 'blockDebit' }, {}, function () {
          route.reload();
        });
        break;
      case 'disableCredit':
        resourceFactory.savingsResource.save({ accountId, command: 'blockCredit' }, {}, function () {
          route.reload();
        });
        break;
      case 'enableDebit':
        resourceFactory.savingsResource.save({ accountId, command: 'unblockDebit' }, {}, function () {
          route.reload();
        });
        break;
      case 'blockCardOrder':
        resourceFactory.savingsResource.save({ accountId, command: 'blockcard' }, {}, function () {
          route.reload();
        });
        break;
      case 'unblockCardOrder':
        resourceFactory.savingsResource.save({ accountId, command: 'unblockcard' }, {}, function () {
          route.reload();
        });
        break;
      case 'createSubledger':
        location.path(`/clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/subledger/create`);
        break;
      case 'editoverdraftlimit':
        location.path(`clients/${scope.savingaccountdetails.clientId}/saving-accounts/${accountId}/edit-overdraft-limit`);
        break;
    }
  };
  scope.filterData = {
    startDate: '',
    endDate: '',
    transactionType: '',
    transactionSubType: '',
    reference: ''
  };
  scope.transactionTypes = [];
  scope.subTransactionTypes = [];

  try {
    const { data: { transactionType, transactionSubType } } = await apiService.savingAccounts.transactionsTemplate(routeParams.id);
    scope.transactionTypes = transactionType;
    scope.subTransactionTypes = transactionSubType;
    scope.isVueReady = true;
  } catch (e) {
    console.warn(e);
  }

  scope.getResultsPage = function (pageNumber) {
    this.formData.locale = scope.optlang.code;
    const dateFormat = 'yyyy-MM-dd';
    scope.filterData.startDate = dateFilter(scope.filterData.startDate, dateFormat);
    scope.filterData.endDate = dateFilter(scope.filterData.endDate, dateFormat);

    resourceFactory.savingsTrxnsResource.getAllTransactions({
      savingsId: routeParams.id,
      offset: ((pageNumber - 1) * scope.transactionsPerPage),
      limit: scope.transactionsPerPage,
      subTransactionType: scope.filterData.transactionSubType,
      transactionType: scope.filterData.transactionType,
      startDate: scope.filterData.startDate,
      endDate: scope.filterData.endDate,
      reference: scope.filterData.reference,
      showInterestAccruals: scope.showInterestAccruals,
      getCardData: true
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.savingsAccountTransactions = data.pageItems;
    });
  };

  scope.searchTransactions = function () {
    this.formData.locale = scope.optlang.code;
    const dateFormat = 'yyyy-MM-dd';
    scope.filterData.startDate = dateFilter(scope.filterData.startDate, dateFormat);
    scope.filterData.endDate = dateFilter(scope.filterData.endDate, dateFormat);
    const tempOffset = 0;

    resourceFactory.savingsTrxnsResource.getAllTransactions({
      savingsId: routeParams.id,
      offset: tempOffset,
      limit: scope.transactionsPerPage,
      subTransactionType: scope.filterData.transactionSubType,
      transactionType: scope.filterData.transactionType,
      startDate: scope.filterData.startDate,
      endDate: scope.filterData.endDate,
      reference: scope.filterData.reference,
      showInterestAccruals: scope.showInterestAccruals,
      getCardData: true
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.savingsAccountTransactions = data.pageItems;
    });
  };

  scope.clearSearchTransactions = function () {
    scope.filterData = {
      startDate: '',
      endDate: '',
      transactionType: '',
      transactionSubType: '',
      reference: ''
    };
    scope.searchTransactions();
  };

  scope.routeToReleaseAmount = function (accountId, authorizationId) {
    location.path(`/savingaccounts/${accountId}/releaseamount/${authorizationId}`);
  };

  scope.routeToSettleAmount = function (accountId, authorizationId) {
    location.path(`/savingaccounts/${accountId}/settleamount/${authorizationId}`);
  };

  scope.initPage = function () {
    resourceFactory.savingsTrxnsResource.getAllTransactions({
      savingsId: routeParams.id,
      offset: 0,
      limit: scope.transactionsPerPage,
      showInterestAccruals: scope.showInterestAccruals,
      getCardData: true
    }, function (data) {
      scope.totalTransactions = data.totalFilteredRecords;
      scope.savingsAccountTransactions = data.pageItems;
    });
  };

  scope.handleShowInterestAccrualsChange = function (event) {
    const { checked } = event.target;
    if (checked) {
      scope.showInterestAccruals = true;
    } else if (!checked) {
      scope.showInterestAccruals = false;
    }
    scope.searchTransactions();
  };

  resourceFactory.savingsResource.get({ accountId: routeParams.id, associations: 'charges' }, function (data) {
    scope.savingaccountdetails = data;
    const { scheduledCloseDate } = scope.savingaccountdetails;
    scope.hasClosedAsSchedule = hasPassedDate(scheduledCloseDate).hasPassed;
    scope.daysBeforeClosing = hasPassedDate(scheduledCloseDate).diff;
    scope.formatDecimalPlace = data.currency.decimalPlaces;
    if (data.subStatus.id === 400) {
      scope.accountBlockButtonText = 'button.unBlockAccount';
      scope.blockPermission = 'UNBLOCK_SAVINGSACCOUNT';
    } else {
      scope.accountBlockButtonText = 'button.blockAccount';
      scope.blockPermission = 'BLOCK_SAVINGSACCOUNT';
    }
    scope.initPage();

    scope.convertDateArrayToObject('date');
    if (scope.savingaccountdetails.groupId) {
      resourceFactory.groupResource.get({ groupId: scope.savingaccountdetails.groupId }, function (data) {
        scope.groupLevel = data.groupLevel;
      });
    }

    scope.staffData.staffId = data.staffId;
    scope.date.toDate = new Date();
    scope.date.fromDate = new Date(data.timeline.activatedOnDate);

    scope.isPrimaryAccount = data.accountSubType === 'PRIMARY_ACCOUNT';
    scope.status = data.status.value;
    if (scope.status === 'Submitted and pending approval' || scope.status === 'Active' || scope.status === 'Approved') {
      scope.choice = true;
    }
    scope.chargeAction = data.status.value === 'Submitted and pending approval';
    scope.chargePayAction = data.status.value === 'Active';
    if (scope.savingaccountdetails.charges) {
      scope.charges = scope.savingaccountdetails.charges;
      if (data.charges) {
        for (const i in scope.charges) {
          if (scope.charges[i].name === 'Annual fee - INR') {
            scope.buttons.options.push({
              name: 'button.applyAnnualFees',
              taskPermissionName: 'APPLYANNUALFEE_SAVINGSACCOUNT'
            });
            scope.annualChargeId = scope.charges[i].id;
          }
          // covert object date into correct format
          const day = scope.charges[i].feeOnMonthDay?.day;
          const affix = findDateAffix(day);
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Monthly Fee') {
            scope.charges[i].feeOnMonthDay = day + affix;
          }
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Annual Fee') {
            scope.charges[i].feeOnMonthDay = `${day + affix} ${dateFilter(new Date(Object.values(scope.charges[i].feeOnMonthDay)), 'MMMM')}`;
          }
          if (day !== undefined && scope.charges[i].chargeTimeType.value === 'Weekly Fee') {
            scope.charges[i].feeOnMonthDay = '';
          }
        }
      }
      scope.chargeTableShow = true;
    } else {
      scope.chargeTableShow = false;
    }
    if (data.status.value === 'Submitted and pending approval') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.modifyapplication',
            icon: 'fas fa-pencil-alt ',
            taskPermissionName: 'UPDATE_SAVINGSACCOUNT'
          },
          {
            name: 'button.approve',
            icon: 'fas fa-check',
            taskPermissionName: 'APPROVE_SAVINGSACCOUNT'
          }
        ],
        options: [
          {
            name: 'button.reject',
            taskPermissionName: 'REJECT_SAVINGSACCOUNT'
          },
          {
            name: 'button.withdrawnbyclient',
            taskPermissionName: 'WITHDRAW_SAVINGSACCOUNT'
          },
          {
            name: 'button.addcharge',
            taskPermissionName: 'CREATE_SAVINGSACCOUNTCHARGE'
          },
          {
            name: 'button.delete',
            taskPermissionName: 'DELETE_SAVINGSACCOUNT'
          }
        ]
      };
    }

    if (data.status.value === 'Approved') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.undoapproval',
            icon: 'fas fa-undo',
            taskPermissionName: 'APPROVALUNDO_SAVINGSACCOUNT'
          },
          {
            name: 'button.activate',
            icon: 'fas fa-check',
            taskPermissionName: 'ACTIVATE_SAVINGSACCOUNT'
          },
          {
            name: 'button.addcharge',
            icon: 'fas fa-plus',
            taskPermissionName: 'CREATE_SAVINGSACCOUNTCHARGE'
          }
        ]
      };
    }

    if (data.status.value === 'Active') {
      scope.buttons = {
        singlebuttons: [
          {
            name: 'button.postInterestAsOn',
            icon: 'icon-arrow-right',
            taskPermissionName: 'POSTINTEREST_SAVINGSACCOUNT'
          },
          {
            name: 'button.deposit',
            icon: 'fas fa-arrow-up',
            taskPermissionName: 'DEPOSIT_SAVINGSACCOUNT'
          },
          {
            name: 'button.withdraw',
            icon: 'fas fa-arrow-down',
            taskPermissionName: 'WITHDRAWAL_SAVINGSACCOUNT'
          },
          {
            name: 'button.calculateInterest',
            icon: 'fas fa-table',
            taskPermissionName: 'CALCULATEINTEREST_SAVINGSACCOUNT'
          }
        ],
        options: [
          {
            name: 'button.postInterest',
            taskPermissionName: 'POSTINTEREST_SAVINGSACCOUNT'
          },
          {
            name: 'button.addcharge',
            taskPermissionName: 'CREATE_SAVINGSACCOUNTCHARGE'
          },
          {
            name: 'button.statementsAndDocuments',
            taskPermissionName: 'READ_MEDIA'
          }
        ]

      };

      scope.buttons.options?.push(
        {
          name: 'button.editAccountNumber',
          taskPermissionName: 'EDIT_ACCOUNTNUMBER'
        }
      );

      scope.buttons.options?.push({
        name: 'button.editAccountNickname',
        taskPermissionName: 'UPDATE_NICKNAME_SAVINGSACCOUNT'
      });

      if (data.allowOverdraft) {
        scope.buttons.options.push({
          name: 'button.editoverdraftlimit'
        });
      }
      if (data.clientId) {
        // scope.buttons.singlebuttons.push({
        //     name: "button.transfer",
        //     icon: "fas fa-exchange-alt",
        //     taskPermissionName: "CREATE_ACCOUNTTRANSFER"
        // });
        scope.buttons.transfer = [
          {
            name: 'button.internaltransfer',
            taskPermissionName: 'CREATE_TRANSFER'
          }
        ];

        if (process.env.VUE_APP_SUBLEDGER === 'evolve') {
          scope.buttons.transfer.push(
            {
              name: 'button.outgoingExternalTransfer',
              taskPermissionName: 'CREATE_TRANSFER'
            },
            {
              name: 'button.incomingExternalTransfer',
              taskPermissionName: 'CREATE_TRANSFER'
            }
          );
        } else {
          scope.buttons.transfer.push(
            {
              name: 'button.outgoingExternalTransferAch',
              textColor: 'text-danger',
              taskPermissionName: 'CREATE_TRANSFER'
            },
            {
              name: 'button.createExternalWire',
              textColor: 'text-danger',
              taskPermissionName: 'CREATE_TRANSFER'
            },
            {
              name: 'button.incomingExternalTransferAch',
              textColor: 'text-success',
              taskPermissionName: 'CREATE_TRANSFER'
            },
            {
              name: 'button.incomingWireTransfer',
              textColor: 'text-success',
              taskPermissionName: 'CREATE_TRANSFER'
            }

          );
        }

        scope.buttons.options.push({
          name: 'button.transferOptions',
          taskPermissionName: ''
        });
      }

      if (data.taxGroup) {
        if (data.withHoldTax) {
          scope.buttons.options.push({
            name: 'button.disableWithHoldTax',
            taskPermissionName: 'UPDATEWITHHOLDTAX_SAVINGSACCOUNT'
          });
        } else {
          scope.buttons.options.push({
            name: 'button.enableWithHoldTax',
            taskPermissionName: 'UPDATEWITHHOLDTAX_SAVINGSACCOUNT'
          });
        }
      }

      if (data.subStatus.id === 500) {
        scope.buttons.options.push(
          {
            name: 'button.enableCredit',
            taskPermissionName: 'UNBLOCKCREDIT_SAVINGSACCOUNT'
          }
        );
        if (!data.subStatus.blockDebit) {
          scope.buttons.options.push(
            {
              name: 'button.disableDebit',
              taskPermissionName: 'BLOCKDEBIT_SAVINGSACCOUNT'
            }
          );
        }
      }

      if (data.subStatus.id === 600) {
        if (!data.subStatus.blockCredit) {
          scope.buttons.options.push(
            {
              name: 'button.disableCredit',
              taskPermissionName: 'BLOCKCREDIT_SAVINGSACCOUNT'
            }
          );
        }
        scope.buttons.options.push(
          {
            name: 'button.enableDebit',
            taskPermissionName: 'UNBLOCKDEBIT_SAVINGSACCOUNT'
          }
        );
      }

      if (data.subStatus.id === 0) {
        scope.buttons.options.push(
          {
            name: 'button.disableCredit',
            taskPermissionName: 'BLOCKCREDIT_SAVINGSACCOUNT'
          }
        );
        scope.buttons.options.push(
          {
            name: 'button.disableDebit',
            taskPermissionName: 'BLOCKDEBIT_SAVINGSACCOUNT'
          }
        );
      }

      if (data.cardRestricted) {
        scope.buttons.options.push(
          {
            name: 'button.unblockCardOrder',
            taskPermissionName: 'UNBLOCK_ORDER_CARD_SAVINGSACCOUNT'
          }
        );
      } else {
        scope.buttons.options.push(
          {
            name: 'button.blockCardOrder',
            taskPermissionName: 'BLOCK_ORDER_CARD_SAVINGSACCOUNT'
          }
        );
      }
    }

    if (data.status.value === 'Closed') {
      scope.buttons = {
        options: [
          {
            name: 'button.statementsAndDocuments',
            taskPermissionName: 'READ_MEDIA'
          }
        ]
      };
    }

    if (data.annualFee && data.annualFee.feeOnMonthDay) {
      let annualdueDate = [];
      annualdueDate = Object.values(data.annualFee.feeOnMonthDay);
      annualdueDate.push(new Date().getFullYear());
      scope.annualdueDate = new Date(annualdueDate);
    }

    if (data.status.value !== 'Closed') {
      scope.buttons.options?.push(
        {
          name: scope.accountBlockButtonText,
          taskPermissionName: scope.blockPermission
        },
        {
          name: 'button.blockAmount',
          taskPermissionName: 'HOLDAMOUNT_SAVINGSACCOUNT'
        }
      );

      if (applicationStore.isOpenBankingEnabled) {
        scope.buttons.options?.push(
          {
            name: 'button.createSubledger',
            taskPermissionName: ''
          }
        );
      }

      // add close account for last
      scope.buttons.options?.push(
        {
          name: 'button.close',
          taskPermissionName: 'CLOSE_SAVINGSACCOUNT'
        }
      );

      scope.buttons.options?.push(
        {
          name: 'button.closeAsScheduled',
          taskPermissionName: 'SCHEDULECLOSE_SAVINGSACCOUNT'
        }
      );
    }

    resourceFactory.standingInstructionTemplateResource.get({ fromClientId: scope.savingaccountdetails.clientId, fromAccountType: 2, fromAccountId: routeParams.id }, function (response) {
      scope.standinginstruction = response;
      scope.searchTransaction();
    });

    scope.getPendingTransactions();
    scope.getHoldFunds();

    if (scope.isPrimaryAccount) {
      scope.getFundAllocations();
    }
  });

  scope.getPendingTransactions = function (pageNumber = 1) {
    resourceFactory.getPendingTransactions.get({
      savingsId: routeParams.id,
      offset: ((pageNumber - 1) * scope.transactionsPerPage),
      limit: scope.transactionsPerPage
    }, function (response) {
      scope.savingsAccountPendingTransactions = response.pageItems?.map(item => ({
        // In case of pending transaction type is SAVINGS_AUTHORIZATION,
        // some of key pairs bellow is not available in response.
        // ... item is used to spread data from response directly
        // to get the values needed to display in detail screen.
        ...item,
        transfer: item?.transfer,
        cardAuthorization: item?.cardAuthorization,
        savingsAccountAuthorization: item?.savingsAccountAuthorization,
        transferId: item.transfer?.id || item.cardAuthorization?.id,
        cardAuthorizationsId: item.cardAuthorization?.externalAuthId,
        createdAt: item.createdAt,
        subAccountNumber: item?.subsidiaryAccount?.accountNumber,
        subAccountName: item?.subsidiaryAccount?.subAccountName,
        subAccountId: item?.subsidiaryAccount?.id,
        amount: item.amount,
        pendingAmount: item.pendingAmount,
        description: item.transfer?.reference?.join('\n') || item.cardAuthorization?.merchant?.description || item.reference,
        type: item.type
      }));
      scope.totalPendingTransactions = response.totalFilteredRecords;
    });
  };

  scope.getFundAllocations = () => {
    resourceFactory.getFundAllocations.get({
      savingsId: routeParams.id,
      includeInactive: true
    }, function (response) {
      scope.fundAllocateTrasnasctions = response.pageItems;
    });
  };

  scope.getHoldFunds = function (pageNumber = 1) {
    resourceFactory.savingsAmountResource.get({
      savingsAccountId: routeParams.id,
      offset: ((pageNumber - 1) * scope.holdFunsPerPage),
      limit: scope.holdFunsPerPage
    }, function (response) {
      scope.holdFunds = response.pageItems?.map(holdFund => ({
        id: holdFund.id,
        holdAuthorizeTransactionId: holdFund.holdAuthorizeTransactionId,
        reference: holdFund.externalAuthId,
        amount: holdFund.amount,
        status: holdFund.status,
        releasedAt: holdFund.releasedAt,
        expiryDate: holdFund.expiryDate,
        createdAt: holdFund.createdAt,
        clearedAmount: holdFund.clearedAmount,
        pendingAmount: holdFund.pendingAmount
      }));
      scope.totalHoldFunds = response.totalFilteredRecords;
    });
  };

  const findDateAffix = function (day) {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  const fetchFunction = function (offset, limit, callback) {
    const params = {};
    params.offset = offset;
    params.limit = limit;
    params.locale = scope.optlang.code;
    params.fromAccountId = routeParams.id;
    params.fromAccountType = 2;
    params.clientId = scope.savingaccountdetails.clientId;
    params.clientName = scope.savingaccountdetails.clientName;
    params.dateFormat = scope.df;

    resourceFactory.standingInstructionResource.search(params, callback);
  };

  scope.searchTransaction = function () {
    scope.displayResults = true;
    scope.instructions = paginatorService.paginate(fetchFunction, 14);
    scope.isCollapsed = false;
  };

  resourceFactory.DataTablesResource.getAllDataTables({ apptable: 'm_savings_account' }, function (data) {
    scope.savingdatatables = data;
  });

  scope.saveNote = function () {
    resourceFactory.noteResource.save({ resourceType: 'savings', resourceId: routeParams.id }, { note: scope.formData.note }, function () {
      scope.getNotesData();
      scope.formData.note = undefined;
    });
  };

  const EditNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.editData = {};
    $scope.editData.editNote = items.sNote;
    $scope.editNote = function () {
      resourceFactory.noteResource.put({ resourceType: 'savings', resourceId: routeParams.id, noteId: items.sId }, { note: this.editData.editNote }, function () {
        scope.savingAccountNotes[items.index].note = $scope.editData.editNote;
        $uibModalInstance.close();
      }, function (error) {
        $rootScope.errorDetails = error.data.errors;
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.showEditNote = function (index) {
    const sId = scope.savingAccountNotes[index].id;
    const sNote = scope.savingAccountNotes[index].note;
    $uibModal.open({
      templateUrl: 'editNote.html',
      controller: EditNoteCtrl,
      resolve: {
        items () {
          return {
            sId,
            sNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  scope.showDeleteNote = function (index) {
    const sId = scope.savingAccountNotes[index].id;
    const sNote = scope.savingAccountNotes[index].note;
    $uibModal.open({
      templateUrl: 'deleteNote.html',
      controller: DeleteNoteCtrl,
      resolve: {
        items () {
          return {
            sId,
            sNote,
            index
          };
        }
      },
      size: 'lg'
    });
  };

  const DeleteNoteCtrl = function ($scope, $uibModalInstance, items) {
    $scope.note = items.sNote;
    $scope.deleteNote = function () {
      resourceFactory.noteResource.delete({ resourceType: 'savings', resourceId: routeParams.id, noteId: items.sId }, '', function () {
        $uibModalInstance.close();
        scope.savingAccountNotes.splice(items.index, 1);
      }, function (error) {
        $rootScope.errorDetails = error.data.errors;
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.dataTableChange = function (datatable) {
    resourceFactory.DataTablesResource.getTableDetails({
      datatablename: datatable.registeredTableName,
      entityId: routeParams.id,
      genericResultSet: 'true'
    }, function (data) {
      scope.datatabledetails = data;
      scope.datatabledetails.isData = data.data.length > 0;
      scope.datatabledetails.isMultirow = data.columnHeaders[0].columnName === 'id';
      scope.showDataTableAddButton = !scope.datatabledetails.isData || scope.datatabledetails.isMultirow;
      scope.showDataTableEditButton = scope.datatabledetails.isData && !scope.datatabledetails.isMultirow;
      scope.singleRow = [];
      for (const i in data.columnHeaders) {
        if (scope.datatabledetails.columnHeaders[i].columnCode) {
          for (const j in scope.datatabledetails.columnHeaders[i].columnValues) {
            for (const k in data.data) {
              if (data.data[k].row[i] === scope.datatabledetails.columnHeaders[i].columnValues[j].id) {
                data.data[k].row[i] = scope.datatabledetails.columnHeaders[i].columnValues[j].value;
              }
            }
          }
        }
      }
      if (scope.datatabledetails.isData) {
        for (const i in data.columnHeaders) {
          if (!scope.datatabledetails.isMultirow) {
            const row = {};
            row.key = data.columnHeaders[i].columnName;
            row.value = data.data[0].row[i];
            scope.singleRow.push(row);
          }
        }
      }
    });
  };

  scope.viewJournalEntries = function () {
    location.path('/accounting/search-journal-entries').search({ savingsId: scope.savingaccountdetails.id });
  };

  scope.viewDataTable = function (registeredTableName, data) {
    if (scope.datatabledetails.isMultirow) {
      location.path(`/viewdatatableentry/${registeredTableName}/${scope.savingaccountdetails.id}/${data.row[0]}`);
    } else {
      location.path(`/viewsingledatatableentry/${registeredTableName}/${scope.savingaccountdetails.id}`);
    }
  };

  scope.viewSavingDetails = function () {
    scope.report = false;
    scope.hidePentahoReport = true;
    scope.viewReport = false;
  };

  scope.viewPrintDetails = function () {
    // scope.printbtn = true;
    scope.report = true;
    scope.viewTransactionReport = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');

    const reportParams = {
      'output-type': 'PDF',
      locale: scope.optlang.code,
      R_startDate: scope.startDate,
      R_endDate: scope.endDate,
      R_savingsAccountId: scope.savingaccountdetails.accountNo
    };

    resourceFactory.clientSavingTransactionsReportResource.get(reportParams, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // allow untrusted urls for iframe http://docs.angularjs.org/error/$sce/insecurl
      scope.viewReportDetails = $sce.trustAsResourceUrl(fileURL);
    });
  };

  scope.exportToCsv = function () {
    scope.startDate = dateFilter(scope.date.fromDate, 'yyyy-MM-dd');
    scope.endDate = dateFilter(scope.date.toDate, 'yyyy-MM-dd');
    const reportParams = {
      'output-type': 'CSV',
      locale: scope.optlang.code,
      R_startDate: scope.startDate,
      R_endDate: scope.endDate,
      R_savingsAccountId: scope.savingaccountdetails.accountNo
    };
    resourceFactory.clientSavingTransactionsCSVReportResource.get(reportParams, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'text/csv;charset=utf-8;' });

      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(file, 'download');
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(file);
          link.setAttribute('href', url);
          link.setAttribute('download', `${scope.savingaccountdetails.accountNo}_savings_transactions`);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  };

  scope.viewSavingsTransactionReceipts = function (transactionId) {
    scope.report = true;
    scope.viewTransactionReport = true;
    scope.viewSavingReport = false;
    scope.printbtn = false;
    scope.viewReport = true;
    scope.hidePentahoReport = true;

    resourceFactory.savingsTransactionReciept.get({ R_transactionId: transactionId }, function (data) {
      const file = new Blob([new Uint8Array(data.data)], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      // allow untrusted urls for iframe http://docs.angularjs.org/error/$sce/insecurl
      scope.viewReportDetails = $sce.trustAsResourceUrl(fileURL);
    });
  };

  scope.deletestandinginstruction = function (id) {
    $uibModal.open({
      templateUrl: 'delInstruction.html',
      controller: DelInstructionCtrl,
      resolve: {
        ids () {
          return id;
        }
      }
    });
  };

  const DelInstructionCtrl = function ($scope, $uibModalInstance, ids) {
    $scope.delete = function () {
      resourceFactory.standingInstructionResource.cancel({ standingInstructionId: ids }, function () {
        scope.searchTransaction();
        $uibModalInstance.close('delete');
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.printReport = function () {
    window.print();
    window.close();
  };

  scope.deleteAll = function (apptableName, entityId) {
    resourceFactory.DataTablesResource.delete({ datatablename: apptableName, entityId, genericResultSet: 'true' }, {}, function () {
      route.reload();
    });
  };

  scope.modifyTransaction = function (accountId, transactionId) {
    location.path(`/savingaccount/${accountId}/modifytransaction?transactionId=${transactionId}`);
  };

  scope.transactionSort = {
    column: 'id',
    descending: true
  };

  scope.changeTransactionSort = function (column) {
    const sort = scope.transactionSort;
    if (sort.column === column) {
      sort.descending = !sort.descending;
    } else {
      sort.column = column;
      sort.descending = true;
    }
  };

  scope.checkStatus = function () {
    if (scope.status === 'Active' || scope.status === 'Closed' || scope.status === 'Transfer in progress' ||
      scope.status === 'Transfer on hold' || scope.status === 'Premature Closed' || scope.status === 'Matured') {
      return true;
    }
    return false;
  };

  scope.getNotesData = function () {
    resourceFactory.savingAccountNotes.query({ accountId: routeParams.id }, function (data) {
      scope.savingAccountNotes = data;
    });
  };

  scope.getStoppedPayments = function () {
    resourceFactory.stopPaymentResource.get({ savingAccountId: routeParams.id }, function (data) {
      scope.stopPayments = data.pageItems;
    });
  };

  scope.routeToStopPayment = function (id) {
    if (id) location.path(`/savingaccounts/${routeParams.id}/stoppayments/${id}`);
    else location.path(`/savingaccounts/${routeParams.id}/stoppayments`);
  };

  scope.deleteStoppedPayments = function (id) {
    resourceFactory.stopPaymentResource.delete({ savingAccountId: routeParams.id, stoppaymentsId: id }, function () {
      scope.getStoppedPayments();
    });
  };

  scope.initFloatingRatePage = function () {
    resourceFactory.savingsFloatingRatesResource.get({
      savingsId: routeParams.id,
      offset: 0,
      limit: scope.floatingRatesPerPage
    }, function (data) {
      scope.totalFloatingRates = data.totalFilteredRecords;
      scope.floatingRates = data.pageItems;
    });
  };

  scope.getFloatingRatesPage = function (pageNumber = 1) {
    resourceFactory.savingsFloatingRatesResource.get({
      savingsId: routeParams.id,
      offset: ((pageNumber - 1) * scope.floatingRatesPerPage),
      limit: scope.floatingRatesPerPage
    }, function (data) {
      scope.totalFloatingRates = data.totalFilteredRecords;
      scope.floatingRates = data.pageItems;
    });
  };

  // scope.setActiveTab = (tab) => {
  //   scope.activeTab = tab;
  //   location.search({ tab });
  //   if (tab === 'transactions' || tab === 'pendingTransactions') scope.convertDateArrayToObject('date');
  //   if (tab === 'stopPayments') scope.getStoppedPayments();
  //   if (tab === 'floatingInterestRate') scope.initFloatingRatePage();
  //   if (tab === 'holdFunds') scope.getHoldFunds();
  //   if (tab === 'notes') scope.getNotesData();
  // };
}

ViewSavingDetailsController.$inject = ['$scope', '$routeParams', 'ResourceFactory', 'paginatorService', '$location', '$uibModal', '$route', 'dateFilter', '$sce', '$rootScope'];
