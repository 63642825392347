export const getAngularRootScope = () => {
  const $body = angular.element(document.getElementById('angular-app'));
  return $body.injector().get('$rootScope');
};

export const getAngularScope = () => {
  const $body = angular.element(document.getElementById('angular-app'));
  return $body.scope();
};

export const getAngularRoute = () => {
  const $body = angular.element(document.getElementById('angular-app'));
  return $body.injector().get('$route');
};

export const getAngularLocation = () => {
  const $body = angular.element(document.getElementById('angular-app'));
  return $body.injector().get('$location');
};

export const getAngularRouteParams = () => {
  const $body = angular.element(document.getElementById('angular-app'));
  return $body.injector().get('$routeParams');
};
