import { parse } from 'lossless-json';

export function ResourceFactoryProvider () {
  let baseUrl = '';
  const apiVer = '/v1';
  let tenantIdentifier = '';
  this.setBaseUrl = (url) => {
    baseUrl = url;
  };

  this.setTenantIdenetifier = function (tenant) {
    tenantIdentifier = tenant;
  };
  this.$get = ['$resource', '$rootScope', function (resource, $rootScope) {
    const defineResource = function (url, paramDefaults, actions) {
      const tempUrl = baseUrl;
      $rootScope.hostUrl = tempUrl;
      $rootScope.tenantIdentifier = tenantIdentifier;
      $rootScope.regions = {
        AF: 'Afghanistan',
        AX: '\u00c5land Islands',
        AL: 'Albania',
        DZ: 'Algeria',
        AS: 'American Samoa',
        AD: 'Andorra',
        AO: 'Angola',
        AI: 'Anguilla',
        AQ: 'Antarctica',
        AG: 'Antigua & Barbuda',
        AR: 'Argentina',
        AM: 'Armenia',
        AW: 'Aruba',
        AC: 'Ascension Island',
        AU: 'Australia',
        AT: 'Austria',
        AZ: 'Azerbaijan',
        BS: 'Bahamas',
        BH: 'Bahrain',
        BD: 'Bangladesh',
        BB: 'Barbados',
        BY: 'Belarus',
        BE: 'Belgium',
        BZ: 'Belize',
        BJ: 'Benin',
        BM: 'Bermuda',
        BT: 'Bhutan',
        BO: 'Bolivia',
        BA: 'Bosnia & Herzegovina',
        BW: 'Botswana',
        BR: 'Brazil',
        IO: 'British Indian Ocean Territory',
        VG: 'British Virgin Islands',
        BN: 'Brunei',
        BG: 'Bulgaria',
        BF: 'Burkina Faso',
        BI: 'Burundi',
        KH: 'Cambodia',
        CM: 'Cameroon',
        CA: 'Canada',
        IC: 'Canary Islands',
        CV: 'Cape Verde',
        BQ: 'Caribbean Netherlands',
        KY: 'Cayman Islands',
        CF: 'Central African Republic',
        EA: 'Ceuta & Melilla',
        TD: 'Chad',
        CL: 'Chile',
        CN: 'China',
        CX: 'Christmas Island',
        CC: 'Cocos (Keeling) Islands',
        CO: 'Colombia',
        KM: 'Comoros',
        CG: 'Congo - Brazzaville',
        CD: 'Congo - Kinshasa',
        CK: 'Cook Islands',
        CR: 'Costa Rica',
        CI: 'C\u00f4te d\u2019Ivoire',
        HR: 'Croatia',
        CU: 'Cuba',
        CW: 'Cura\u00e7ao',
        CY: 'Cyprus',
        CZ: 'Czechia',
        DK: 'Denmark',
        DG: 'Diego Garcia',
        DJ: 'Djibouti',
        DM: 'Dominica',
        DO: 'Dominican Republic',
        EC: 'Ecuador',
        EG: 'Egypt',
        SV: 'El Salvador',
        GQ: 'Equatorial Guinea',
        ER: 'Eritrea',
        EE: 'Estonia',
        SZ: 'Eswatini',
        ET: 'Ethiopia',
        FK: 'Falkland Islands',
        FO: 'Faroe Islands',
        FJ: 'Fiji',
        FI: 'Finland',
        FR: 'France',
        GF: 'French Guiana',
        PF: 'French Polynesia',
        TF: 'French Southern Territories',
        GA: 'Gabon',
        GM: 'Gambia',
        GE: 'Georgia',
        DE: 'Germany',
        GH: 'Ghana',
        GI: 'Gibraltar',
        GR: 'Greece',
        GL: 'Greenland',
        GD: 'Grenada',
        GP: 'Guadeloupe',
        GU: 'Guam',
        GT: 'Guatemala',
        GG: 'Guernsey',
        GN: 'Guinea',
        GW: 'Guinea-Bissau',
        GY: 'Guyana',
        HT: 'Haiti',
        HN: 'Honduras',
        HK: 'Hong Kong SAR China',
        HU: 'Hungary',
        IS: 'Iceland',
        IN: 'India',
        ID: 'Indonesia',
        IR: 'Iran',
        IQ: 'Iraq',
        IE: 'Ireland',
        IM: 'Isle of Man',
        IL: 'Israel',
        IT: 'Italy',
        JM: 'Jamaica',
        JP: 'Japan',
        JE: 'Jersey',
        JO: 'Jordan',
        KZ: 'Kazakhstan',
        KE: 'Kenya',
        KI: 'Kiribati',
        XK: 'Kosovo',
        KW: 'Kuwait',
        KG: 'Kyrgyzstan',
        LA: 'Laos',
        LV: 'Latvia',
        LB: 'Lebanon',
        LS: 'Lesotho',
        LR: 'Liberia',
        LY: 'Libya',
        LI: 'Liechtenstein',
        LT: 'Lithuania',
        LU: 'Luxembourg',
        MO: 'Macao SAR China',
        MG: 'Madagascar',
        MW: 'Malawi',
        MY: 'Malaysia',
        MV: 'Maldives',
        ML: 'Mali',
        MT: 'Malta',
        MH: 'Marshall Islands',
        MQ: 'Martinique',
        MR: 'Mauritania',
        MU: 'Mauritius',
        YT: 'Mayotte',
        MX: 'Mexico',
        FM: 'Micronesia',
        MD: 'Moldova',
        MC: 'Monaco',
        MN: 'Mongolia',
        ME: 'Montenegro',
        MS: 'Montserrat',
        MA: 'Morocco',
        MZ: 'Mozambique',
        MM: 'Myanmar (Burma)',
        NA: 'Namibia',
        NR: 'Nauru',
        NP: 'Nepal',
        NL: 'Netherlands',
        NC: 'New Caledonia',
        NZ: 'New Zealand',
        NI: 'Nicaragua',
        NE: 'Niger',
        NG: 'Nigeria',
        NU: 'Niue',
        NF: 'Norfolk Island',
        KP: 'North Korea',
        MK: 'North Macedonia',
        MP: 'Northern Mariana Islands',
        NO: 'Norway',
        OM: 'Oman',
        PK: 'Pakistan',
        PW: 'Palau',
        PS: 'Palestinian Territories',
        PA: 'Panama',
        PG: 'Papua New Guinea',
        PY: 'Paraguay',
        PE: 'Peru',
        PH: 'Philippines',
        PN: 'Pitcairn Islands',
        PL: 'Poland',
        PT: 'Portugal',
        XA: 'Pseudo-Accents',
        XB: 'Pseudo-Bidi',
        PR: 'Puerto Rico',
        QA: 'Qatar',
        RE: 'R\u00e9union',
        RO: 'Romania',
        RU: 'Russia',
        RW: 'Rwanda',
        WS: 'Samoa',
        SM: 'San Marino',
        ST: 'S\u00e3o Tom\u00e9 & Pr\u00edncipe',
        SA: 'Saudi Arabia',
        SN: 'Senegal',
        RS: 'Serbia',
        SC: 'Seychelles',
        SL: 'Sierra Leone',
        SG: 'Singapore',
        SX: 'Sint Maarten',
        SK: 'Slovakia',
        SI: 'Slovenia',
        SB: 'Solomon Islands',
        SO: 'Somalia',
        ZA: 'South Africa',
        GS: 'South Georgia & South Sandwich Islands',
        KR: 'South Korea',
        SS: 'South Sudan',
        ES: 'Spain',
        LK: 'Sri Lanka',
        BL: 'St. Barth\u00e9lemy',
        SH: 'St. Helena',
        KN: 'St. Kitts & Nevis',
        LC: 'St. Lucia',
        MF: 'St. Martin',
        PM: 'St. Pierre & Miquelon',
        VC: 'St. Vincent & Grenadines',
        SD: 'Sudan',
        SR: 'Suriname',
        SJ: 'Svalbard & Jan Mayen',
        SE: 'Sweden',
        CH: 'Switzerland',
        SY: 'Syria',
        TW: 'Taiwan',
        TJ: 'Tajikistan',
        TZ: 'Tanzania',
        TH: 'Thailand',
        TL: 'Timor-Leste',
        TG: 'Togo',
        TK: 'Tokelau',
        TO: 'Tonga',
        TT: 'Trinidad & Tobago',
        TA: 'Tristan da Cunha',
        TN: 'Tunisia',
        TR: 'Turkey',
        TM: 'Turkmenistan',
        TC: 'Turks & Caicos Islands',
        TV: 'Tuvalu',
        UM: 'U.S. Outlying Islands',
        VI: 'U.S. Virgin Islands',
        UG: 'Uganda',
        UA: 'Ukraine',
        AE: 'United Arab Emirates',
        GB: 'United Kingdom',
        US: 'United States',
        UY: 'Uruguay',
        UZ: 'Uzbekistan',
        VU: 'Vanuatu',
        VA: 'Vatican City',
        VE: 'Venezuela',
        VN: 'Vietnam',
        WF: 'Wallis & Futuna',
        EH: 'Western Sahara',
        YE: 'Yemen',
        ZM: 'Zambia',
        ZW: 'Zimbabwe'
      };
      return resource(baseUrl + url, paramDefaults, actions);
    };

    return {
      userResource: defineResource(`${apiVer}/users/:userId`, { userId: '@userId' }, {
        getAllUsers: { method: 'GET', params: { fields: 'id,firstname,lastname,username,officeName' }, isArray: true },
        getUser: { method: 'GET', params: {} }
      }),
      userDoccumentResource: defineResource(`${apiVer}/users/:userId/documents/:documentId`, { userId: '@userId', documentId: '@documentId' }, {
        getUserDocument: { method: 'GET', params: {}, isArray: true },
        getUserDocumentById: { method: 'GET', params: {} }
      }),
      roleResource: defineResource(`${apiVer}/roles/:roleId`, { roleId: '@roleId', command: '@command' }, {
        getAllRoles: { method: 'GET', params: {}, isArray: true },
        deleteRoles: { method: 'DELETE' },
        disableRoles: { method: 'POST' },
        enableRoles: { method: 'POST' },
        updateRole: { method: 'PUT' }
      }),
      rolePermissionResource: defineResource(`${apiVer}/roles/:roleId/permissions`, { roleId: '@roleId' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      transferTemplate: defineResource(`${apiVer}/transfers/template`, {}, {
        get: { method: 'GET' }
      }),
      permissionResource: defineResource(`${apiVer}/permissions`, {}, {
        get: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      officeResource: defineResource(`${apiVer}/offices/:officeId`, { officeId: '@officeId' }, {
        getAllOffices: { method: 'GET', params: {}, isArray: true },
        getAllOfficesInAlphabeticalOrder: { method: 'GET', params: { orderBy: 'name', sortOrder: 'ASC' }, isArray: true },
        update: { method: 'PUT' }
      }),
      officeImportTemplateResource: defineResource(`${apiVer}/offices/bulkimporttemplate`, {}, {
        get: { method: 'GET', params: {} }
      }),
      importResource: defineResource(`${apiVer}/imports`, {}, {
        getImports: { method: 'GET', params: {}, isArray: true }
      }),
      clientResource: defineResource(`${apiVer}/clients/:clientId/:anotherresource`, { clientId: '@clientId', anotherresource: '@anotherresource', sqlSearch: '@sqlSearch' }, {
        getAllClients: { method: 'GET', params: { limit: 1000, sqlSearch: '@sqlSearch' } },
        getAllClientsWithoutLimit: { method: 'GET', params: {} },
        getClientClosureReasons: { method: 'GET', params: {} },
        getAllClientDocuments: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' },
        retrieveTransferDate: { method: 'GET', params: {}, isArray: true },
        getSavingAccounts: { method: 'GET', params: { fields: 'savingsAccounts' } },
        verify: { method: 'POST', params: { tenantIdentifier: '@tenantIdentifier', command: 'verify' } },
        avs: { method: 'PUT', params: {} },
        getMlaStatusHistory: { method: 'GET', params: { }, isArray: true }
      }),
      clientAuthorizations: defineResource(`${apiVer}/clientauthorizations`, {}, {
        get: { method: 'GET', params: {} }
      }),
      clientChargesResource: defineResource(`${apiVer}/clients/:clientId/charges/:resourceType`, { clientId: '@clientId', resourceType: '@resourceType' }, {
        getCharges: { method: 'GET' },
        waive: { method: 'POST', params: { command: 'waive' } }
      }),
      clientTransactionResource: defineResource(`${apiVer}/clients/:clientId/transactions/:transactionId`, { clientId: '@clientId', transactionId: '@transactionId' }, {
        getTransactions: { method: 'GET', isArray: true },
        undoTransaction: { method: 'POST', params: { command: 'undo' } }
      }),
      clientIdentifierResource: defineResource(`${apiVer}/client_identifiers/:clientIdentityId/documents`, { clientIdentityId: '@clientIdentityId' }, {
        get: { method: 'GET', params: {}, isArray: true }
      }),
      clientDocumentsResource: defineResource(`${apiVer}/clients/:clientId/documents/:documentId`, { clientId: '@clientId', documentId: '@documentId' }, {
        getAllClientDocuments: { method: 'GET', params: {}, isArray: true },
        getClientDocument: { method: 'GET', params: {} }
      }),
      clientDocumentsUrl: defineResource(`${apiVer}/clients/:clientId/documents/:documentId/attachment/presigned`, { clientId: '@clientId', documentId: '@documentId' }, {
        get: {
          method: 'GET',
          transformResponse: function (data) {
            return { url: angular.fromJson(data) };
          }
        }
      }),
      clientAccountResource: defineResource(`${apiVer}/clients/:clientId/accounts`, { clientId: '@clientId' }, {
        getAllClients: { method: 'GET', params: {} }
      }),
      clientDetailResource: defineResource(`${apiVer}/clients/:clientId`, { clientId: '@clientId' }, {
        get: { method: 'GET', params: { fields: 'id,displayName' } }
      }),
      clientNotesResource: defineResource(`${apiVer}/clients/:clientId/notes/:noteId`, { clientId: '@clientId', noteId: '@noteId' }, {
        getAllNotes: { method: 'GET', params: {}, isArray: true },
        delete: { method: 'DELETE', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      clientTemplateResource: defineResource(`${apiVer}/clients/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      clientIdenfierTemplateResource: defineResource(`${apiVer}/clients/:clientId/identifiers/template`, { clientId: '@clientId' }, {
        get: { method: 'GET', params: {} }
      }),
      clientIdenfierResource: defineResource(`${apiVer}/clients/:clientId/identifiers/:id`, { clientId: '@clientId', id: '@id' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      clientPhoneNumber: defineResource(`${apiVer}/clients/:clientId/mobile/:id`, { clientId: '@clientId', id: '@id' }, {
        get: { method: 'GET', params: {} },
        delete: { method: 'DELETE', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      otpProtectedOperationResource: defineResource(`${apiVer}/otpProtectedOperation/:id`, { id: '@id' }, {
        update: { method: 'PUT' }
      }),
      groupResource: defineResource(`${apiVer}/groups/:groupId/:anotherresource`, { groupId: '@groupId', anotherresource: '@anotherresource' }, {
        get: { method: 'GET', params: {} },
        getAllGroups: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      groupSummaryResource: defineResource(`${apiVer}/runreports/:reportSource`, { reportSource: '@reportSource' }, {
        getSummary: { method: 'GET', params: {} }
      }),
      groupAccountResource: defineResource(`${apiVer}/groups/:groupId/accounts`, { groupId: '@groupId' }, {
        getAll: { method: 'GET', params: {} }
      }),
      groupNotesResource: defineResource(`${apiVer}/groups/:groupId/notes/:noteId`, { groupId: '@groupId', noteId: '@noteId' }, {
        getAllNotes: { method: 'GET', params: {}, isArray: true }
      }),
      groupTemplateResource: defineResource(`${apiVer}/groups/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      groupMeetingResource: defineResource(`${apiVer}/groups/:groupId/meetings/:templateSource`, { groupId: '@groupId', templateSource: '@templateSource' }, {
        getMeetingInfo: { method: 'GET', params: {} }
      }),
      attachMeetingResource: defineResource(`${apiVer}/:groupOrCenter/:groupOrCenterId/calendars/:templateSource`, {
        groupOrCenter: '@groupOrCenter',
        groupOrCenterId: '@groupOrCenterId',
        templateSource: '@templateSource'
      }, {
        update: { method: 'PUT' }
      }),
      runReportsResource: defineResource(`${apiVer}/runreports/:reportSource`, { reportSource: '@reportSource' }, {
        get: { method: 'GET', params: {}, isArray: true },
        getReport: { method: 'GET', params: {} },
        getTransferReport: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse (data, headersGetter) {
            return {
              data,
              headers: headersGetter
            };
          }
        }
      }),
      reportsResource: defineResource(`${apiVer}/reports/:id/:resourceType`, { id: '@id', resourceType: '@resourceType' }, {
        get: { method: 'GET', params: { id: '@id' } },
        getReport: { method: 'GET', params: { id: '@id' }, isArray: true },
        getReportDetails: { method: 'GET', params: { id: '@id' } },
        update: { method: 'PUT', params: {} }
      }),
      DataTablesResource: defineResource(`${apiVer}/datatables/:datatablename/:entityId/:resourceId`, { datatablename: '@datatablename', entityId: '@entityId', resourceId: '@resourceId' }, {
        getAllDataTables: { method: 'GET', params: {}, isArray: true },
        getTableDetails: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      DataTablesWorkflowResource: defineResource(`${apiVer}/datatableworkflows/:entityName/`, { entityName: '@entityName' }, {
        getAllDataTableWorkflows: { method: 'GET', params: {}, isArray: true },
        getWorkflowDetails: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      loanProductResource: defineResource(`${apiVer}/loanproducts/:loanProductId/:resourceType`, { resourceType: '@resourceType', loanProductId: '@loanProductId' }, {
        getAllLoanProducts: { method: 'GET', params: { loanType: '@loanType' }, isArray: true },
        getProductmix: { method: 'GET', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      chargeResource: defineResource(`${apiVer}/charges/:chargeId`, { chargeId: '@chargeId' }, {
        getAllCharges: { method: 'GET', params: {}, isArray: true },
        getCharge: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      chargeTemplateResource: defineResource(`${apiVer}/charges/template`, {
        get: { method: 'GET', params: {}, isArray: true },
        getChargeTemplates: { method: 'GET', params: {} }
      }),
      savingProductResource: defineResource(`${apiVer}/savingsproducts/:savingProductId/:resourceType`, { savingProductId: '@savingProductId', resourceType: '@resourceType' }, {
        getAllSavingProducts: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} }
      }),
      fixedDepositProductResource: defineResource(`${apiVer}/fixeddepositproducts/:productId/:resourceType`, { productId: '@productId', resourceType: '@resourceType' }, {
        getAllFixedDepositProducts: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} }
      }),
      recurringDepositProductResource: defineResource(`${apiVer}/recurringdepositproducts/:productId/:resourceType`, { productId: '@productId', resourceType: '@resourceType' }, {
        getAllRecurringDepositProducts: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} }
      }),

      notificationsResource: defineResource(`${apiVer}/notifications`, { sqlSearch: '@sqlSearch' }, {
        getAllNotifications: { method: 'GET', params: { isRead: true, sqlSearch: '@sqlSearch' } },
        getAllUnreadNotifications: { method: 'GET', params: { isRead: false, sqlSearch: '@sqlSearch' } },
        update: { method: 'PUT' }
      }),

      interestRateChartResource: defineResource(`${apiVer}/interestratecharts/:chartId/:resourceType`, { chartId: '@chartId', resourceType: '@resourceType' }, {
        getInterestRateChart: { method: 'GET', params: { productId: '@productId', template: '@template', associations: '@chartSlabs' }, isArray: true },
        update: { method: 'PUT', params: {} },
        getAllInterestRateCharts: { method: 'GET', params: { productId: '@productId' }, isArray: true }
      }),
      batchResource: defineResource(`${apiVer}/batches`, {}, {
        post: { method: 'POST', params: {}, isArray: true }
      }),
      loanResource: defineResource(`${apiVer}/loans/:loanId/:resourceType/:resourceId`, { resourceType: '@resourceType', loanId: '@loanId', resourceId: '@resourceId', limit: '@limit', sqlSearch: '@sqlSearch' }, {
        getAllLoans: { method: 'GET', params: { limit: '@limit', sqlSearch: '@sqlSearch' } },
        getAllNotes: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      loanChargeTemplateResource: defineResource(`${apiVer}/loans/:loanId/charges/template`, { loanId: '@loanId' }, {
        get: { method: 'GET', params: {} }
      }),
      loanChargesResource: defineResource(`${apiVer}/loans/:loanId/charges/:chargeId`, { loanId: '@loanId', chargeId: '@chargeId' }, {
      }),
      loanCollateralTemplateResource: defineResource(`${apiVer}/loans/:loanId/collaterals/template`, { loanId: '@loanId' }, {
        get: { method: 'GET', params: {} }
      }),
      loanTrxnsTemplateResource: defineResource(`${apiVer}/loans/:loanId/transactions/template`, { loanId: '@loanId' }, {
        get: { method: 'GET', params: {} }
      }),
      loanTemplateResource: defineResource(`${apiVer}/loans/:loanId/template`, { loanId: '@loanId' }, {
        get: { method: 'GET', params: {} }
      }),
      loanTrxnsResource: defineResource(`${apiVer}/loans/:loanId/transactions/:transactionId`, { loanId: '@loanId', transactionId: '@transactionId' }, {
        get: { method: 'GET', params: {} }
      }),
      LoanAccountResource: defineResource(`${apiVer}/loans/:loanId/:resourceType/:chargeId`, { loanId: '@loanId', resourceType: '@resourceType', chargeId: '@chargeId' }, {
        getLoanAccountDetails: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      LoanEditDisburseResource: defineResource(`${apiVer}/loans/:loanId/disbursements/:disbursementId`, { loanId: '@loanId', disbursementId: '@disbursementId' }, {
        getLoanAccountDetails: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      LoanAddTranchesResource: defineResource(`${apiVer}/loans/:loanId/disbursements/editDisbursements`, { loanId: '@loanId' }, {
        update: { method: 'PUT' }
      }),
      LoanDocumentResource: defineResource(`${apiVer}/loans/:loanId/documents/:documentId`, { loanId: '@loanId', documentId: '@documentId' }, {
        getLoanDocuments: { method: 'GET', params: {}, isArray: true }
      }),
      currenciesResource: defineResource(`${apiVer}/currencies`, {}, {
        update: { method: 'PUT' }
      }),
      userListResource: defineResource(`${apiVer}/users/:userId`, { userId: '@userId' }, {
        update: { method: 'PUT' }
      }),
      userChangePasswordResource: defineResource(`${apiVer}/users/:userId/changepassword`, { userId: '@userId' }, {
        update: { method: 'PUT' }
      }),
      userTemplateResource: defineResource(`${apiVer}/users/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      userResetPasswordResource: defineResource(`${apiVer}/users/:userId/resetpassword`, { userId: '@userId' }, {
        put: { method: 'PUT' }
      }),
      employeeResource: defineResource(`${apiVer}/staff/:staffId`, { staffId: '@staffId', status: 'all' }, {
        getAllEmployees: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      globalSearch: defineResource(`${apiVer}/search`, { query: '@query', resource: '@resource' }, {
        search: {
          method: 'GET',
          params: { query: '@query', resource: '@resource' },
          isArray: true
        }
      }),
      globalSearchTemplateResource: defineResource(`${apiVer}/search/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      globalAdHocSearchResource: defineResource(`${apiVer}/search/advance/`, {}, {
        get: { method: 'GET', params: {} },
        search: { method: 'POST', isArray: true },
        getClientDetails: { method: 'POST', params: { clientInfo: true }, isArray: true }
      }),
      fundsResource: defineResource(`${apiVer}/funds/:fundId`, { fundId: '@fundId' }, {
        getAllFunds: { method: 'GET', params: {}, isArray: true },
        getFund: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      accountingRulesResource: defineResource(`${apiVer}/accountingrules/:accountingRuleId`, { accountingRuleId: '@accountingRuleId' }, {
        getAllRules: { method: 'GET', params: { associations: 'all' }, isArray: true },
        getById: { method: 'GET', params: { accountingRuleId: '@accountingRuleId' } },
        get: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      accountingRulesTemplateResource: defineResource(`${apiVer}/accountingrules/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      accountCoaResource: defineResource(`${apiVer}/glaccounts/:glAccountId`, { glAccountId: '@glAccountId' }, {
        getAllAccountCoas: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      accountCoaTemplateResource: defineResource(`${apiVer}/glaccounts/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      journalEntriesResource: defineResource(`${apiVer}/journalentries/:trxid`, { trxid: '@transactionId' }, {
        get: { method: 'GET', params: { transactionId: '@transactionId' } },
        reverse: { method: 'POST', params: { command: 'reverse' } },
        search: { method: 'GET', params: {} }
      }),

      accountingClosureResource: defineResource(`${apiVer}/glclosures/:accId`, { accId: '@accId' }, {
        get: { method: 'GET', params: {}, isArray: true },
        getView: { method: 'GET', params: {} }
      }),
      periodicAccrualAccountingResource: defineResource(`${apiVer}/runaccruals`, {}, {
        run: { method: 'POST', params: {} }
      }),
      officeOpeningResource: defineResource(`${apiVer}/journalentries/openingbalance`, {}, {
        get: { method: 'GET', params: {} }
      }),
      codeResources: defineResource(`${apiVer}/codes/:codeId`, { codeId: '@codeId' }, {
        getAllCodes: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} }
      }),
      codeValueResource: defineResource(`${apiVer}/codes/:codeId/codevalues/:codevalueId`, { codeId: '@codeId', codevalueId: '@codevalueId' }, {
        getAllCodeValues: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} }
      }),
      hookResources: defineResource(`${apiVer}/hooks/:hookId`, { hookId: '@hookId' }, {
        getAllHooks: { method: 'GET', params: {}, isArray: true },
        getHook: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      hookTemplateResource: defineResource(`${apiVer}/hooks/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      entityToEntityResource: defineResource(`${apiVer}/entitytoentitymapping/:mappingId/:fromId/:toId`, { mappingId: '@mappingId' }, {
        getAllEntityMapping: { method: 'GET', params: {}, isArray: true },
        getEntityMapValues: { method: 'GET', params: {} }
      }),
      entityMappingResource: defineResource(`${apiVer}/entitytoentitymapping/:mapId`, { mappingId: '@mappingId' }, {
        getAllEntityMapping: { method: 'GET', params: {}, isArray: true },
        getEntityMapValues: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} },
        delete: { method: 'DELETE', params: {} }
      }),
      accountNumberResources: defineResource(`${apiVer}/accountnumberformats/:accountNumberFormatId`, { accountNumberFormatId: '@accountNumberFormatId' }, {
        get: { method: 'GET', params: { accountNumberFormatId: '@accountNumberFormatId' } },
        getAllPreferences: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT' },
        getPrefixType: { method: 'GET', params: { template: true } },
        delete: { method: 'DELETE', params: {} }
      }),
      accountNumberTemplateResource: defineResource(`${apiVer}/accountnumberformats/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      holResource: defineResource(`${apiVer}/holidays`, {}, {
        getAllHols: { method: 'GET', params: {}, isArray: true }
      }),
      holValueResource: defineResource(`${apiVer}/holidays/:holId`, { holId: '@holId' }, {
        getholvalues: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      holidayTemplateResource: defineResource(`${apiVer}/holidays/template`, {}, {
        get: { method: 'GET', params: {}, isArray: true }
      }),
      savingsAccounts: defineResource(`${apiVer}/savingsaccounts/:accountId`, { accountId: '@accountId' }, {
        getTransferOptions: { method: 'GET', params: { associations: 'transferOptions' } },
        updateTransferOptions: { method: 'POST', params: { command: 'updateTransferOptions' } },
        update: { method: 'POST', params: { command: '@command' } },
        closeAsScheduled: { method: 'POST', params: { command: 'closeAsScheduled' } }
      }),
      savingsTemplateResource: defineResource(`${apiVer}/savingsaccounts/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      savingsResource: defineResource(`${apiVer}/savingsaccounts/:accountId/:resourceType/:chargeId`, { accountId: '@accountId', resourceType: '@resourceType', chargeId: '@chargeId' }, {
        get: { method: 'GET', params: {} },
        getAllNotes: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      savingsAmountResource: defineResource(`${apiVer}/savingsaccount/:savingsAccountId/authorizations`, { savingsAccountId: '@savingsAccountId' }, {
        get: { method: 'GET', params: {} },
        hold: { method: 'POST', params: { command: 'hold' } },
        release: { method: 'POST', params: { command: 'release' } },
        settlement: { method: 'POST', params: { command: 'settlement' } }
      }),
      savingsChargeResource: defineResource(`${apiVer}/savingsaccounts/:accountId/charges/:resourceType`, { accountId: '@accountId', resourceType: '@resourceType' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      savingsTrxnsTemplateResource: defineResource(`${apiVer}/savingsaccounts/:savingsId/transactions/template`, { savingsId: '@savingsId' }, {
        get: { method: 'GET', params: { savingsId: '@savingsId' } }
      }),
      savingsTrxnsResource: defineResource(`${apiVer}/savingsaccounts/:savingsId/transactions/:transactionId`, { savingsId: '@savingsId', transactionId: '@transactionId' }, {
        get: { method: 'GET', params: { savingsId: '@savingsId', transactionId: '@transactionId' } },
        getAllTransactions: { method: 'GET', params: { savingsId: '@savingsId', limit: 1000 } }
      }),

      employeeAccountTrxnsResource: defineResource(`${apiVer}/savingsaccounts/:primaryAccountId/subsidiaryaccounts/:subsidiaryAccountId/transactions/:transactionId`, { primaryAccountId: '@primaryAccountId', subsidiaryAccountId: '@subsidiaryAccountId', transactionId: '@transactionId' }, {
        get: { method: 'GET', params: { primaryAccountId: '@primaryAccountId', subsidiaryAccountId: '@subsidiaryAccountId', transactionId: '@transactionId' } },
        getAllTransactions: { method: 'GET', params: { primaryAccountId: '@primaryAccountId', subsidiaryAccountId: '@subsidiaryAccountId', transactionId: '@transactionId', limit: 1000 } }
      }),

      cardAuthorizationsByCardResource: defineResource(`${apiVer}/cards/:token/authorization/:authorizationId`, { token: '@token', authorizationId: '@authorizationId' }, {
        getCardAuthorization: { method: 'GET', params: { limit: '@limit', token: '@token', authorizationId: '@authorizationId' } }
      }),

      savingsOnHoldTrxnsResource: defineResource(`${apiVer}/savingsaccounts/:savingsId/onholdtransactions`, { savingsId: '@savingsId' }, {
        get: { method: 'GET', params: {} }
      }),
      fixedDepositAccountResource: defineResource(`${apiVer}/fixeddepositaccounts/:accountId/:resourceType`, { accountId: '@accountId', resourceType: '@resourceType' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      fixedDepositAccountTemplateResource: defineResource(`${apiVer}/fixeddepositaccounts/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      fixedDepositTrxnsTemplateResource: defineResource(`${apiVer}/fixeddepositaccounts/:savingsId/transactions/template`, { savingsId: '@savingsId' }, {
        get: { method: 'GET', params: { savingsId: '@savingsId' } }
      }),
      fixedDepositTrxnsResource: defineResource(`${apiVer}/fixeddepositaccounts/:savingsId/transactions/:transactionId`, { savingsId: '@savingsId', transactionId: '@transactionId' }, {
        get: { method: 'GET', params: { savingsId: '@savingsId', transactionId: '@transactionId' } }
      }),
      recurringDepositAccountResource: defineResource(`${apiVer}/recurringdepositaccounts/:accountId/:resourceType`, { accountId: '@accountId', resourceType: '@resourceType' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT' }
      }),
      recurringDepositAccountTemplateResource: defineResource(`${apiVer}/recurringdepositaccounts/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      recurringDepositTrxnsTemplateResource: defineResource(`${apiVer}/recurringdepositaccounts/:savingsId/transactions/template`, { savingsId: '@savingsId' }, {
        get: { method: 'GET', params: { savingsId: '@savingsId' } }
      }),
      recurringDepositTrxnsResource: defineResource(`${apiVer}/recurringdepositaccounts/:savingsId/transactions/:transactionId`, { savingsId: '@savingsId', transactionId: '@transactionId' }, {
        get: { method: 'GET', params: { savingsId: '@savingsId', transactionId: '@transactionId' } }
      }),
      accountTransferResource: defineResource(`${apiVer}/accounttransfers/:id`, { id: '@id' }, {
        get: { method: 'GET', params: { } }
      }),
      accountTransfersTemplateResource: defineResource(`${apiVer}/accounttransfers/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      standingInstructionResource: defineResource(`${apiVer}/standinginstructions/:standingInstructionId`, { standingInstructionId: '@standingInstructionId' }, {
        get: { method: 'GET', params: { standingInstructionId: '@standingInstructionId' } },
        getTransactions: { method: 'GET', params: { standingInstructionId: '@standingInstructionId', associations: 'transactions' } },
        withTemplate: { method: 'GET', params: { standingInstructionId: '@standingInstructionId', associations: 'template' } },
        search: { method: 'GET', params: {} },
        update: { method: 'PUT', params: { command: 'update' } },
        cancel: { method: 'PUT', params: { command: 'delete' } }
      }),
      standingInstructionTemplateResource: defineResource(`${apiVer}/standinginstructions/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      standingInstructionHistoryResource: defineResource(`${apiVer}/standinginstructionrunhistory`, {}, {
        get: { method: 'GET', params: {} }
      }),
      getPendingTransactions: defineResource(`${apiVer}/savingsaccounts/:savingsId/pendingTransaction`, { savingsId: '@savingsId' }),
      centerAccountResource: defineResource(`${apiVer}/centers/:centerId/accounts`, { centerId: '@centerId' }, {
        getAll: { method: 'GET', params: {}, isArray: true }
      }),
      centerResource: defineResource(`${apiVer}/centers/:centerId/:anotherresource`, { centerId: '@centerId', anotherresource: '@anotherresource' }, {
        get: { method: 'GET', params: {} },
        getAllCenters: { method: 'GET', params: {}, isArray: true },
        getAllMeetingFallCenters: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' }
      }),
      centerMeetingResource: defineResource(`${apiVer}/centers/:centerId/meetings/:templateSource`, { centerId: '@centerId', templateSource: '@templateSource' }, {
        getMeetingInfo: { method: 'GET', params: {} }
      }),
      centerTemplateResource: defineResource(`${apiVer}/centers/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      jobsResource: defineResource(`${apiVer}/jobs/:id/:resourceType`, { id: '@id', resourceType: '@resourceType' }, {
        get: { method: 'GET', params: {}, isArray: true },
        getJobDetails: { method: 'GET', params: {} },
        getJobHistory: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      schedulerResource: defineResource(`${apiVer}/scheduler`, {}, {
        get: { method: 'GET', params: {} }
      }),
      assignStaffResource: defineResource(`${apiVer}/groups/:groupOrCenterId`, { groupOrCenterId: '@groupOrCenterId' }, {
        get: { method: 'GET', params: {} }
      }),
      configurationResource: defineResource(`${apiVer}/configurations/:id`, { id: '@id' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      configurationByNameResource: defineResource(`${apiVer}/configurations/name/:name`, { name: '@name' }, {
        get: { method: 'GET', params: {} }
      }),
      fieldconfigurationResource: defineResource(`${apiVer}/fieldconfigurations/:id`, { id: '@id' }, {
        update: { method: 'PUT', params: {} }
      }),
      fieldvalidationResource: defineResource(`${apiVer}/fieldconfigurations/:fieldConfigurationId/fieldvalidations/:fieldValidationId`, { fieldConfigurationId: '@fieldConfigurationId', fieldValidationId: '@fieldValidationId' }, {
        update: { method: 'PUT', params: {} }
      }),
      configurationResourceByName: defineResource(`${apiVer}/configurations/`, { configName: '@configName' }, {
        get: { method: 'GET', params: { configName: 'configName' } }
      }),
      cacheResource: defineResource(`${apiVer}/caches`, {}, {
        get: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} }
      }),
      templateResource: defineResource(`${apiVer}/templates/:templateId/:resourceType`, { templateId: '@templateId', resourceType: '@resourceType' }, {
        get: { method: 'GET', params: {}, isArray: true },
        getTemplateDetails: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      subscriberResource: defineResource(`${apiVer}/subscribers/:subscriberId/:resourceType`, { subscriberId: '@subscriberId', resourceType: '@resourceType' }, {
        getSubscriberDetails: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} }
      }),
      subscriptionResource: defineResource(`${apiVer}/subscriptions/:subscriptionId/:resourceType`, { subscriptionId: '@subscriptionId', resourceType: '@resourceType' }, {
        listEvents: { method: 'GET', params: { type: '@type' }, isArray: true },
        listBySubscriberId: { method: 'GET', params: { subscriberId: '@subscriberId' } }
      }),
      recurringPaymentResource: defineResource(`${apiVer}/recurringpayments/:id`, { id: '@id', clientId: '@clientId' }, {
        get: { method: 'GET', params: {} },
        getRecurringPaymentDetails: { method: 'GET', params: { id: '@id' } },
        update: { method: 'PUT', params: {} }
      }),
      beneficiariesResource: defineResource(`${apiVer}/beneficiaries/:id`, { id: '@id', clientId: '@clientId' }, {
        get: { method: 'GET', params: {} },
        getBeneficiaryDetails: { method: 'GET', params: { id: '@id' } }
      }),
      loanProductTemplateResource: defineResource(`${apiVer}/loanproducts/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      loanReassignmentResource: defineResource(`${apiVer}/loans/loanreassignment/:templateSource`, { templateSource: '@templateSource' }, {
        get: { method: 'GET', params: {} }
      }),
      loanRescheduleResource: defineResource(`${apiVer}/rescheduleloans/:scheduleId`, { scheduleId: '@scheduleId', command: '@command' }, {
        get: { method: 'GET', params: {} },
        getAll: { method: 'GET', params: {}, isArray: true },
        template: { method: 'GET', params: {} },
        preview: { method: 'GET', params: { command: 'previewLoanReschedule' } },
        put: { method: 'POST', params: { command: 'reschedule' } },
        reject: { method: 'POST', params: { command: 'reject' } },
        approve: { method: 'POST', params: { command: 'approve' } }
      }),
      auditResource: defineResource(`${apiVer}/audits/:templateResource`, { templateResource: '@templateResource' }, {
        get: {
          method: 'GET',
          params: {},
          transformResponse: function (data) {
            return parse(data);
          }
        },
        search: {
          method: 'GET',
          params: {},
          transformResponse: function (data) {
            return parse(data);
          }
        },
        clientAudit: { method: 'GET', params: {}, isArray: true }
      }),
      accessAttemptResource: defineResource(`${apiVer}/accessattempts`, { }, {
        get: { method: 'GET', params: {} }
      }),
      accessAttemptActionsResource: defineResource(`${apiVer}/accessattempts/:accessattemptId/logs`, { accessattemptId: '@accessattemptId' }, {
        get: { method: 'GET', params: {} }
      }),
      guarantorResource: defineResource(`${apiVer}/loans/:loanId/guarantors/:templateResource`, { loanId: '@loanId', templateResource: '@templateResource' }, {
        get: { method: 'GET', params: {} },
        update: { method: 'PUT', params: {} },
        delete: { method: 'DELETE', params: { guarantorFundingId: '@guarantorFundingId' } }
      }),
      guarantorAccountResource: defineResource(`${apiVer}/loans/:loanId/guarantors/accounts/template`, { loanId: '@loanId' }, {
        get: { method: 'GET', params: { clientId: '@clientId' } },
        update: { method: 'PUT', params: {} }
      }),
      checkerInboxResource: defineResource(`${apiVer}/makercheckers/:templateResource`, { templateResource: '@templateResource' }, {
        get: { method: 'GET', params: {} },
        search: { method: 'GET', params: {}, isArray: true },
        delete: { method: 'DELETE', hasBody: true }
      }),
      officeToGLAccountMappingResource: defineResource(`${apiVer}/financialactivityaccounts/:mappingId`, { mappingId: '@mappingId' }, {
        get: { method: 'GET', params: { mappingId: '@mappingId' } },
        getAll: { method: 'GET', params: {}, isArray: true },
        withTemplate: { method: 'GET', params: { mappingId: '@mappingId', template: 'true' } },
        search: { method: 'GET', params: {} },
        create: { method: 'POST', params: {} },
        update: { method: 'PUT', params: { mappingId: '@mappingId' } },
        delete: { method: 'DELETE', params: { mappingId: '@mappingId' } }
      }),
      officeToGLAccountMappingTemplateResource: defineResource(`${apiVer}/financialactivityaccounts/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      tellerResource: defineResource(`${apiVer}/tellers/:tellerId`, { tellerId: '@tellerId' }, {
        getAllTellers: { method: 'GET', params: {}, isArray: true },
        get: { method: 'GET', params: { tellerId: '@tellerId' } },
        update: { method: 'PUT', params: { tellerId: '@tellerId' } },
        delete: { method: 'DELETE', params: { tellerId: '@tellerId' } }
      }),
      tellerCashierResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/:cashierId`, { tellerId: '@tellerId', cashierId: '@cashierId' }, {
        getAllCashiersForTeller: { method: 'GET', params: { tellerId: '@tellerId' }, isArray: false },
        getCashier: { method: 'GET', params: { tellerId: '@tellerId', cashierId: '@cashierId' } },
        update: { method: 'PUT', params: { tellerId: '@tellerId', cashierId: '@cashierId' } },
        delete: { method: 'DELETE', params: { tellerId: '@tellerId', cashierId: '@cashierId' } }
      }),
      tellerCashierTemplateResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/template`, { tellerId: '@tellerId' }, {
        get: { method: 'GET', params: { tellerId: '@tellerId' }, isArray: false }
      }),
      tellerCashierTxnsResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/:cashierId/transactions`, { tellerId: '@tellerId', cashierId: '@cashierId' }, {
        getCashierTransactions: { method: 'GET', params: { tellerId: '@tellerId', cashierId: '@cashierId' }, isArray: true }
      }),
      tellerCashierSummaryAndTxnsResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/:cashierId/summaryandtransactions`, { tellerId: '@tellerId', cashierId: '@cashierId' }, {
        getCashierSummaryAndTransactions: { method: 'GET', params: { tellerId: '@tellerId', cashierId: '@cashierId' }, isArray: false }
      }),
      tellerCashierTxnsAllocateResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/:cashierId/allocate`, { tellerId: '@tellerId', cashierId: '@cashierId' }, {
        allocate: { method: 'POST', params: { tellerId: '@tellerId', cashierId: '@cashierId', command: 'allocate' } }
      }),
      tellerCashierTxnsSettleResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/:cashierId/settle`, { tellerId: '@tellerId', cashierId: '@cashierId' }, {
        settle: { method: 'POST', params: { tellerId: '@tellerId', cashierId: '@cashierId', command: 'settle' } }
      }),
      cashierTxnTemplateResource: defineResource(`${apiVer}/tellers/:tellerId/cashiers/:cashierId/transactions/template`, { tellerId: '@tellerId', cashierId: '@cashierId' }, {
        get: { method: 'GET', params: { tellerId: '@tellerId', cashierId: '@cashierId' }, isArray: false }
      }),
      collectionSheetResource: defineResource(`${apiVer}/collectionsheet`, {}, {
      }),
      workingDaysResource: defineResource(`${apiVer}/workingdays`, {}, {
        get: { method: 'GET', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      workingDaysResourceTemplate: defineResource(`${apiVer}/workingdays/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      passwordPrefTemplateResource: defineResource(`${apiVer}/passwordpreferences/template`, {}, {
        get: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      passwordPrefResource: defineResource(`${apiVer}/passwordpreferences`, {}, {
        put: { method: 'PUT', params: {} }
      }),
      paymentTypeResource: defineResource(`${apiVer}/paymenttypes/:paymentTypeId`, { paymentTypeId: '@paymentTypeId' }, {
        getAll: { method: 'GET', params: {}, isArray: true },
        get: { method: 'GET', params: { paymentTypeId: '@paymentTypeId' } },
        update: { method: 'PUT', params: { paymentTypeId: '@paymentTypeId' } }
      }),
      externalServicesS3Resource: defineResource(`${apiVer}/externalservice/S3`, {}, {
        get: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      externalServicesSMTPResource: defineResource(`${apiVer}/externalservice/SMTP`, {}, {
        get: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      externalServicesNotificationResource: defineResource(`${apiVer}/externalservice/NOTIFICATION`, {}, {
        get: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      externalServicesResource: defineResource(`${apiVer}/externalservice/:id`, { id: '@id' }, {
        get: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      clientaddressFields: defineResource(`${apiVer}/client/addresses/template`, {}, {
        get: { method: 'GET', params: {} }
      }
      ),
      addressFieldConfiguration: defineResource(`${apiVer}/fieldconfigurations`, { entity: '@entity' }, {
        get: { method: 'GET', params: {} }
      }),
      clientAddress: defineResource(`${apiVer}/client/:clientId/addresses/:addressId`, {}, {
        post: { method: 'POST', params: { type: '@type' } },
        get: { method: 'GET', params: { type: '@type', status: '@status' }, isArray: true },
        put: { method: 'PUT' }
      }),
      clientTier: defineResource(`${apiVer}/clients/:clientId/tiers/:tierId`, { clientId: '@clientId', tierId: '@tierId' }, {
        getAll: { method: 'GET', params: {}, isArray: true }
      }),
      familyMember: defineResource(`${apiVer}/clients/:clientId/familymembers/:clientFamilyMemberId`, {}, {

        get: { method: 'GET', params: {} },
        delete: { method: 'DELETE', params: {} },
        put: { method: 'PUT', params: {} }

      }),
      familyMembers: defineResource(`${apiVer}/clients/:clientId/familymembers/`, {}, {

        get: { method: 'GET', isArray: true },
        post: { method: 'POST', params: {} }

      }),
      familyMemberTemplate: defineResource(`${apiVer}/clients/:clientId/familymembers/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      provisioningcriteria: defineResource(`${apiVer}/provisioningcriteria/:criteriaId`, { criteriaId: '@criteriaId' }, {
        get: { method: 'GET', params: {} },
        getAll: { method: 'GET', params: {}, isArray: true },
        template: { method: 'GET', params: {} },
        post: { method: 'POST', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      provisioningentries: defineResource(`${apiVer}/provisioningentries/:entryId`, { entryId: '@entryId' }, {
        get: { method: 'GET', params: {} },
        getAll: { method: 'GET', params: {} },
        template: { method: 'GET', params: {} },
        post: { method: 'POST', params: {} },
        put: { method: 'PUT', params: {} },
        createJournals: { method: 'POST', params: { command: 'createjournalentry' } },
        reCreateProvisioningEntries: { method: 'POST', params: { command: 'recreateprovisioningentry' } },
        getJournals: { method: 'GET', params: { entryId: '@entryId' } }
      }),
      provisioningjournals: defineResource(`${apiVer}/journalentries/provisioning`, {}, {
        get: { method: 'GET', params: {} }
      }),
      provisioningentriesSearch: defineResource(`${apiVer}/provisioningentries/entries`, {}, {
        get: { method: 'GET', params: {} }
      }),

      provisioningcategory: defineResource(`${apiVer}/provisioningcategory`, {}, {
        getAll: { method: 'GET', params: {}, isArray: true }
      }),

      floatingrates: defineResource(`${apiVer}/floatingrates/:floatingRateId`, { floatingRateId: '@floatingRateId' }, {
        get: { method: 'GET', params: {} },
        getAll: { method: 'GET', params: {}, isArray: true },
        post: { method: 'POST', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      variableinstallments: defineResource(`${apiVer}/loans/:loanId/schedule`, { loanId: '@loanId' }, {
        validate: { method: 'POST', params: { command: 'calculateLoanSchedule' } },
        addVariations: { method: 'POST', params: { command: 'addVariations' } },
        deleteVariations: { method: 'POST', params: { command: 'deleteVariations' } }
      }),
      taxcomponent: defineResource(`${apiVer}/taxes/component/:taxComponentId`, { taxComponentId: '@taxComponentId' }, {
        getAll: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      taxcomponenttemplate: defineResource(`${apiVer}/taxes/component/template`, {}, {
      }),
      taxgroup: defineResource(`${apiVer}/taxes/group/:taxGroupId`, { taxGroupId: '@taxGroupId' }, {
        getAll: { method: 'GET', params: {}, isArray: true },
        put: { method: 'PUT', params: {} }
      }),
      taxgrouptemplate: defineResource(`${apiVer}/taxes/group/template`, {}, {
      }),

      productsResource: defineResource(`${apiVer}/products/:productType/:resourceType`, { productType: '@productType', resourceType: '@resourceType' }, {
        template: { method: 'GET', params: {} },
        post: { method: 'POST', params: {} }
      }),
      shareProduct: defineResource(`${apiVer}/products/share/:shareProductId`, { shareProductId: '@shareProductId' }, {
        post: { method: 'POST', params: {} },
        getAll: { method: 'GET', params: {} },
        get: { method: 'GET', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      shareAccountTemplateResource: defineResource(`${apiVer}/accounts/share/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      sharesAccount: defineResource(`${apiVer}/accounts/share/:shareAccountId`, { shareAccountId: '@shareAccountId' }, {
        get: { method: 'GET', params: {} },
        post: { method: 'POST', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      shareproductdividendresource: defineResource(`${apiVer}/shareproduct/:productId/dividend/:dividendId`, { productId: '@productId', dividendId: '@dividendId' }, {
        get: { method: 'GET', params: {} },
        getAll: { method: 'GET', params: {} },
        post: { method: 'POST', params: {} },
        put: { method: 'PUT', params: {} },
        approve: { method: 'PUT', params: { command: 'approve' } }
      }),

      smsResource: defineResource(`${apiVer}/sms/:campaignId/messageByStatus`, { campaignId: '@campaignId', additionalParam: '@additionalParam' }, {
        getByStatus: { method: 'GET', params: {} }
      }),

      entityDatatableChecksResource: defineResource(`${apiVer}/entityDatatableChecks/:entityDatatableCheckId/:additionalParam`, { entityDatatableCheckId: '@entityDatatableCheckId', additionalParam: '@additionalParam' }, {
        getAll: { method: 'GET', params: {} },
        get: { method: 'GET', params: {} },
        save: { method: 'POST', params: {} },
        delete: { method: 'DELETE', params: {} }
      }),

      adHocQueryResource: defineResource(`${apiVer}/adhocquery/:adHocId`, { adHocId: '@adHocId' }, {
        getAllAdHocQuery: { method: 'GET', params: {}, isArray: true },
        disableAdHocQuery: { method: 'POST' },
        enableAdHocQuery: { method: 'POST' },
        update: { method: 'PUT' }
      }),
      adHocQueryTemplateResource: defineResource(`${apiVer}/adhocquery/template`, {}, {
        get: { method: 'GET', params: {} }
      }),

      twoFactorResource: defineResource(`${apiVer}/otp`, {}, {
        getDeliveryMethods: { method: 'GET', params: {}, isArray: true },
        requestOTP: { method: 'POST' }
      }),
      twoFactorConfigResource: defineResource(`${apiVer}/mfa/requesttoken`, {}, {
        getAllConfigs: { method: 'GET', params: {} },
        put: { method: 'PUT', params: {} }
      }),
      totpResource: defineResource(`${apiVer}/totp`, {}, {
        disable: { method: 'POST', params: { command: 'reset' } }
      }),

      savingsTransactionReciept: defineResource(`${apiVer}/runreports/Savings%20Transaction%20Receipt?exportPDF=true`, {}, {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse (data, headersGetter) {
            return {
              data,
              headers: headersGetter
            };
          }
        }
      }),
      loanTransactionReciept: defineResource(`${apiVer}/runreports/Loan%20Transaction%20Receipt?output-type=PDF&tenantIdentifier=default&locale=en`, {}, {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse (data, headersGetter) {
            return {
              data,
              headers: headersGetter
            };
          }
        }
      }),
      clientSavingTransactionsReportResource: defineResource(`${apiVer}/runreports/:reportSource`, { reportSource: 'Client Saving Transactions' }, {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse (data, headersGetter) {
            return {
              data,
              headers: headersGetter
            };
          }
        }
      }),

      clientSavingTransactionsCSVReportResource: defineResource(`${apiVer}/runreports/:reportSource`, { reportSource: 'Client Saving Transactions_CSV' }, {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          transformResponse (data, headersGetter) {
            return {
              data,
              headers: headersGetter
            };
          }
        }
      }),
      pentahaReport: defineResource(`${apiVer}/runreports/:reportSource`, { reportSource: '@reportSource' }, {
        get: {
          method: 'GET',
          responseType: 'arraybuffer',
          params: {},
          transformResponse (data, headersGetter) {
            return {
              data,
              headers: headersGetter
            };
          }
        }
      }),
      // MBANQ: NEW TRANSFER RESOURCE
      settlementsResource: defineResource(`${apiVer}/cardSettlements/:settlementId`, { settlementId: '@settlementId' }, {
        update: { method: 'PUT' },
        query: { method: 'GET' }
      }),
      transferResource: defineResource(`${apiVer}/transfers/:id`, { action: '@action' }, {
        update: { method: 'PUT' },
        query: { method: 'GET', params: { action: '@action' } }
      }),
      transfersResource: defineResource(`${apiVer}/transfers`),
      returnTransfersResource: defineResource(`${apiVer}/transfers/return`),
      transferAmlScreeningResultDetails: defineResource(`${apiVer}/amlscreeningresult/transfer/:transferId`, { transferId: '@transferId' }),
      amlscreeningResource: defineResource(`${apiVer}/amlscreeningresult/state`),
      newAmlGroupsResource: defineResource(`${apiVer}/amlgroups/:id`, { id: '@id' }, {
        get: { method: 'GET', isArray: true },
        getOne: { method: 'GET', params: {} },
        update: { method: 'PUT', params: { id: '@id' } }
      }),
      amlGroupsResource: defineResource(`${apiVer}/amlgroup/:ruleName/:ruleId/:action/:actionId`),
      newAmlScreeningResultDetailsResource: defineResource(`${apiVer}/amlgroups/amlscreeningresultdetails/:id`, { id: '@id' }, {
        get: { method: 'GET', isArray: true }
      }),
      amlRulesResource: defineResource(`${apiVer}/amlgroup/amlrule/:ruleId/:action/:actionId`, {}, {
        update: { method: 'PUT' }
      }),
      amlWatchmansResource: defineResource(`${apiVer}/amlgroup/watchman/:ruleId/:action/:actionId`, {}, {
        update: { method: 'PUT' }
      }),
      amlNameMatchsResource: defineResource(`${apiVer}/amlgroup/namematch/:ruleId/:action/:actionId`, {}, {
        update: { method: 'PUT' }
      }),
      amlNetworkConnectionsResource: defineResource(`${apiVer}/amlgroup/ncrule/:ruleId/:action/:actionId`, {}, {
        update: { method: 'PUT' }
      }),

      // AML BLACK LISTED COUNTRIES
      amlRestrictedCountries: defineResource(`${apiVer}/blacklist_countries`, {}, {
        update: { method: 'PUT' },
        get: { method: 'GET' }
      }),

      // MBANQ: CARD
      cardsResource: defineResource(`${apiVer}/cards/:cardId`, { cardId: '@cardId' }, {
        update: { method: 'PUT', params: { command: '@command' } },
        changeStatus: { method: 'POST', params: { command: '@command' } },
        get: { method: 'GET', params: { associations: '@associations' } }
      }),

      // MBANQ: EXTERNAL CARD
      externalCardsResource: defineResource(`${apiVer}/clients/:clientId/externalCards/:externalCardId`, { clientId: '@clientId', externalCardId: '@externalCardId' }, {
        delete: { method: 'DELETE' }
      }),
      externalCardsTransactions: defineResource(`${apiVer}/clients/:clientId/externalCards/:externalCardId/transactions`, { clientId: '@clientId', externalCardId: '@externalCardId' }, {
      }),
      externalCardsKeyId: defineResource(`${apiVer}/externalCards/key`),

      chargeBack: defineResource(`${apiVer}/savingsaccounts/:savingAccountId/transactions`),

      // MBANQ: CARD PRODUCTS
      cardProductsResource: defineResource(`${apiVer}/cardproducts/:cardProductId`, { cardProductId: '@cardProductId' }, {
        update: { method: 'PUT' },
        updateStatus: { method: 'POST', params: { command: '@command' } }
      }),

      // MBANQ: CARD PROCESSOR
      cardProcessorsResource: defineResource(`${apiVer}/cardprocessors`, {}, {
        get: { method: 'GET', params: { fields: 'id,displayName' }, isArray: true }
      }),

      // MBANQ: WEBHOOK
      eventsResource: defineResource(`${apiVer}/events`),
      webhooksResource: defineResource(`${apiVer}/events/:eventAction/:eventActionId`, { eventAction: '@eventAction', eventActionId: '@eventActionId' }, {
        query: { method: 'GET', params: { fields: '@fields' }, isArray: true },
        update: { method: 'PUT' }
      }),

      // transfer limits
      transferLmitsResource: defineResource(`${apiVer}/transfer/limit/:transferLimitsId`, { transferLimitsId: '@transferLimitsId' }, {
        create: { method: 'POST' },
        update: { method: 'PUT' },
        delete: { method: 'DELETE' }
      }),

      transferLimitsTemplate: defineResource(`${apiVer}/transfer/limit/template`, {
        get: { method: 'GET' }
      }),

      // transfer limits
      transferClientLimitsResource: defineResource(`${apiVer}/transfer/client-limit/:transferLimitsId`, { transferLimitsId: '@transferLimitsId' }, {
        create: { method: 'POST' },
        update: { method: 'PUT' },
        delete: { method: 'DELETE' }
      }),

      // block merchant pull payments
      blockMerchantResource: defineResource(`${apiVer}/merchant-blocks/:blockId`, { blockId: '@blockId' }, {
        get: { method: 'GET', params: {}, isArray: false },
        create: { method: 'POST' },
        delete: { method: 'DELETE' }
      }),

      // stop payment in view saving account
      stopPaymentResource: defineResource(`${apiVer}/savingsaccounts/:savingAccountId/stoppayments/:stoppaymentsId`, { savingAccountId: '@savingAccountId', stoppaymentsId: '@stoppaymentsId' }, {
        get: { method: 'GET', params: {} },
        delete: { method: 'DELETE', params: {} },
        update: { method: 'PUT', params: {} },
        create: { method: 'POST', params: {} }
      }),

      stopPaymentResourceTemplate: defineResource(`${apiVer}/savingsaccounts/:savingAccountId/stoppayments/template`, { savingAccountId: '@savingAccountId' }, {
        get: { method: 'GET', params: {} }
      }),

      chargesTemplate: defineResource(`${apiVer}/charges`, { chargeTimeType: '@chargeTimeType' }, {
        get: { method: 'GET', params: {}, isArray: true }
      }),

      // MBANQ: GQL
      gqlResource: defineResource('/graphql', {}, {
        query: { method: 'POST', params: {} }
      }),

      amlscreeningresultResource: defineResource(`${apiVer}/amlscreeningresult/:screeningId`, { screeningId: '@screeningId' }, {
        put: { method: 'PUT' },
        query: { method: 'GET' }
      }),

      operationalAccountMappingResource: defineResource(`${apiVer}/operationalAccountsMapping/:activityType`, { activityType: '@type' }, {
        getAll: { method: 'GET', params: {}, isArray: true },
        create: { method: 'POST', params: {} },
        update: { method: 'PUT', params: {} },
        delete: { method: 'DELETE', params: {} }
      }),
      operationalAccountMappingTemplateResource: defineResource(`${apiVer}/operationalAccountsMapping/template`, {}, {
      }),

      authenticationDetailResource: defineResource(`${apiVer}/authenticationdetail`),
      resetpasswordResource: defineResource(`${apiVer}/resetpassword/:action`, { action: '@action' }, {
        request: { method: 'POST', params: { action: 'token' } }
      }),
      savingAccountNotes: defineResource(`${apiVer}/savings/:accountId/notes`),

      mccChargeResource: defineResource(`${apiVer}/merchantcharges/:id`, { id: '@id' }, {
        update: { method: 'PUT', params: {} },
        query: { method: 'GET', params: {}, isArray: true }
      }),
      clientAuditHistory: defineResource(`${apiVer}/clients/:clientId/history`, { clientId: '@clientId' }, {
        get: { method: 'GET', params: {}, isArray: true }
      }),
      clientNotificationHistory: defineResource(`${apiVer}/clients/:clientId/notificationhistory`),
      clientAuditHistoryTemplate: defineResource(`${apiVer}/clients/:clientId/history/template`, { clientId: '@clientId' }, {
        get: { method: 'GET', params: {} }
      }),
      noteResource: defineResource(`${apiVer}/:resourceType/:resourceId/notes/:noteId`, { resourceType: '@resourceType', resourceId: '@resourceId', noteId: '@noteId' }, {
        getAll: { method: 'GET', params: { }, isArray: true },
        get: { method: 'GET', params: {} },
        put: { method: 'PUT', params: { resourceType: '@resourceType', resourceId: '@resourceId', noteId: '@noteId' } },
        delete: { method: 'DELETE', params: { resourceType: '@resourceType', resourceId: '@resourceId', noteId: '@noteId' } }
      }),
      savingsFloatingRatesResource: defineResource(`${apiVer}/savingsaccounts/:savingsId/floatingRates`, { savingsId: '@savingsId' }, {
        getAll: { method: 'GET', params: { }, isArray: true },
        get: { method: 'GET', params: {} },
        put: { method: 'PUT', params: { resourceType: '@resourceType', resourceId: '@resourceId', noteId: '@noteId' } },
        delete: { method: 'DELETE', params: { resourceType: '@resourceType', resourceId: '@resourceId', noteId: '@noteId' } }
      }),
      creditProductResource: defineResource(`${apiVer}/creditproducts/:creditProductId`, { creditProductId: '@creditProductId' }, {
        getAllCreditProducts: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT', params: {} },
        get: { method: 'GET', params: {} }
      }),
      creditAccountTemplateResource: defineResource(`${apiVer}/creditcards/template`, {}, {
        get: { method: 'GET', params: {} }
      }),
      creditAccountResource: defineResource(`${apiVer}/creditcards/:accountId/:resourceType/:chargeId`, { accountId: '@accountId', resourceType: '@resourceType', chargeId: '@chargeId' }, {
        get: { method: 'GET', params: {} },
        getAllNotes: { method: 'GET', params: {}, isArray: true },
        update: { method: 'PUT' },
        getBillingCycles: { method: 'GET', params: {} }
      }),
      creditCardTrxnsResource: defineResource(`${apiVer}/creditcards/:cardId/transactions/:transactionId`, { cardId: '@cardId', transactionId: '@transactionId' }, {
        get: { method: 'GET', params: { cardId: '@cardId', transactionId: '@transactionId' } },
        getAllTransactions: { method: 'GET', params: { cardId: '@cardId', limit: 1000 } }
      }),
      getPendingCreditCardTransactions: defineResource(`${apiVer}/creditcards/:cardId/pendingTransaction`, { cardId: '@cardId' }),
      creditCardNotes: defineResource(`${apiVer}/creditCards/:cardId/notes`),
      creditCardAuthorizationsByCardResource: defineResource(`${apiVer}/creditcards/:token/authorization/:authorizationId`, { token: '@token', authorizationId: '@authorizationId' }, {
        getCardAuthorization: { method: 'GET', params: { limit: '@limit', token: '@token', authorizationId: '@authorizationId' } }
      }),
      creditCardCharges: defineResource(`${apiVer}/creditcards/:cardId/charges`),
      creditCardChargesAction: defineResource(`${apiVer}/creditcards/:accountId/charges/:chargeId`, {}, {
        save: { method: 'POST' }
      }),
      getFundAllocations: defineResource(`${apiVer}/savingsaccounts/:savingsId/fundAllocations`, { savingsId: '@savingId' }),
      tokenHandler: defineResource(`/oauth/invalidate`, {}, {
        invalidate: { method: 'POST' }
      }),
      documentsAndStatements: defineResource(`${apiVer}/savings/:accountId/documents`, { accountId: '@accountId' }, {
        get: { method: 'GET', params: {}, isArray: true }
      }),
      CreditCardDocumentResource: defineResource(`${apiVer}/creditcards/:cardId/documents/:documentId`, { cardId: '@cardId', documentId: '@documentId' }, {
        getCreditCardDocuments: { method: 'GET', params: {}, isArray: true }
      }),
      agingBucketResource: defineResource(`${apiVer}/agingbuckets`, {}, {
        get: { method: 'GET' }
      }),
      manageEmployeeAccounts: defineResource(`${apiVer}/savingsaccounts/:primaryAccountId/subsidiaryaccounts`, { primaryAccountId: '@primaryAccountId' }, {
        create: { method: 'post', params: {} }
      })
    };
  }];
}
