export function EditLimitRuleController (scope, routeParams, resourceFactory, location) {
  const { ruleGroupId: ruleId, id: limitRuleId } = routeParams;
  scope.limitRule = { id: limitRuleId, ruleGroup: { id: ruleId } };
  scope.ruleGroup = {};
  scope.formData = {};

  resourceFactory.paymentTypeResource.getAll(function (data) {
    scope.paymentTypes = [{ id: 0, name: 'ALL' }, ...data.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })];
  }, handleResponseError);

  function loadData () {
    const params = {
      amlRule: { ruleId, fields: 'id,name' },
      limitRule: { ruleId, action: 'limitrule', actionId: limitRuleId }
    };

    resourceFactory.amlRulesResource.get(params.amlRule, function (resAmlRule) {
      resourceFactory.amlRulesResource.get(params.limitRule, function (resLimitRule) {
        scope.ruleGroup = resAmlRule;
        delete resLimitRule.$promise;
        delete resLimitRule.$resolved;
        delete resLimitRule.id;
        delete resLimitRule.ruleGroupId;
        scope.formData = resLimitRule;
        scope.formData.legalForm = resLimitRule.legalForm.toString();
        if (!scope.formData.paymentType) scope.formData.paymentType = 0;
        scope.paymentType = scope.paymentTypes.find(paymentType => paymentType.id === scope.formData.paymentType);
        if (!scope.formData.clientClassificationId) scope.formData.clientClassificationId = 0;

        resourceFactory.codeResources.getAllCodes(function (data) {
          const codes = data;
          const clientClassificationCodeId = codes.find(code => code.name === 'ClientClassification').id;
          resourceFactory.codeValueResource.getAllCodeValues({ codeId: clientClassificationCodeId }, function (data) {
            scope.codeValues = [{ id: 0, name: 'ALL' }, ...data.sort((a, b) => {
              if (a.name < b.name) return -1;
              if (a.name > b.name) return 1;
              return 0;
            })];
            scope.clientClassification = scope.codeValues.find(codeValue => codeValue.id === scope.formData.clientClassificationId);
          });
        });
      }, handleResponseError);
    }, handleResponseError);
  }

  loadData();

  scope.onTransactionalRelationshipChange = function () {
    if (scope.formData.filterByTransactionalRelationship === 'SEEN') {
      scope.formData.filterByCounterpartyStatus = 'KNOWN';
    }
  };

  scope.submit = function () {
    const params = { ruleId, action: 'limitrule', actionId: limitRuleId };
    scope.formData.paymentType = scope.paymentType.id;
    scope.formData.clientClassificationId = scope.clientClassification.id;
    scope.formData.legalForm = Number(scope.formData.legalForm);
    resourceFactory.amlRulesResource.update(
      params, { ...scope.formData },
      function (res) {
        const { resourceId } = res;
        location.path(`/payments/aml/rulegroups/${ruleId}/limitrules/${resourceId}`);
      },
      handleResponseError
    );
  };

  function handleResponseError (error) {
    scope.formData.legalForm = scope.formData.legalForm.toString();
    const { data } = error;
    scope.errorMsg = data.errors[0].defaultUserMessage;
  }

  scope.closeAlert = function () {
    scope.errorMsg = '';
  };
}

EditLimitRuleController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location'];
