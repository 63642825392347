<template>
  <b-modal
    id="tx-force-post-transfer-modal"
    hide-header-close
    title="Force Post Confirmation"
  >
    Are you sure you want to force post its transfer?
    <template #modal-footer="{ cancel }">
      <button
        class="btn btn-default"
        @click="cancel"
      >
        {{ $t('label.button.cancel') }}
      </button>
      <button
        class="btn btn-primary"
        @click="reExecuteTransfer"
      >
        {{ $t('label.button.confirm') }}
      </button>
    </template>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'pinia';
import apiService from '@/services/apiService';
import { useTxMonitoringStore } from '@/stores/txmonitoring';

export default {
  name: 'TxForcePostTransferModal',
  computed: {
    ...mapState(useTxMonitoringStore, ['transaction'])
  },
  methods: {
    ...mapActions(useTxMonitoringStore, ['setNeedsToReloadList']),
    async reExecuteTransfer () {
      try {
        await apiService.transfer.saveTransfer(this.transaction.id, 'force_post');
        this.$bvModal.hide('tx-force-post-transfer-modal');
        this.setNeedsToReloadList(true);
      } catch (e) {
        this.$notify.error(e);
      }
    }
  }
};
</script>
