import _ from 'underscore';

export function EditInterestRateChartController (scope, resourceFactory, location, routeParams, dateFilter, $uibModal) {
  scope.formData = {};// used for update/save form data
  scope.restrictDate = new Date();
  scope.fromDate = {}; // required for date formatting
  scope.endDate = {};// required for date formatting
  scope.deletedincentives = [];
  scope.isPrimaryGroupingByAmount = false;

  // Fixed deposit product details
  scope.productName = routeParams.productName;
  scope.productId = routeParams.productId;

  scope.productsLink = '';
  scope.viewProductLink = '';
  scope.productType = routeParams.productType;
  // alert($scope.productType);
  if (routeParams.productType === 'fixeddepositproduct') {
    scope.productsLink = 'fixeddepositproducts';
    scope.viewProductLink = 'viewfixeddepositproduct';
  } else if (routeParams.productType === 'recurringdepositproduct') {
    scope.productsLink = 'recurringdepositproducts';
    scope.viewProductLink = 'viewrecurringdepositproduct';
  }

  // get a interestrate chart
  resourceFactory.interestRateChartResource.get({ chartId: routeParams.chartId, productId: routeParams.productId, template: true, associations: 'chartSlabs' }, function (data) {
    scope.chart = data;
    _.each(scope.chart.chartSlabs, function (chartSlab) {
      chartSlab.amountRangeTo = chartSlab.amountRangeTo || '';
      chartSlab.amountRangeFrom = chartSlab.amountRangeFrom || '';
      chartSlab.annualInterestRate = chartSlab.annualInterestRate || '';
      chartSlab.fromPeriod = chartSlab.fromPeriod || '';
      chartSlab.toPeriod = chartSlab.toPeriod || '';
      chartSlab.periodType = chartSlab.periodType.id;
      _.each(chartSlab.incentives, function (incentive) {
        incentive.attributeValue = parseInt(incentive.attributeValue, 10);
        incentive.amount = parseFloat(incentive.amount);
        incentive.attributeName = incentive.attributeName.id;
        incentive.conditionType = incentive.conditionType.id;
        incentive.incentiveType = incentive.incentiveType.id;
      });
    });

    // format date values
    if (scope.chart.fromDate) {
      const fromDate = dateFilter(scope.chart.fromDate, scope.df);
      scope.fromDate.date = new Date(fromDate);
    }
    if (scope.chart.endDate) {
      const endDate = dateFilter(scope.chart.endDate, scope.df);
      scope.endDate.date = new Date(endDate);
    }
    scope.isPrimaryGroupingByAmount = scope.chart.isPrimaryGroupingByAmount;
  });

  /**
             * Add a new row with default values for entering chart details
             */
  scope.addNewRow = function () {
    let fromPeriod = '';
    let amountRangeFrom = '';
    let periodType = '';
    let toPeriod = '';
    let amountRangeTo = '';
    if (_.isNull(scope.chart.chartSlabs) || _.isUndefined(scope.chart.chartSlabs)) {
      scope.chart.chartSlabs = [];
    } else {
      let lastChartSlab = {};
      if (scope.chart.chartSlabs.length > 0) {
        lastChartSlab = angular.copy(scope.chart.chartSlabs[scope.chart.chartSlabs.length - 1]);
      } else {
        lastChartSlab = null;
      }
      if (!(_.isNull(lastChartSlab) || _.isUndefined(lastChartSlab))) {
        if (scope.isPrimaryGroupingByAmount) {
          if ((_.isNull(lastChartSlab.toPeriod) || _.isUndefined(lastChartSlab.toPeriod) || lastChartSlab.toPeriod.length === 0)) {
            amountRangeFrom = _.isNull(lastChartSlab) ? '' : parseFloat(lastChartSlab.amountRangeTo) + 1;
            fromPeriod = (_.isNull(lastChartSlab.fromPeriod) || _.isUndefined(lastChartSlab.fromPeriod) || lastChartSlab.fromPeriod.length === 0) ? '' : 1;
          } else {
            amountRangeFrom = lastChartSlab.amountRangeFrom;
            amountRangeTo = lastChartSlab.amountRangeTo;
            fromPeriod = _.isNull(lastChartSlab) ? '' : parseInt(lastChartSlab.toPeriod, 10) + 1;
          }
        } else if ((_.isNull(lastChartSlab.amountRangeTo) || _.isUndefined(lastChartSlab.amountRangeTo) || lastChartSlab.amountRangeTo.length === 0)) {
          amountRangeFrom = (_.isNull(lastChartSlab.amountRangeFrom) || _.isUndefined(lastChartSlab.amountRangeFrom) || lastChartSlab.amountRangeFrom.length === 0) ? '' : 1;
          fromPeriod = _.isNull(lastChartSlab) ? '' : parseFloat(lastChartSlab.toPeriod) + 1;
        } else {
          fromPeriod = lastChartSlab.fromPeriod;
          toPeriod = lastChartSlab.toPeriod;
          amountRangeFrom = _.isNull(lastChartSlab) ? '' : parseInt(lastChartSlab.amountRangeTo, 10) + 1;
        }
        periodType = angular.copy(lastChartSlab.periodType);
      }
    }

    const chartSlab = {
      periodType,
      fromPeriod,
      amountRangeFrom,
      incentives: []
    };
    if (!_.isUndefined(toPeriod) && toPeriod.length > 0) {
      chartSlab.toPeriod = toPeriod;
    }
    if (!_.isUndefined(amountRangeTo) && amountRangeTo.length > 0) {
      chartSlab.amountRangeTo = amountRangeTo;
    }
    scope.chart.chartSlabs.push(chartSlab);
  };

  /**
             * Remove chart details row
             */
  scope.removeRow = function (index) {
    scope.chart.chartSlabs.splice(index, 1);
  };

  // back to deposit product view
  scope.cancel = function () {
    location.path(`/interestratecharts/${routeParams.productId}/${routeParams.productName}/${routeParams.productType}`);
  };

  /**
             * Update Interest rate chart details
             */
  scope.submitInterestRateChartForm = function () {
    const chartData = copyChartData(scope.chart);
    scope.formData.charts = [];// declare charts array
    scope.formData.charts.push(chartData);// add chart details

    if (routeParams.productType === 'fixeddepositproduct') {
      resourceFactory.fixedDepositProductResource.update({ productId: routeParams.productId }, scope.formData, function () {
        location.path(`/interestratecharts/${routeParams.productId}/${routeParams.productName}/${scope.productType}`);
      });
    } else if (routeParams.productType === 'recurringdepositproduct') {
      resourceFactory.recurringDepositProductResource.update({ productId: routeParams.productId }, scope.formData, function () {
        location.path(`/interestratecharts/${routeParams.productId}/${routeParams.productName}/${scope.productType}`);
      });
    }

    // resourceFactory.interestRateChartResource.update({chartId: routeParams.chartId}, chartData, function (data) {
    //  location.path('/interestratecharts/' + routeParams.productId + '/' + routeParams.productName);
    // });
  };

  /**
             *  create new chart data object
             */

  const copyChartData = function () {
    const chartData = {
      name: scope.chart.name,
      id: scope.chart.id,
      description: scope.chart.description,
      fromDate: dateFilter(scope.fromDate.date, scope.df),
      endDate: dateFilter(scope.endDate.date, scope.df),
      isPrimaryGroupingByAmount: scope.isPrimaryGroupingByAmount,
      // savingsProductId: $scope.chart.savingsProductId,
      dateFormat: scope.df,
      locale: scope.optlang.code,
      chartSlabs: angular.copy(copyChartSlabs(scope.chart.chartSlabs))
    };

    return chartData;
  };

  /**
             *  copy all chart details to a new Array
             * @param chartSlabs
             * @returns {Array}
             */
  const copyChartSlabs = function (chartSlabs) {
    const detailsArray = [];
    _.each(chartSlabs, function (chartSlab) {
      const chartSlabData = copyChartSlab(chartSlab);
      detailsArray.push(chartSlabData);
    });
    return detailsArray;
  };

  /**
             * create new chart detail object data from chartSlab
             * @param chartSlab
             *
             */

  const copyChartSlab = function (chartSlab) {
    const newChartSlabData = {
      id: chartSlab.id,
      description: chartSlab.description,
      fromPeriod: chartSlab.fromPeriod,
      toPeriod: chartSlab.toPeriod,
      amountRangeFrom: chartSlab.amountRangeFrom,
      amountRangeTo: chartSlab.amountRangeTo,
      annualInterestRate: chartSlab.annualInterestRate,
      locale: scope.optlang.code,
      incentives: angular.copy(copyIncentives(chartSlab.incentives, chartSlab.id))
    };
    if (chartSlab.periodType !== undefined) {
      newChartSlabData.periodType = chartSlab.periodType;
    }

    // remove empty values
    _.each(newChartSlabData, function (v, k) {
      if (!v && v !== 0) { delete newChartSlabData[k]; }
    });

    return newChartSlabData;
  };

  scope.incentives = function (index) {
    $uibModal.open({
      templateUrl: 'incentive.html',
      controller: IncentiveCtrl,
      size: 'lg',
      resolve: {
        data () {
          return scope.chart;
        },
        chartSlab () {
          return scope.chart.chartSlabs[index];
        }
      }
    });
  };

  /**
             *  copy all chart details to a new Array
             * @param incentiveDatas
             * @returns {Array}
             */
  const copyIncentives = function (incentives, slabId) {
    const detailsArray = [];
    _.each(incentives, function (incentive) {
      const incentiveData = copyIncentive(incentive);
      detailsArray.push(incentiveData);
    });
    _.each(scope.deletedincentives, function (del) {
      if (del.id === slabId && del.data.id) {
        detailsArray.push(del.data);
      }
    });

    return detailsArray;
  };

  /**
             * create new chart detail object data from chartSlab
             * @param incentiveData
             *
             */

  const copyIncentive = function (incentiveData) {
    const newIncentiveDataData = {
      id: incentiveData.id,
      entityType: incentiveData.entityType,
      attributeName: incentiveData.attributeName,
      conditionType: incentiveData.conditionType,
      attributeValue: incentiveData.attributeValue,
      incentiveType: incentiveData.incentiveType,
      amount: incentiveData.amount,
      locale: scope.optlang.code

    };
    if (incentiveData.id) {
      newIncentiveDataData.entityType = incentiveData.entityType.id;
    }
    return newIncentiveDataData;
  };

  const IncentiveCtrl = function ($scope, $uibModalInstance, data, chartSlab) {
    $scope.data = data;
    $scope.chartSlab = chartSlab;
    if (!$scope.chartSlab.incentives) {
      $scope.chartSlab.incentives = [];
    }
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };

    $scope.addNewRow = function () {
      const incentive = {
        entityType: '2',
        attributeName: '',
        conditionType: '',
        attributeValue: '',
        incentiveType: '',
        amount: ''
      };

      $scope.chartSlab.incentives.push(incentive);
    };

    /**
                 * Remove chart details row
                 */
    $scope.removeRow = function (index) {
      const incentive = {
        id: $scope.chartSlab.incentives[index].id,
        delete: 'true'
      };
      const deldata = {
        id: chartSlab.id,
        data: incentive
      };
      scope.deletedincentives.push(deldata);
      $scope.chartSlab.incentives.splice(index, 1);
    };
  };
}

EditInterestRateChartController.$inject = ['$scope', 'ResourceFactory', '$location', '$routeParams', 'dateFilter', '$uibModal'];
