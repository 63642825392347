<template>
  <div class="w-100">
    <template v-if="addresses?.length">
      <base-table
        v-for="address in addresses"
        :key="address.addressId"
        :items="parseArray(address)"
        vertical
      >
        <tr>
          <th
            colspan="4"
          >
            <div class="d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <i
                  v-tooltips="$t(`label.anchor.${address.isActive ? 'active' : 'inactive'}`)"
                  class="fas fa-stop me-1"
                  :class="generalStatusLookUp(address.isActive.toString())"
                /><span>{{ address.addressType }}</span>
              </div>

              <div class="d-flex justify-content-end gap-2">
                <button
                  v-has-permission="'UPDATE_ADDRESS'"
                  class="btn btn-primary"
                  :disabled="loading"
                  @click="routeToEdit(address)"
                >
                  {{ $t('label.button.edit') }}
                </button>
                <button
                  v-if="address.addressType != 'PRIMARY'"
                  v-has-permission="'DELETE_ADDRESS'"
                  class="btn btn-danger"
                  :disabled="loading"
                  @click="deleteAddress(address.addressId)"
                >
                  {{ $t('label.button.delete') }}
                </button>
                <button
                  class="btn"
                  :class="address.isActive ? 'btn-danger' : 'btn-success'"
                  :disabled="loading"
                  @click="updateStatus(address)"
                >
                  {{ $t(`label.button.${address.isActive ? 'deactivate' : 'activate'}`) }}
                </button>
              </div>
            </div>
          </th>
        </tr>
      </base-table>
    </template>
    <div
      v-else
      class="text-center text-secondary mb-3"
    >
      {{ $t('label.heading.noAddressesAdded') }}
    </div>
  </div>
</template>

<script setup>
import BaseTable from '@/components/shared/data-grid/BaseTable.vue';
import { generalStatusLookUp } from '@/helper/statusLookUp';
import i18n from '@/plugins/i18n';
import prompt from '@/plugins/prompt';
import { $notify } from '@/plugins/prototypes';
import router from '@/router/index';
import apiService from '@/services/apiService';
import { useSharedStore } from '@/stores/shared';
import { computed } from 'vue';

const props = defineProps({
  addresses: {
    type: Array,
    required: true
  },
  clientId: {
    type: String,
    required: true
  }
});

const emits = defineEmits(['update']);
const sharedStore = useSharedStore();
const loading = computed(() => sharedStore.loading);

const { clientId } = props;

function parseArray (address) {
  return [
    { label: i18n.t('label.head.addressLineOne'), value: address.addressLine1 },
    { label: i18n.t('label.head.addressLineTwo'), value: address.addressLine2 },
    { label: i18n.t('label.head.addressLineThree'), value: address.addressLine3 },
    { label: i18n.t('label.head.city'), value: address.city },
    { label: i18n.t('label.head.stateOrProvince'), value: address.stateName },
    { label: i18n.t('label.head.country'), value: address.countryName },
    { label: i18n.t('label.head.postalCode'), value: address.postalCode }
  ];
}

async function deleteAddress (id) {
  try {
    const confirmation = await prompt.confirm(i18n.t('messages.areYouSureYouWantToDelete'));
    if (confirmation) {
      await apiService.clientAddress.delete(clientId, id);
      emits('update');
    }
  } catch (error) {
    $notify.error(error);
  }
}

function routeToEdit ({ addressId: addrId, addressTypeId: addrType }) {
  router.push({ name: 'EditClientAddress', params: { clientId, addrType, addrId } });
}

async function updateStatus ({ addressId, addressTypeId: type, isActive }) {
  try {
    await apiService.clientAddress.put(clientId, { type }, { addressId, isActive: !isActive });
    emits('update');
  } catch (error) {
    $notify.error(error);
  }
}
</script>
