import _ from 'underscore';
import { getRandomString, getClonnedName } from '../../common-methods';

export function CreateSavingProductController (scope, resourceFactory, location, WizardHandler, dateFilter, rootScope, filter) {
  const searchParams = location.search();
  if (searchParams.clone) {
    getSavingsProductById(searchParams.clone);
  }

  scope.formData = {
    nominalAnnualInterestRate: 0
  };
  scope.savingproduct = {};
  scope.charges = [];
  scope.showOrHideValue = 'show';
  scope.configureFundOptions = [];
  scope.specificIncomeaccounts = [];
  scope.penaltySpecificIncomeaccounts = [];
  scope.configureFundOption = {};
  scope.isClicked = false;
  scope.date = {
    startDate: '',
    endDate: ''
  };

  resourceFactory.savingProductResource.get({ resourceType: 'template' }, function (data) {
    scope.product = data;
    scope.product.chargeOptions = scope.product.chargeOptions || [];
    scope.product.penaltyOptions = scope.product.penaltyOptions || [];
    scope.floatingRateOptions = scope.product.floatingRateOptions || [];
    scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
    scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
    scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
    scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];

    scope.formData.currencyCode = data.currencyOptions[0].code;
    scope.formData.digitsAfterDecimal = data.currencyOptions[0].decimalPlaces;
    scope.formData.interestCompoundingPeriodType = data.interestCompoundingPeriodType.id;
    scope.formData.interestPostingPeriodType = data.interestPostingPeriodType.id;
    scope.formData.interestCalculationType = data.interestCalculationType.id;
    scope.formData.interestCalculationDaysInYearType = data.interestCalculationDaysInYearType.id;
    scope.formData.accountingRule = '1';
    scope.savingproduct = angular.copy(scope.formData);
  });

  scope.$watch(
    'formData',
    function (newVal) {
      scope.savingproduct = angular.extend(scope.savingproduct, newVal);
    },
    true
  );

  scope.goNext = function (form) {
    WizardHandler.wizard().checkValid(form);
    scope.isClicked = true;
  };

  scope.formValue = function (array, model, findattr, retAttr) {
    findattr = findattr || 'id';
    retAttr = retAttr || 'value';
    return _.find(array, function (obj) {
      return obj[findattr] === model;
    })[retAttr];
  };

  scope.showOrHide = function (showOrHideValue) {
    if (showOrHideValue === 'show') {
      scope.showOrHideValue = 'hide';
    }

    if (showOrHideValue === 'hide') {
      scope.showOrHideValue = 'show';
    }
  };

  scope.chargeSelected = function (chargeId) {
    if (chargeId) {
      resourceFactory.chargeResource.get(
        { chargeId, template: 'true' },
        this.formData,
        function (data) {
          data.chargeId = data.id;
          data.isMandatory = false;
          scope.charges.push(data);
          scope.chargeId = '';
        }
      );
    }
  };

  scope.deleteCharge = function (index) {
    scope.charges.splice(index, 1);
  };

  scope.addConfigureFundSource = function () {
    if (
      scope.product.paymentTypeOptions &&
            scope.product.paymentTypeOptions.length > 0 &&
            scope.assetAccountOptions &&
            scope.assetAccountOptions.length > 0
    ) {
      const assetsAndExpanse = scope.assetAccountOptions.concat(scope.expenseAccountOptions);
      scope.configureFundOptions.push({
        paymentTypeId: scope.product.paymentTypeOptions[0].id,
        fundSourceAccountId: scope.assetAccountOptions[0].id,
        paymentTypeOptions: scope.product.paymentTypeOptions,
        assetAccountOptions: assetsAndExpanse
      });
    }
  };
  scope.mapFees = function () {
    if (
      scope.product.chargeOptions &&
            scope.product.chargeOptions.length > 0 &&
            scope.incomeAccountOptions &&
            scope.incomeAccountOptions.length > 0
    ) {
      scope.specificIncomeaccounts.push({
        chargeId: scope.product.chargeOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        chargeOptions: scope.product.chargeOptions,
        incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
      });
    }
  };

  scope.mapPenalty = function () {
    if (
      scope.product.penaltyOptions &&
            scope.product.penaltyOptions.length > 0 &&
            scope.incomeAccountOptions &&
            scope.incomeAccountOptions.length > 0
    ) {
      scope.penaltySpecificIncomeaccounts.push({
        chargeId: scope.product.penaltyOptions[0].id,
        incomeAccountId: scope.incomeAccountOptions[0].id,
        penaltyOptions: scope.product.penaltyOptions,
        incomeAccountOptions: scope.incomeAccountOptions
      });
    } else {
      rootScope.errorStatus = filter('translate')('label.message.noPenaltyOptions');
    }
  };

  scope.deleteFund = function (index) {
    scope.configureFundOptions.splice(index, 1);
  };

  scope.deleteFee = function (index) {
    scope.specificIncomeaccounts.splice(index, 1);
  };

  scope.deletePenalty = function (index) {
    scope.penaltySpecificIncomeaccounts.splice(index, 1);
  };

  scope.cancel = function () {
    location.path('/products/saving-accounts');
  };

  scope.submit = function () {
    const reqStartDate = dateFilter(scope.date.startDate, scope.df);
    const reqEndDate = dateFilter(scope.date.endDate, scope.df);
    scope.paymentChannelToFundSourceMappings = [];
    scope.feeToIncomeAccountMappings = [];
    scope.penaltyToIncomeAccountMappings = [];
    scope.chargesSelected = [];

    let temp = '';

    scope.formData.nominalAnnualInterestRate = 0;
    // configure fund sources for payment channels
    for (const i in scope.configureFundOptions) {
      temp = {
        paymentTypeId: scope.configureFundOptions[i].paymentTypeId,
        fundSourceAccountId: scope.configureFundOptions[i].fundSourceAccountId
      };
      scope.paymentChannelToFundSourceMappings.push(temp);
    }

    // map fees to specific income accounts
    for (const i in scope.specificIncomeaccounts) {
      temp = {
        chargeId: scope.specificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.specificIncomeaccounts[i].incomeAccountId
      };
      scope.feeToIncomeAccountMappings.push(temp);
    }

    // map penalties to specific income accounts
    for (const i in scope.penaltySpecificIncomeaccounts) {
      temp = {
        chargeId: scope.penaltySpecificIncomeaccounts[i].chargeId,
        incomeAccountId: scope.penaltySpecificIncomeaccounts[i].incomeAccountId
      };
      scope.penaltyToIncomeAccountMappings.push(temp);
    }

    for (const i in scope.charges) {
      temp = {
        id: scope.charges[i].id,
        isMandatory: scope.charges[i].isMandatory
      };
      scope.chargesSelected.push(temp);
    }

    if (scope.formData.isLinkedToFloatingInterestRates) {
      delete scope.formData.nominalAnnualInterestRate;
      scope.formData.isFloatingInterestRateCalculationAllowed = scope.formData.isFloatingInterestRateCalculationAllowed || false;
    } else {
      delete scope.formData.floatingRateId;
      delete scope.formData.interestRateDifferential;
      delete scope.formData.isFloatingInterestRateCalculationAllowed;
      delete scope.formData.minDifferentialRate;
      delete scope.formData.defaultDifferentialRate;
      delete scope.formData.maxDifferentialRate;
      delete scope.formData.isLinkedToFloatingInterestRates;
    }

    this.formData.paymentChannelToFundSourceMappings = scope.paymentChannelToFundSourceMappings;
    this.formData.feeToIncomeAccountMappings = scope.feeToIncomeAccountMappings;
    this.formData.penaltyToIncomeAccountMappings = scope.penaltyToIncomeAccountMappings;
    this.formData.charges = scope.chargesSelected;
    this.formData.locale = scope.optlang.code;
    this.formData.dateFormat = scope.df;
    this.formData.startDate = reqStartDate;
    this.formData.endDate = reqEndDate;

    resourceFactory.savingProductResource.save(this.formData, function (data) {
      location.path(`/viewsavingproduct/${data.resourceId}`);
    });
  };

  function getSavingsProductById (savingsProductId) {
    scope.formData = {};
    scope.charges = [];
    scope.showOrHideValue = 'show';
    scope.configureFundOptions = [];
    scope.specificIncomeaccounts = [];
    scope.penaltySpecificIncomeaccounts = [];
    scope.configureFundOption = {};

    resourceFactory.savingProductResource.get({ savingProductId: savingsProductId, template: 'true' }, (data) => {
      const creditName = getClonnedName(data.name);
      const randomString = getRandomString(4);

      scope.formData = {
        // Require to show initially
        name: creditName,
        shortName: randomString,
        description: data.description,
        isReservedProduct: data.isReservedProduct
      };

      scope.product = data;
      scope.assetAccountOptions = scope.product.accountingMappingOptions.assetAccountOptions || [];
      scope.liabilityAccountOptions = scope.product.accountingMappingOptions.liabilityAccountOptions || [];
      scope.incomeAccountOptions = scope.product.accountingMappingOptions.incomeAccountOptions || [];
      scope.expenseAccountOptions = scope.product.accountingMappingOptions.expenseAccountOptions || [];
      scope.reserveProductOptions = scope.product.reserveProductOptions || [];

      // Added a time
      setTimeout(() => {
        scope.formData = {

          // details tab
          name: creditName,
          shortName: randomString,
          description: data.description,
          isReservedProduct: data.isReservedProduct,

          // currency tab
          currencyCode: data.currency.code,
          inMultiplesOf: data.currency.inMultiplesOf,
          digitsAfterDecimal: data.currency.decimalPlaces,

          // terms tab
          isLinkedToFloatingInterestRates: data.isLinkedToFloatingInterestRates,
          isFloatingInterestRateCalculationAllowed: data.isFloatingInterestRateCalculationAllowed,
          floatingRateId: data.floatingRateId,
          interestRateDifferential: data.interestRateDifferential,
          minDifferentialRate: data.minDifferentialRate,
          maxDifferentialRate: data.maxDifferentialRate,
          defaultDifferentialRate: data.defaultDifferentialRate,
          interestCompoundingPeriodType: data.interestCompoundingPeriodType?.id || '',
          interestPostingPeriodType: data.interestPostingPeriodType?.id || '',
          interestCalculationType: data.interestCalculationType?.id || '',
          interestCalculationDaysInYearType: data.interestCalculationDaysInYearType?.id || '',

          // settings
          minRequiredOpeningBalance: data.minRequiredOpeningBalance,
          lockinPeriodFrequency: data.lockinPeriodFrequency,
          lockinPeriodFrequencyType: data.lockinPeriodFrequencyType?.id || '',
          withdrawalFeeForTransfers: data.withdrawalFeeForTransfers || '',
          minBalanceForInterestCalculation: data.minBalanceForInterestCalculation || '',
          enforceMinRequiredBalance: data.enforceMinRequiredBalance || '',
          minRequiredBalance: data.minRequiredBalance || '',
          allowOverdraft: data.allowOverdraft || '',
          roundingMode: data.roundingMode || '',
          overdraftLimit: data.overdraftLimit || '',
          nominalAnnualInterestRateOverdraft: data.nominalAnnualInterestRateOverdraft || '',
          minOverdraftForInterestCalculation: data.minOverdraftForInterestCalculation || '',
          withHoldTax: data.withHoldTax || '',
          isDormancyTrackingActive: data.isDormancyTrackingActive || '',
          taxGroupId: data.taxGroup?.id || '',
          daysToInactive: data.daysToInactive || '',
          daysToDormancy: data.daysToDormancy || '',
          daysToEscheat: data.daysToEscheat || '',
          isUsedForSuspenseAccounting: data.isUsedForSuspenseAccounting || '',
          isLinkedWithFundSourceAccount: data.isLinkedWithFundSourceAccount || '',
          skipCollectTransferCharge: data.isSkipCollectTransferCharge || '',
          escheatLiabilityId: data.accountingMappings?.escheatLiabilityAccount?.id || '',

          // accounting
          accountingRule: data.accountingRule?.id || '',
          transfersInSuspenseAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.transfersInSuspenseAccount.id : '',
          interestOnSavingsAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.interestOnSavingsAccount.id : '',
          savingsReferenceAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.savingsReferenceAccount.id : '',
          overdraftPortfolioControlId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.overdraftPortfolioControl.id : '',
          savingsControlAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.savingsControlAccount.id : '',
          writeOffAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.writeOffAccount.id : '',
          incomeFromFeeAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.incomeFromFeeAccount.id : '',
          incomeFromPenaltyAccountId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.incomeFromPenaltyAccount.id : '',
          incomeFromInterestId: (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.incomeFromInterest.id : '',
          overdraftInterestReceivableAccountId: (data.accountingRule.id === 3) ? data.accountingMappings.overdraftInteresetReceivableAccount.id : '',
          interestPayableLiabilityId: (data.accountingRule.id === 3) ? data.accountingMappings.interestPayableAccount.id : ''
        };
      }, 400);

      // This is to assing value in the fields
      scope.formData.transfersInSuspenseAccountId = (data.accountingRule.id === 2) ? data.accountingMappings.transfersInSuspenseAccount.id : '';
      scope.formData.interestOnSavingsAccountId = (data.accountingRule.id === 2) ? data.accountingMappings.interestOnSavingsAccount.id : '';
      scope.formData.savingsReferenceAccountId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.savingsReferenceAccount.id : '';
      scope.formData.overdraftPortfolioControlId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.overdraftPortfolioControl.id : '';
      scope.formData.savingsControlAccountId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.savingsControlAccount.id : '';
      scope.formData.writeOffAccountId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.writeOffAccount.id : '';
      scope.formData.incomeFromFeeAccountId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.incomeFromFeeAccount.id : '';
      scope.formData.incomeFromPenaltyAccountId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.incomeFromPenaltyAccount.id : '';
      scope.formData.incomeFromInterestId = (data.accountingRule.id === 2 || data.accountingRule.id === 3) ? data.accountingMappings.incomeFromInterest.id : '';
      scope.formData.overdraftInterestReceivableAccountId = (data.accountingRule.id === 3) ? data.accountingMappings.savingsControlAccount.id : '';
      scope.formData.taxGroupId = data.taxGroup?.id || '';
      scope.formData.escheatLiabilityId = data.accountingMappings?.escheatLiabilityAccount?.id || '';

      if (data.startDate) {
        scope.date.startDate = new Date(data.startDate);
      }
      if (data.endDate) {
        scope.date.endDate = new Date(data.endDate);
      }

      // Advanced accounting rule
      if (data.paymentChannelToFundSourceMappings || data.feeToIncomeAccountMappings || data.penaltyToIncomeAccountMappings) {
        scope.showOrHideValue = 'hide';
      } else {
        scope.showOrHideValue = 'show';
      }

      _.each(scope.product.paymentChannelToFundSourceMappings, function (fundSource) {
        scope.configureFundOptions.push({
          paymentTypeId: fundSource.paymentType.id,
          fundSourceAccountId: fundSource.fundSourceAccount.id,
          paymentTypeOptions: scope.product.paymentTypeOptions,
          assetAccountOptions: scope.assetAccountOptions
        });
      });

      _.each(scope.product.feeToIncomeAccountMappings, function (fees) {
        scope.specificIncomeaccounts.push({
          chargeId: fees.charge.id,
          incomeAccountId: fees.incomeAccount.id,
          chargeOptions: scope.product.chargeOptions,
          incomeAccountOptions: scope.incomeAccountOptions
        });
      });

      _.each(scope.product.penaltyToIncomeAccountMappings, function (penalty) {
        scope.penaltySpecificIncomeaccounts.push({
          chargeId: penalty.charge.id,
          incomeAccountId: penalty.incomeAccount.id,
          penaltyOptions: scope.product.penaltyOptions,
          incomeAccountOptions: scope.incomeAccountOptions
        });
      });

      scope.addConfigureFundSource = function () {
        if (
          scope.product.paymentTypeOptions &&
                scope.product.paymentTypeOptions.length > 0 &&
                scope.assetAccountOptions &&
                scope.assetAccountOptions.length > 0
        ) {
          scope.configureFundOptions.push({
            paymentTypeId: scope.product.paymentTypeOptions[0].id,
            fundSourceAccountId: scope.assetAccountOptions[0].id,
            paymentTypeOptions: scope.product.paymentTypeOptions,
            assetAccountOptions: scope.assetAccountOptions
          });
        }
      };

      scope.mapFees = function () {
        if (
          scope.product.chargeOptions &&
                scope.product.chargeOptions.length > 0 &&
                scope.incomeAccountOptions &&
                scope.incomeAccountOptions.length > 0
        ) {
          scope.specificIncomeaccounts.push({
            chargeId: scope.product.chargeOptions[0].id,
            incomeAccountId: scope.incomeAccountOptions[0].id,
            chargeOptions: scope.product.chargeOptions,
            incomeAccountOptions: scope.product.accountingMappingOptions.incomeAccountOptions
          });
        }
      };

      scope.mapPenalty = function () {
        if (
          scope.product.penaltyOptions &&
                scope.product.penaltyOptions.length > 0 &&
                scope.incomeAccountOptions &&
                scope.incomeAccountOptions.length > 0
        ) {
          scope.penaltySpecificIncomeaccounts.push({
            chargeId: scope.product.penaltyOptions[0].id,
            incomeAccountId: scope.incomeAccountOptions[0].id,
            penaltyOptions: scope.product.penaltyOptions,
            incomeAccountOptions: scope.incomeAccountOptions
          });
        }
      };

      setTimeout(() => { $('#taxGroupId').trigger('chosen:updated'); }, 1);
      angular.module('backoffice', ['angular.chosen']);
    });
  }
}
CreateSavingProductController.$inject = ['$scope', 'ResourceFactory', '$location', 'WizardHandler', 'dateFilter', '$rootScope', '$filter'];
