export function ViewTransactionController (scope, routeParams, resourceFactory, location, route, $uibModal) {
  scope.flag = false;
  scope.manualEntry = false;
  scope.productName = routeParams.productName;
  scope.clientName = routeParams.clientName;
  scope.accountNo = routeParams.accountNo;
  scope.clientId = routeParams.clientId;
  scope.loanId = routeParams.loanId;
  scope.groupName = routeParams.groupName;
  scope.journalEntryTransactionId = routeParams.transactionId;
  if (scope.journalEntryTransactionId !== null && scope.journalEntryTransactionId !== '') {
    scope.journalEntryTransactionId = scope.journalEntryTransactionId.substring(1, scope.journalEntryTransactionId.length);
  }

  resourceFactory.journalEntriesResource.get({ transactionId: routeParams.transactionId, transactionDetails: true }, function (data) {
    scope.transactionNumber = routeParams.transactionId;
    scope.transactions = data.pageItems;
    const { debits, credits, ...entry } = scope.transactions[0];
    scope.entries = [
      debits.map(debit => ({ ...entry, ...debit })),
      credits.map(credit => ({ ...entry, ...credit }))
    ].flat();

    for (const i in data.pageItems) {
      scope.manualEntry = data.pageItems[i].manualEntry;
      if (data.pageItems[i].reversed === false) {
        scope.flag = true;
      }
    }
  });

  scope.confirmation = function () {
    $uibModal.open({
      templateUrl: 'confirmation.html',
      controller: ConfirmationCtrl,
      resolve: {
        id () {
          return scope.trxnid;
        }
      }
    });
  };

  const ConfirmationCtrl = function ($scope, $uibModalInstance, id) {
    $scope.transactionnumber = id.transactionId;
    $scope.redirect = function () {
      $uibModalInstance.close('delete');
      location.path(`/viewtransactions/${id.transactionId}`);
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.showTransaction = function (transaction) {
    scope.transaction = transaction;
    $uibModal.open({
      templateUrl: 'viewjournalentry.html',
      controller: ViewJournalEntryCtrl,
      resolve: {
        transaction () {
          return scope.transaction;
        }
      }
    });
  };

  const ViewJournalEntryCtrl = function ($scope, $uibModalInstance, transaction) {
    $scope.transaction = transaction;
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.reverseTransaction = function (transactionId) {
    $uibModal.open({
      templateUrl: 'reverseTransaction.html',
      controller: ReverseJournalEntriesCtrl,
      resolve: {
        transactionId () {
          return transactionId;
        }
      }
    });
  };

  const ReverseJournalEntriesCtrl = function ($scope, $uibModalInstance, transactionId) {
    $scope.data = {
      reverseComments: ''
    };
    $scope.reverse = function () {
      const reverseData = { transactionId, comments: $scope.data.reverseComments };
      resourceFactory.journalEntriesResource.reverse(reverseData, function (data) {
        $uibModalInstance.dismiss('cancel');

        scope.trxnid = data;
        scope.confirmation();

        route.reload();
      });
    };
    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  };

  scope.backToSearchJournalEntries = function () {
    location.path('accounting/search-journal-entries').search(routeParams);
  };
}

ViewTransactionController.$inject = ['$scope', '$routeParams', 'ResourceFactory', '$location', '$route', '$uibModal'];
